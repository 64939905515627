/* ###### fonts ####### */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

/* ###### bootstrap ####### */
@import "variables";

@import "custom/custom";
@import "bootstrap/accordion";
@import "bootstrap/alerts";
@import "bootstrap/avatars";
@import "bootstrap/badges";
@import "bootstrap/breadcrumb";
@import "bootstrap/buttons";
@import "bootstrap/cards";
@import "bootstrap/carousel";
@import "bootstrap/dropdown";
@import "bootstrap/list";
@import "bootstrap/modal";
@import "bootstrap/navigation";
@import "bootstrap/panel";
@import "bootstrap/progress";
@import "bootstrap/tags";
@import "bootstrap/thumbnail";
@import "bootstrap/tooltip&popover";
@import "bootstrap/typhography";


/* ###### components ####### */

// @import "components/calendar";
@import "components/chat";
@import "components/chat2";
@import "components/contact";
@import "components/rangeslider";
@import "components/rating";


/* ###### custom ####### */


@import "custom/custom-styles";
@import "custom/email";
@import "custom/feather-icons";
@import "custom/icon-list";
@import "custom/loader";
@import "custom/tables";
@import "custom/vectormap";
@import "custom/widgets";


/* ###### forms ####### */

@import "forms/custom-control";
@import "forms/date-picker";
@import "forms/form-elements";
@import "forms/form-wizard";
@import "forms/input-group";
@import "forms/select-group";
@import "forms/selectize";


/* ###### layouts ####### */

@import "layouts/alignments";
@import "layouts/backgrounds";
@import "layouts/layouts";
@import "layouts/sidemenu";
@import "layouts/headers";
@import "layouts/horizontal-menu";
@import "layouts/rtl";
@import "layouts/style-dark";
@import "layouts/switcher-styles";
@import "layouts/menu-styles";
@import "layouts/header-styles";


/* ###### pages ####### */

@import "pages/gallery";
@import "pages/pricing";
@import "pages/profile";
@import "pages/file-attachments";
@import "pages/ecommerce";



































