.app-sidebar .mCSB_draggerContainer {
  right: -11px !important;
}

@media (min-width: 992px) {
  .app.sidenav-toggled {
    .app-content {
      margin-left: 70px;
    }

    .app-sidebar {
      left: 0;
      width: 70px;
      overflow: hidden;
      -webkit-transition: left .3s ease, width .3s ease;
      transition: left .3s ease, width .3s ease;
    }

    .app-sidebar__overlay {
      visibility: hidden;
    }

    .slide.is-expanded .slide-menu {
      display: none;
    }
  }

  .side-badge {
    display: none;
  }

  .app-header {
    width: 250px;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 1;
  }
}

@media (max-width: 991px) {
  .app {
    overflow-x: hidden;

    .app-sidebar {
      left: -250px;
    }

    .app-sidebar__overlay {
      visibility: hidden;
    }

    &.sidenav-toggled {
      .app-content {
        margin-left: 0;
      }

      .app-sidebar {
        left: 0;
      }

      .app-sidebar__overlay {
        visibility: visible;
      }
    }

    &.sidebar-gone.sidenav-toggled .app-sidebar {
      left: 0;
    }
  }

  .app.sidenav-toggled .side-menu .side-menu__icon {
    margin-right: 13px !important;
    margin-left: 0;
  }

  .sidebar-mini,
  .sidebar-gone {
    .side-menu {
      margin-inline: 0 !important;
      padding: 10px;
    }
  }
}

.app-content {
  min-height: calc(100vh - 50px);
  margin-top: 50px;
  margin-bottom: 0 !important;
  -webkit-transition: margin-left 0.2s ease;
  -o-transition: margin-left 0.2s ease;
  transition: margin-left 0.2s ease;
  overflow: hidden;

  .side-app {
    padding: 25px 1.5rem 0 1.5rem;
  }
}

.footer .container {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 992px) {

  .app.sidebar-mini.sidenav-toggled .logo-icon.icon-light {
    display: block;
  }

  .app.sidebar-mini.sidenav-toggled .logo-icon {
    text-align: center;
  }

  .app.sidebar-mini.sidenav-toggled .main-sidebar-header .logo-icon.icon-dark {
    display: none;
  }

  .app.sidebar-mini .main-sidebar-header .logo-icon {
    display: none;
  }

  .app.sidebar-mini.sidenav-toggled .main-sidebar-header .main-logo {
    display: none;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .main-sidebar-header .main-logo {
    display: block;
  }

  .app.sidebar-mini .main-semi-white {
    display: none;
  }

  .app.sidebar-mini .desktop-semilogo {
    display: none;
  }

  .app.sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
    line-height: 19px;
  }

  .app.sidebar-mini.sidenav-toggled .side-item.side-item-category {
    display: none;
  }

  .app.sidebar-mini.sidenav-toggled .side-item.side-item-category {
    display: none;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-item.side-item-category {
    display: block;
  }
}

@media (max-width: 991px) {
  .app-content {
    min-width: 100%;
  }
}

@media print {
  .app-content {
    margin: 0;
    padding: 0;
    background-color: $white;
  }
}

.light-text {
  font-weight: 300 !important;
}

.semibold-text {
  font-weight: 600 !important;
}

.line-head {
  padding-bottom: 10px;
  border-bottom: 1px solid $border;
}

/*----- Componant: Top Navigation Bar ----- */

@media print {
  .app-header {
    display: none;
  }
}

.app-header__logo {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  color: $white;
  text-align: center;
  font-family: "Niconne";
  padding: 0 15px;
  font-size: 26px;
  font-weight: 400;
  line-height: 50px;

  &:focus,
  &:hover {
    text-decoration: none;
  }
}

@media (min-width: 992px) {
  .app-header__logo {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: block;
    width: 230px;
  }
}

@media (max-width: 991px) {
  .app-sidebar__toggle {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .main-sidebar-header {
    display: none !important;
  }

  .main-sidemenu {
    margin-top: 0 !important;
  }
}

.app-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media (min-width: 992px) {
  .app-nav {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
}

.app-nav__item {
  display: block;
  padding: 15px;
  line-height: 20px;
  color: #a8a8a8;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;

  &:hover,
  &:focus {
    background: $black-1;
    color: #f6f6f6;
  }
}

.app-search {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  margin-right: 15px;
  padding: 10px 0;
}

@media (max-width: 480px) {
  .app-search {
    display: none;
  }
}

.app-search__input {
  border: 0;
  padding: 5px 10px;
  padding-right: 30px;
  border-radius: 2px;
  background-color: $white-8;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;

  &::-webkit-input-placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder,
  &::placeholder {
    color: $black-4;
  }
}

.app-search__button {
  position: absolute;
  right: 0;
  top: 10px;
  bottom: 10px;
  padding: 0 10px;
  border: 0;
  color: $black-8;
  background: none;
  cursor: pointer;
}

.app-notification {
  min-width: 270px;
}

.app-notification__title {
  padding: 8px 20px;
  text-align: center;
  background-color: rgba(0, 150, 136, 0.4);
  color: #333;
}

.app-notification__footer {
  padding: 8px 20px;
  text-align: center;
  background-color: #eee;
}

.app-notification__content {
  max-height: 220px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $black-2;
  }
}

.app-notification__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 20px;
  color: inherit;
  border-bottom: 1px solid $border;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;

  &:focus,
  &:hover {
    color: inherit;
    text-decoration: none;
    background-color: #e0e0e0;
  }
}

.app-notification__message,
.app-notification__meta {
  margin-bottom: 0;
}

.app-notification__icon {
  padding-right: 10px;
}

.app-notification__message {
  line-height: 1.2;
}

.app-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  color: $color;
  width: 250px;
  max-height: 100%;
  z-index: 1024;
  background: $white;
  -webkit-box-shadow: 0px 10px 10px 20px rgb(176 184 214 / 9%), 10px 10px 15px -5px rgb(204 210 236 / 32%);
  box-shadow: 0px 10px 10px 20px rgb(176 184 214 / 9%), 10px 10px 15px -5px rgb(204 210 236 / 32%);
  border-right: 1px solid $border;
  transition: left 0.3s ease, width 0.3s ease;
  padding-bottom: 70px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $black-2;
  }
}

@media print {
  .app-sidebar {
    display: none;
  }
}

@media (max-width: 991px) {
  .app-sidebar__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
  }
}

.app-sidebar__user {
  .dropdown-menu {
    top: 10px !important;
  }

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: $color;
  width: 100%;
  padding: 25px 20px;
  display: inline-block;
  border-bottom: 1px solid $border;
}

.app-sidebar__user-avatar {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 15px;
}

.app-sidebar__user-name {
  &.text-sm {
    font-size: 12px;
    font-weight: 400;
  }

  font-size: 17px;
  line-height: 1.3;
  white-space: normal;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-bottom: 0;
  overflow: hidden;
  font-weight: 600;
  color: #e5e9ec;
  font-size: 15px;
  margin-top: 5px !important;
}

.app-sidebar__user-designation {
  white-space: normal;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-bottom: 0;
  overflow: hidden;
  font-weight: 600;
  color: #e5e9ec;
  font-size: 15px;
  margin-top: 5px !important;
}

.side-menu {
  margin-bottom: 0;
  padding: 0;
  list-style: none;

  .slide .side-menu__item {
    padding: 10px 20px 10px 22px;
    margin: 0;
    border-radius: 0;
    color: #576482;
  }
}

slide is-expanded side-menu__item {
  background: rgba(238, 238, 247, 0.9);
}

.slide {
  margin: 0;
}

.side-menu .slide.active {
  .side-menu__item {
    background-color: transparent;
  }

  .side-menu__label,
  .side-menu__icon {
    color: #{$primary};
  }
}

.side-menu__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  font-size: 14px;
  font-weight: 500;

  &.active,
  &:hover,
  &:focus {
    text-decoration: none;
    color: #{$primary};
  }

  &.active .side-menu__label {
    color: #{$primary} !important;
  }

  &.active .angle {
    color: #{$primary} !important;
  }

  &.active .side-menu__icon,
  &:hover .side-menu__icon,
  &:focus .side-menu__icon {
    color: #{$primary} !important;
  }
}

@media (min-width: 992px) {
  .app.sidebar-mini.sidenav-toggled .profile-status {
    right: 22px;
    top: 57px;
  }

  .app.sidenav-toggled .side-menu__label {
    display: none !important;
    position: relative;
    padding: 0;
    margin: 0;
    left: 0;
    color: $white-8;
    opacity: 1;
    background: transparent;
    font-size: 12px;
    box-shadow: none;
  }
}

.slide {
  &:hover {

    .side-menu__label,
    .angle,
    .side-menu__icon {
      color: #{$primary} !important;
      fill: #{$primary-02} !important;
    }
  }

  &.is-expanded {

    .side-menu__label,
    .side-menu__icon,
    .angle {
      color: #{$primary};
      fill: #{$primary-02};
    }
  }
}

.slide-item {

  &.active,
  &:hover,
  &:focus {
    text-decoration: none;
    color: #{$primary} !important;

    &::before {
      color: #{$primary} !important;
    }

    .sub-angle {
      color: #{$primary} !important;
      opacity: 1;
    }
  }
}

.sub-side-menu__item {

  &.active,
  &:hover,
  &:focus {
    text-decoration: none;
    color: #{$primary} !important;

    &::before {
      color: #{$primary} !important;
    }

    .sub-angle {
      color: #{$primary} !important;
      opacity: 1;
    }
  }
}

.sub-slide-menu a {

  &.active,
  &:hover,
  &:focus {
    text-decoration: none;
    color: #{$primary} !important;

    &::before {
      color: #{$primary} !important;
    }
  }
}

.slide-menu {
  .sub-slide-menu {
    padding: 0 5px;
  }
}

.sub-slide .sub-angle {
  margin-left: auto;
  float: right;
  font-size: 12px;
}

.slide-menu li {
  position: relative;
}

.slide.is-expanded {
  a {
    color: #576482;
    text-decoration: none;
  }

  .sub-side-menu__item:before {
    content: "\e048";
    font-family: typicons !important;
    margin-right: 8px;
    position: relative;
    color: #576482;
    opacity: 0.6;
  }
}

.side-menu .side-menu__icon {
  font-size: 23px;
  line-height: 0;
  margin-right: 8px;
  margin-left: -8px;
  width: 22px;
  height: 22px;
  line-height: 34px;
  border-radius: 3px;
  text-align: center;
  color: #{$primary-05};
  fill: #{$primary-02};
}

.side-menu__icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25px;
}

.side-menu__label {
  white-space: nowrap;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
  font-size: .85rem;
  line-height: 1;
  vertical-align: middle;
  font-weight: 500;
  letter-spacing: .6px;
}

.app-sidebar .side-item.side-item-category {
  color: #2c364c;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-bottom: 12px;
  height: 15px;
  padding: 0 20px 0 25px;
}

.app-sidebar .side-item.side-item-category:not(:first-child) {
  margin-top: 25px;
}

.slide-menu {
  display: none;
  list-style: none;
}

.slide.is-expanded .slide-menu {
  display: block;
}

.slide-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  padding: 8px 14px 8px 47px;
  font-weight: 400;
  color: #576482;

  .icon {
    margin-right: 5px;
  }
}

.angle {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

@media (max-width: 848px) {
  .profile-user .p-text {
    display: none;
  }
}

@media (min-width: 992px) {
  .sidenav-toggled {

    .app-sidebar__user-designation,
    .angle,
    .user-notification,
    .user-info {
      display: none;
    }

    .sidenav-toggled.user-notification::before {
      background: transparent;
      display: none;
    }


    .app-sidebar .avatar-xl {
      width: 3rem !important;
      height: 3rem !important;
      line-height: 3rem;
      font-size: 1rem;
      margin-bottom: 0px !important;
    }

    .app-sidebar__user,
    .side-menu {
      margin-top: 0px;
    }

    .app-sidebar__user-avatar {
      width: 25px;
      height: 25px;
    }

    .app-sidebar__user {
      padding: 12px 0px 12px 0;
      margin-bottom: 0px;
    }

    .profile-img {
      top: 0px;
      right: 19px;
    }

    .app-content {
      margin-left: 0;
    }

    .app-sidebar {
      &:hover {
        overflow: visible;
      }
    }

    .side-menu__item {
      overflow: hidden;

      &:hover {
        overflow: visible;

        .side-menu__label {
          opacity: 1;
        }

        +.slide-menu {
          visibility: visible;
        }
      }
    }

    .side-menu__label {
      display: block;
      position: absolute;
      top: 0;
      left: 50px;
      padding: 12px 5px 12px 20px;
      margin-left: -3px;
      line-height: 1;
      opacity: 0;
      background: $white;
      color: #a8a8a8;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      -webkit-box-shadow: 0px 8px 17px $black-2;
      box-shadow: 0px 8px 17px $black-2;
    }

    .slide {
      &:hover {
        .side-menu__label {
          opacity: 1;
        }

        .slide-menu {
          max-height: 100%;
          opacity: 1;
          visibility: visible;
          z-index: 10;
        }
      }

      .side-menu__label {
        border-bottom-right-radius: 0;
      }
    }

    .slide-menu {
      position: absolute;
      left: 90px;
      min-width: 180px;
      opacity: 0;
      border-bottom-right-radius: 4px;
      z-index: 9;
      visibility: hidden;
      -webkit-transition: visibility 0.3s ease;
      -o-transition: visibility 0.3s ease;
      transition: visibility 0.3s ease;
      -webkit-box-shadow: 0px 8px 17px $black-2;
      box-shadow: 0px 8px 17px $black-2;
    }
  }

  .app.sidenav-toggled {
    .side-menu__item {
      display: block;
      padding: 13px 0 13px 0 !important;
      margin: 0 auto;
      text-align: center;
    }

    .side-menu_label {
      display: block;
      font-size: 12px;
    }

    .side-menu__label {
      display: block;
      position: relative;
      padding: 0;
      margin: 0;
      left: 0;
      color: #5c6287;
      opacity: 1;
      background: transparent;
      font-size: 12px;
      box-shadow: none;
    }
  }

  .sidenav-toggled .app-sidebar__user .avatar-md {
    margin: 0 auto;
  }

  .app.sidenav-toggled .nav-badge {
    position: absolute;
    top: 8px;
    right: 28px;
    padding: 0.2rem 0.4rem;
    font-size: 11px;
  }
}

.app {
  &.sidenav-toggled .side-menu .side-menu__icon {
    margin-right: 0;
    margin-left: 0;
  }

  .side-menu_label {
    display: none;
  }
}

.dropdown-menu {
  border-radius: 0;

  &.dropdown-menu-right {
    left: auto;
  }
}

.dropdown-item {

  .fa,
  .icon {
    vertical-align: middle;
  }
}

.app-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  background-color: $white;
  margin: -30px -30px 30px;
  padding: 20px 30px;
  -webkit-box-shadow: 0 1px 2px $black-1;
  box-shadow: 0 1px 2px $black-1;

  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 400;
  }

  p {
    margin-bottom: 0;
    font-style: italic;
  }
}

@media print {
  .app-title {
    display: none;
  }
}

@media (max-width: 480px) {
  .app-title {
    margin: -15px -15px 15px;
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

@media (max-width: 480px) {
  .app-title p {
    display: none;
  }
}

.app-breadcrumb {
  margin-bottom: 0;
  text-align: right;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
  padding: 0;
  text-align: left;
  padding: 0;
  background-color: transparent;
}

@media (max-width: 480px) {
  .app-breadcrumb {
    margin-top: 10px;
  }
}

.sidenav-toggled .app-sidebar__user .avatar-md {
  line-height: 2rem;
  font-size: 1rem;
}

.app-sidebar {
  .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background: $black-05;
  }

  .mCSB_scrollTools {

    .mCSB_dragger .mCSB_dragger_bar,
    .mCSB_draggerRail {
      background: $white-2;
    }
  }
}

.sidenav-toggled .main-wrapper .side-menu .side-menu__item {
  .nav-badge {
    position: absolute;
    top: 5px;
    left: 57px;
    display: block !important;
    padding: 3px 5px !important;
  }

  .nav-badge1 {
    display: none;
  }
}

.nav-badge {
  border-radius: 30px;
  padding: 0.4em 0.6em;
  font-size: 12px;
}

.app-header {
  .header-brand1 {
    text-align: center;
    display: table;
  }

  .header-brand-img.desktop-logo {
    max-height: 2.5rem;
    text-align: center;
    display: block;
    margin-right: 0;
  }
}

.sidenav-toggled .app-sidebar .app-header .header-brand-img.desktop-logo,
.app-header .header-brand-img.toggle-logo {
  display: none;
}

.sidenav-toggled {
  .app-sidebar .app-header {
    .header-brand-img.toggle-logo {
      display: none;
      margin-right: 0;
    }
  }

  .user-pic {
    margin-bottom: 0;
  }
}

.app-header {
  display: flex;
  border-bottom: 1px solid $border;
  -webkit-transition: left 0.3s ease;
  -o-transition: left 0.3s ease;
  transition: left 0.3s ease;
}

.sidenav-toggled .app-sidebar__toggle {
  display: block;
}

.user-pic {
  margin-bottom: 0.8rem;
}

.side-menu h3 {
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 1px;
  opacity: 1;
  padding: 20px 34px 10px;
  text-transform: capitalize;
  width: 100%;
  position: relative;
  color: #90909e;
  position: relative;

  &:after {
    content: "--";
    position: absolute;
    left: 21px;
    top: 20px;
  }
}

.sidenav-toggled .side-menu h3 {
  display: none;
}

.slide.is-expanded .slide-menu,
.sub-slide {
  display: block;
}

/*-- Sub-slide--**/

.sub-side-menu__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 0;
  font-size: 12px;
  color: #576482;
}

.sub-side-menu__label {
  white-space: normal;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-weight: 400;
}

.sub-slide {
  .sub-angle {
    transform-origin: center;
    opacity: 0.5;
  }

  &.is-expanded .sub-angle {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.sub-slide-menu {
  list-style: none;
  padding: 0;
}

.sub-slide-item {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  list-style: none;
  font-size: 12px;
  padding: 8px 0 8px 65px !important;
}

.sub-slide-menu {
  display: none;
  font-size: 0.8rem !important;
  padding-left: 0;
  list-style: none;
}

.sub-slide.is-expanded {
  .sub-slide-menu {
    display: block;
  }
}

.slide-menu .sub-slide.is-expanded {
  display: block;
}

.sub-side-menu__item {
  padding-left: 48px !important;
  padding-right: 20px !important;
}

.app-sidebar {
  .side-menu__item.active:hover {
    color: #7282a9 !important;
  }

  .slide {
    // &.active .side-menu__icon,
    // &.is-expanded .side-menu__icon {
    //   fill: #{$primary} !important;
    // }

    &.active {
      .side-menu__icon {
        fill: #{$primary} !important;
      }

      .side-menu__item {
        color: #{$primary} !important;
      }
    }
  }
}

.side-menu .slid.active .sub-slide.is-expanded .sub-slide-menu {
  max-height: 0;
}

.app-sidebar .slide-menu a:before {
  content: '\e048';
  font-family: typicons !important;
  margin-right: 8px;
  position: relative;
  color: #576482;
  opacity: 0.6;
}

.sub-slide-menu1 .sub-slide-item2:before {
  content: '\e048';
  font-family: typicons !important;
  position: absolute;
  left: 35px !important;
  font-size: 9px;
  color: #576482;
  top: 5px !important;
  font-weight: 900;
}

.app-sidebar__toggle .close-toggle,
.sidebar-mini.sidenav-toggled .open-toggle {
  display: none;
}

.header-icon {
  text-align: center;
  line-height: 40px;
  width: 34px;
  height: 34px;
  border-radius: 4px;
  text-align: center;
  line-height: 1.7;
  font-size: 1.5rem;
  color: #a8b1c7;
}

.sidebar-mini.sidenav-toggled .close-toggle {
  display: block;
  color: $black;
  font-size: 18px;
  text-align: center;
}

.sidenav-toggled .app-content {
  transition: margin-left 0.3s ease;
}

.sub-slide-menu .active .sub-slide-item.active {
  color: #{$primary};
}

.app-sidebar .slide-menu a.active:before {
  color: #{$primary};
}

@media (min-width: 992px) {
  .app.sidebar-mini.sidenav-toggled-open .app-sidebar {
    left: 0;
    width: 250px;
  }

  .app.sidebar-mini.sidenav-toggled-open .side-menu__item {
    display: flex;
    align-items: center;
    padding: 12px 14px;
    margin: 2px 0;
  }

  .app.sidebar-mini.sidenav-toggled-open .side-menu .slide .side-menu__item {
    padding: 10px 20px 10px 22px !important;
    margin: 0;
    text-align: left;
    border-radius: 0;
  }

  .app.sidebar-mini.sidenav-toggled-open .side-menu__label {
    white-space: normal;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    opacity: 1;
    display: block !important;
    position: initial;
    font-size: 0.875rem;
    line-height: 1;
    font-weight: 400;
  }

  .app.sidebar-mini.sidenav-toggled-open .angle {
    display: block;
  }

  .app.sidebar-mini.sidenav-toggled-open .avatar-xl {
    width: 4rem !important;
    height: 4rem !important;
    font-size: 36px !important;
  }

  .app.sidebar-mini.sidenav-toggled-open .app-sidebar__user .user-info {
    margin: 0 auto;
    display: block !important;
    text-align: center;
  }

  .app.sidebar-mini.sidenav-toggled-open .side-menu .side-menu__icon {
    margin-right: 12px;
  }

  .app.sidebar-mini.sidenav-toggled-open .main-sidebar-header {
    width: 250px !important;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .logo-icon {
    display: none !important;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .desktop-logo.logo-light {
    display: block;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .profile-status {
    right: 97px;
    top: 70px;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .desktop-logo.logo-dark {
    display: none;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-badge {
    display: block !important;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
    left: 0;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu__item:hover+.slide-menu {
    visibility: visible;
    opacity: inherit;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide.is-expanded .slide-menu {
    display: block;
    box-shadow: none;
  }

  .sidenav-toggled .slide-menu {
    box-shadow: none !important;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
    position: inherit;
    visibility: visible;
    opacity: inherit !important;
    z-index: 0;
    cursor: pointer;
  }
}

@media (min-width: 991px) and (max-width: 992px) {
  .main-sidebar-header .main-logo {
    display: block;
  }
}

@media (max-width: 991px) {
  .main-body.app.sidebar-mini.sidebar-gone.sidenav-toggled .desktop-logo.logo-light {
    display: none !important;
  }

  .main-body.app.sidebar-mini.sidebar-gone.sidenav-toggled.sidenav-toggled-open .desktop-logo.logo-light {
    display: block !important;
  }
}

@media (max-width: 991px) and (min-width: 992px) {
  .desktop-logo {
    height: auto !important;
    text-align: inherit !important;
    margin: 0 !important;
    justify-content: inherit !important;
  }
}

@media only screen and (max-width: 991px) {
  .app .desktop-logo {
    margin-left: 0 !important;
  }

  .main-body.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-header form[role="search"].active input {
    padding-left: 250px;
    transition: none;
  }

  .main-body.app.sidebar-mini.sidenav-toggled .app-header form[role="search"].active input {
    padding-left: 90px;
  }

  .main-body.app.sidebar-mini.sidebar-gone .app-header form[role="search"].active input {
    padding-left: 20px;
  }

  .main-body.app.sidebar-mini .app-header form[role="search"].active input {
    padding-left: 250px;
  }
}

.slide.is-expanded .angle {
  transform: rotate(90deg);
}

.sub-slide.is-expanded .sub-slide-sub.is-expanded .sub-angle {
  transform: rotate(180deg);
}

.sub-slide.is-expanded .sub-slide-sub .sub-angle {
  transform: rotate(0deg);
}

.sub-slide-menu-sub {
  display: none;
  font-size: 0.8rem !important;
  padding-left: 0;
  list-style: none;
  padding: 0 5px;
}

.sub-slide.is-expanded .sub-side-menu__item:hover {
  color: #{$primary};
}

.slide.is-expanded .sub-slide-item:hover {
  color: #{$primary};
}

.sub-slide-menu-sub .sub-slide-item {
  padding-left: 55px !important;
}

.app-sidebar .slide-menu .sub-slide-menu .sub-slide-menu-sub a:before {
  left: 35px;
}

@media (min-width: 992px) {
  .app.sidenav-toggled .side-menu__item .badge {
    display: none;
  }

  .app.sidenav-toggled.sidenav-toggled-open .side-menu__item .badge {
    display: block;
  }

  .app.sidenav-toggled.sidenav-toggled-open .app-sidebar__user {
    padding: 25px 20px;
  }
}

.side-menu-label1 {
  display: none !important;
}

.sub-slide-menu1 .sub-slide-item2 {
  padding: 6px 0px 6px 48px !important;
  display: block;
  font-size: 11px;
  color: #576482;
}

.sub-slide-menu1 .sub-slide-item2:hover {
  color: #{$primary} !important;
}

.sub-slide2.is-expanded .sub-slide-menu1 {
  display: none;
}

.slide-menu .sub-slide-menu1 {
  padding: 0 10px;
}

.sub-slide-menu1 {
  display: none;
  font-size: 0.8rem !important;
  padding-left: 0;
  list-style: none;
}

.sidebar-mini .slide-left,
.sidebar-mini .slide-right {
  display: none !important;
}

.logo-icon {
  img {
    height: 2.5rem;
  }
}

.sidebar-mini {
  .side-menu {
    margin-inline: 0 !important;
    padding: 10px;
  }
}

// ----- CLOSED MENU -------//
.closed-menu {
  @media (min-width: 992px) {
    &.app.sidenav-toggled .app-sidebar {
      display: none !important;
    }

    &.app.sidenav-toggled {
      .app-content {
        margin-left: 0px !important;
      }

      .app-header {
        padding-left: 0px !important;
      }
    }
  }
}

// ----- ! CLOSED MENU -------//

// ----- ICON TEXT -------//
.icontext-menu {
  @media (min-width: 992px) {
    &.app.sidenav-toggled .side-menu .side-menu__icon {
      margin-bottom: 5px;
    }

    &.app.sidenav-toggled .side-menu__label {
      display: block !important;
      font-size: 13.5px !important;
      position: relative !important;
    }

    &.app.sidenav-toggled.sidenav-toggled-open .side-menu__label {
      font-size: 0.875rem !important;
    }

    &.app.sidenav-toggled {
      .side-menu__label {
        font-size: 12px !important;
      }

      .app-sidebar {
        width: 90px !important;
      }

      .main-sidebar-header {
        width: 90px !important;
      }

      .app-header {
        padding-left: 90px !important;
      }

      .app-content {
        margin-left: 90px;
      }

      .app-sidebar-help {
        width: 90px;
      }
    }

    &.app.sidebar-mini.sidenav-toggled-open {
      .app-sidebar {
        width: 250px !important;
      }

      .main-sidebar-header {
        width: 250px !important;
      }

      .close-toggle {
        display: none !important;
      }

      .app-sidebar-help {
        width: 250px;
      }
    }

    &.sidebar-mini.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
      width: inherit;
      height: inherit;
    }

  }
}

// ----- ! ICON TEXT -------//

// ----- HOVER SUBMENU -------//
.hover-submenu {
  @media (min-width: 992px) {
    &.sidenav-toggled-open .slide-menu {
      border-radius: 0px 7px 7px 0px !important;
    }

    &.sidenav-toggled-open .slide-item {
      padding: 8px 18px 8px 18px;
    }

    &.sidenav-toggled-open .sub-side-menu__item {
      padding: 8px 18px 8px 18px !important;
    }

    &.sidenav-toggled-open .sub-slide-item {
      padding: 8px 0 8px 30px !important
    }

    .slide-menu {
      border-radius: 0px 7px 7px 0px;
    }

    &.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
      position: absolute;
      left: 90px;
      min-width: 180px;
      opacity: 1;
      z-index: 9999;
      visibility: hidden;
      top: 0;
      display: block;
      max-height: inherit;
    }

    .app-sidebar .slide {
      position: relative;
      display: block;
    }

    &.app.sidenav-toggled .side-menu__item {
      padding: 13px 0 13px 0;
    }

    &.app.sidenav-toggled-open {
      .ps>.ps__rail-y {
        display: none !important;
      }

      .slide-menu {
        padding-left: 0 !important;
        padding: 10px 0px !important;
        position: absolute;
        background: $white;
        opacity: 1;
        visibility: visible;
        z-index: 999;
        width: 200px;
        left: 90px;
        border: 1px solid $border;
        transition: none !important;
        display: block !important;
        box-shadow: 8px 8px 17px $black-1  !important;
        top: 0;
      }

      .slide:hover .slide-menu {
        visibility: visible;
        display: block !important;
        height: auto !important;
      }

      .app-sidebar {
        overflow: visible !important;
        position: absolute !important;
      }

      .side-menu__label {
        white-space: nowrap;
        display: block;
        position: relative;
        padding: 0;
        margin: 0;
        left: 0;
        color: #7b8191;
        opacity: 1;
        background: transparent;
        font-size: 12px;
        box-shadow: none;
      }

      .side-menu .side-menu__icon {
        margin-bottom: 5px !important;
      }

      .side-menu .slide .side-menu__item.active {
        border-right: 0 !important;
        padding: 10px;
      }

      .main-sidebar-header {

        .desktop-logo,
        .desktop-dark,
        .mobile-dark {
          display: none !important;
        }

        .mobile-logo {
          display: block !important;
        }
      }

      .side-item.side-item-category {
        display: none !important;
      }

      .side-menu__icon {
        margin-right: 0;
        margin-left: 0;
        margin: 0 auto !important;
      }

      .side-menu .slide .side-menu__item.active {
        display: block;
        margin: 0 auto !important;
        padding: 13px 0 13px 0 !important;
        border-radius: 0;
        text-align: center !important;
      }

      .side-menu .slide .side-menu__item {
        display: block;
        margin: 0 auto !important;
        padding: 13px 0 13px 0 !important;
        border-radius: 0;
        text-align: center !important;

        i {
          display: none !important;
        }
      }
    }

    &.app.sidenav-toggled {
      .main-content {
        min-height: 1300px;
      }

      .side-menu__label {
        font-size: 11px !important;
      }

      .app-sidebar {
        position: absolute !important;
      }

      .side-menu .side-menu__icon {
        margin-bottom: 5px;
      }

      .side-menu__label {
        display: block !important;
      }

      .app-sidebar__toggle .close-toggle {
        display: block;
      }

      .app-header {
        padding-left: 90px;
      }

      .main-sidebar-header {
        width: 90px !important;
      }

      .app-content {
        margin-left: 90px;
      }

      .app-sidebar {
        left: 0;
        width: 90px !important;
      }

      .slide.is-expanded .slide-menu {
        display: none;
      }

      .app-sidebar-help {
        display: none;
      }
    }

    .side-badge {
      display: none;
    }

    .app-header {
      width: 250px;
      left: 0;
      right: 0;
      top: 0;
      position: fixed;
      z-index: 1;
    }
  }

  .sub-slide-menu .sub-side-menu__item:before {
    left: 25px !important;
    font-size: 9px;
  }

  @media (min-width: 992px) {
    &.app.sidebar-mini {
      &.sidenav-toggled .logo-icon.mobile-logo {
        margin: 0 auto;
        text-align: center;
      }

      &.sidenav-toggled-open .avatar-xl {
        width: 3rem !important;
        height: 3rem !important;
      }

      &.sidenav-toggled.sidenav-toggled-open .profile-status {
        right: 22px;
        top: 57px;
      }

      &.sidenav-toggled-open {

        .app-sidebar__user {
          padding: 12px 0 12px 0;
        }

        .side-menu {
          padding: 10px 0;
        }

        .app-sidebar__user .user-info {
          display: none !important;
        }

        .app-sidebar__user .user-pic {
          margin: 0;
        }

        #bg-side-text,
        .main-sidebar-header .logo-icon.icon-dark {
          display: none !important;
        }
      }
    }
  }
}

// -----! HOVER SUBMENU -------//

// ----- HOVER SUBMENU 1 -------//
.hover-submenu1 {
  @media (min-width: 992px) {
    &.sidenav-toggled-open .slide-menu {
      border-radius: 0px 7px 7px 0px !important;
    }

    &.sidenav-toggled-open .slide-item {
      padding: 8px 18px 8px 18px;
    }

    &.sidenav-toggled-open .sub-side-menu__item {
      padding: 8px 18px 8px 18px !important;
    }

    &.sidenav-toggled-open .sub-slide-item {
      padding: 8px 0 8px 30px !important
    }

    .slide-menu .side-menu-label1 a:before {
      display: none;
    }

    .slide-menu {
      border-radius: 0px 7px 7px 0px;
    }

    &.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
      position: absolute;
      left: 90px;
      min-width: 180px;
      opacity: 1;
      z-index: 9999;
      visibility: hidden;
      top: 0;
      display: block;
      max-height: inherit;
    }

    .app-sidebar .slide {
      position: relative;
      display: block;
    }

    &.app.sidenav-toggled .side-menu__item {
      padding: 13px 0 13px 0;
    }

    &.app.sidenav-toggled-open {
      .ps>.ps__rail-y {
        display: none !important;
      }

      .slide-menu {
        padding-left: 0 !important;
        padding: 10px 0px !important;
        position: absolute;
        background: $white;
        opacity: 1;
        visibility: visible;
        z-index: 999;
        width: 200px;
        left: 90px;
        border: 1px solid $border;
        transition: none !important;
        display: block !important;
        box-shadow: 8px 8px 17px $black-1;
        top: 0;
      }

      .slide:hover .slide-menu {
        visibility: visible;
        display: block !important;
        height: auto !important;
      }

      .app-sidebar {
        overflow: visible !important;
        position: absolute !important;
      }

      .side-menu__label {
        white-space: nowrap;
        display: block;
        position: relative;
        padding: 0;
        margin: 0;
        left: 0;
        color: #7b8191;
        opacity: 1;
        background: transparent;
        font-size: 12px;
        box-shadow: none;
      }

      .side-menu .side-menu__icon {
        margin-bottom: 5px !important;
      }

      .side-menu .slide .side-menu__item.active {
        border-right: 0 !important;
        padding: 10px;
      }

      .main-sidebar-header {

        .desktop-logo,
        .desktop-dark,
        .mobile-dark {
          display: none !important;
        }

        .mobile-logo {
          display: block !important;
        }
      }

      .side-item.side-item-category {
        display: none !important;
      }

      .side-menu__icon {
        margin-right: 0;
        margin-left: 0;
        margin: 0 auto !important;
      }

      .side-menu .slide .side-menu__item.active {
        display: block;
        margin: 0 auto !important;
        padding: 13px 0 13px 0 !important;
        border-radius: 0;
        text-align: center !important;
      }

      .side-menu .slide .side-menu__item {
        display: block;
        margin: 0 auto !important;
        padding: 13px 0 13px 0 !important;
        border-radius: 0;
        text-align: center !important;

        i {
          display: none !important;
        }
      }

      .side-menu-label1 {
        display: block !important;
        font-size: 15px;
        font-weight: 500;
        border-bottom: 1px solid $border;
        padding: 0 20px 10px 20px !important;
        margin-bottom: 6px;
      }
    }

    &.app.sidenav-toggled {
      .main-content {
        min-height: 1300px;
      }

      .side-menu__label {
        font-size: 11px !important;
      }

      .app-sidebar {
        position: absolute !important;
      }

      .side-menu .side-menu__icon {
        margin-bottom: 5px;
      }

      .side-menu__label {
        display: none !important;
      }

      .app-sidebar__toggle .close-toggle {
        display: block;
      }

      .app-header {
        padding-left: 90px;
      }

      .main-sidebar-header {
        width: 90px !important;
      }

      .app-content {
        margin-left: 90px;
      }

      .app-sidebar {
        left: 0;
        width: 90px !important;
      }

      .slide.is-expanded .slide-menu {
        display: none;
      }

      .app-sidebar-help {
        display: none;
      }
    }

    .side-badge {
      display: none;
    }

    .app-header {
      width: 250px;
      left: 0;
      right: 0;
      top: 0;
      position: fixed;
      z-index: 1;
    }
  }

  .sub-slide-menu .sub-side-menu__item:before {
    left: 25px !important;
    font-size: 9px;
  }

  @media (min-width: 992px) {
    &.app.sidebar-mini {
      &.sidenav-toggled .logo-icon.mobile-logo {
        margin: 0 auto;
        text-align: center;
      }

      &.sidenav-toggled-open .avatar-xl {
        width: 3rem !important;
        height: 3rem !important;
      }

      &.sidenav-toggled.sidenav-toggled-open .profile-status {
        right: 22px;
        top: 57px;
      }

      &.sidenav-toggled-open {

        .app-sidebar__user {
          padding: 12px 0 12px 0;
        }

        .side-menu {
          padding: 10px 0;
        }

        .app-sidebar__user .user-info {
          display: none !important;
        }

        .app-sidebar__user .user-pic {
          margin: 0;
        }

        #bg-side-text,
        .main-sidebar-header .logo-icon.icon-dark {
          display: none !important;
        }
      }
    }
  }
}

// -----! HOVER SUBMENU 1 -------//


.main-sidebar-header {
  height: 75px;
  padding: 18px 20px;
  width: 250px;
  background: $white;
  border-bottom: 1px solid $border;
  position: fixed;
  border-right: 1px solid $border;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  transition: left 0.3s ease, width 0.3s ease;
}

.main-logo {
  display: flex;
  align-items: center;
  position: relative;
  height: 2.5rem;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
}

.slide-menu .side-menu-label1 {
  display: none;
}

.app.sidebar-mini .desktop-logo.logo-dark {
  display: none;
}

.main-sidemenu {
  margin-top: 75px;
}

@media (min-width: 992px) {
  .main-content {
    padding-bottom: 1rem !important;
    margin-left: 250px;
  }
}

.app-sidebar__user .user-info .text-muted {
  color: #b3bdca !important;
  font-size: 12px;
}

.app-sidebar__user .user-pic {
  margin: 0 0 10px 0;
}

.app-sidebar-help {
  border-top: 1px solid $border;
  border-right: 1px solid $border;
  background: $white;
  position: fixed;
  bottom: 0;
  z-index: 11;
  width: 250px;
  padding: 5px 13px;
  transition: left 0.3s ease, width 0.3s ease;
}

.app-sidebar .dropdown-menu {
  box-shadow: 0px 10px 10px 20px rgb(16 22 58 / 2%), 10px 10px 15px -5px rgb(16 22 58 / 8%);
  background: #{$primary};
  color: $white;
  border: 1px solid $border;

  a {
    color: $white-8;
  }
}

.app-sidebar .dropdown-menu-arrow.dropdown-menu-end {

  &::before,
  &::after {
    bottom: -9px;
    top: auto;
    transform: rotate(-180deg);
    right: auto;
    left: 12px;
    border-bottom: 9px solid #{$primary};
  }
}

.sidebar-dropdown-divider {
  border-bottom: 1px solid $white-2;
}


@media (min-width: 992px) {
  .app.sidenav-toggled .main-sidebar-header {
    width: 70px;
    padding: 18px 15px;
  }

  .app.sidenav-toggled .app-header {
    padding-left: 70px;
  }

  .sidenav-toggled .app-sidebar-help {
    width: 70px;
  }

  .sidenav-toggled .app-sidebar-help .help-dropdown {
    display: none;
  }

  .sidenav-toggled.sidenav-toggled-open .app-sidebar-help {
    width: 250px;
  }

  .sidenav-toggled.sidenav-toggled-open .app-sidebar-help .help-dropdown {
    display: flex;
  }
}

.sidenav-toggled {
  @media (min-width: 992px) {
    .app-sidebar__user .user-pic {
      margin: 0;
    }
  }

  &.sidenav-toggled-open {
    .app-sidebar__user .user-pic {
      margin: 0 0 10px 0;
    }
  }
}

@media (max-width: 991px) {
  .app-sidebar {
    z-index: 9 !important;
    top: 65px !important;
  }
}

@media (max-width: 991.98px) {
  .main-content .container {
    max-width: none;
  }
}

@media (min-width: 992px) {
  .sidenav-toggled {
    .app-sidebar-help {
      .help {
        .ms-auto {
          margin: 0 auto !important;
        }
      }
    }

   .sidenav-toggled-open {
      .app-sidebar-help {
        .help {
          .ms-auto {
            margin-left: auto !important;
          }
        }
      }

    }
  }
}