body.rtl {
    text-align: right;
    direction: rtl;
}

.rtl {

    dd {
        margin-right: 0;
        margin-left: inherit;
    }

    caption {
        text-align: right;
    }

    .list-unstyled {
        padding-right: 0;
        padding-left: inherit;
    }

    .list-inline {
        padding-right: 0;
        padding-left: inherit;
    }

    .list-inline-item:not(:last-child) {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    /*------ Scroll -------*/

    * html .fileinput-button {
        margin: 1px 0 0 -3px;
    }

    .new-list.option-dots {
        left: 10px;
        right: inherit;
    }

    .page-style1 .input-group-addon {
        border: 1px solid $border;
        border-left: 0;
    }

    .page-style1 .input-group-text {
        border: 1px solid $border;
        border-right: 0;
        border-radius: 0 0 5px 5px;
    }

    .page-style1 .btn-svgs .svg-icon {
        right: 37%;
        left: inherit;
    }

    .bg-style .header-brand-img {
        right: 55px;
        left: inherit;
    }

    .card-pay .tabs-menu li:last-child a {
        border-left: 0;
        border-right: inherit;
    }

    .html-code {
        margin-right: 20px;
        margin-left: inherit;
        padding-right: 45px;
        padding-left: 22px;
    }

    .html-code .svg-icon {
        right: 14px;
        left: inherit;
    }

    .list-style {
        padding-right: inherit;
        padding-left: inherit;
    }

    .list-style ul {
        padding-right: 30px;
        padding-left: inherit;
    }

    .list-style2 {
        padding-right: inherit;
        padding-left: inherit;
    }

    .list-style2 ul {
        padding-right: 30px;
        padding-left: inherit;
    }

    .list-style3 {
        padding-right: inherit;
        padding-left: inherit;
    }

    .list-style3 ul {
        padding-right: 30px;
        padding-left: inherit;
    }

    .list-style4 {
        padding-right: inherit;
        padding-left: inherit;
    }

    .list-style4 ul {
        padding-right: 30px;
        padding-left: inherit;
    }

    .list-style5 {
        padding-right: inherit;
        padding-left: inherit;
    }

    .list-style5 ul {
        padding-right: 30px;
        padding-left: inherit;
    }

    .list-style6 {
        padding-right: inherit;
        padding-left: inherit;
    }

    .list-style6 ul {
        padding-right: 30px;
        padding-left: inherit;
    }

    .browse-file.form-control {
        border-top-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: inherit;
    }

    .input-group-btn .btn-primary {
        border-top-left-radius: 4px !important;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: 4px !important;
        border-bottom-right-radius: 0 !important;
    }

    .pagination {
        padding-right: 0;
        padding-left: inherit;
    }

    .page-link {
        margin-right: -1px;
        margin-left: inherit;
    }

    .page-item:first-child .page-link {
        margin-right: 0;
        margin-left: inherit;
        border-top-right-radius: 3px;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: inherit;
    }

    .page-item:last-child .page-link {
        border-top-left-radius: 3px;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: inherit;
    }

    .pagination-lg .page-item:first-child .page-link {
        border-top-right-radius: 3px;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: inherit;
    }

    .pagination-lg .page-item:last-child .page-link {
        border-top-left-radius: 3px;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: inherit;
    }

    .pagination-sm .page-item:first-child .page-link {
        border-top-right-radius: 3px;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: inherit;
    }

    .pagination-sm .page-item:last-child .page-link {
        border-top-left-radius: 3px;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: inherit;
    }

    /*-------Accordion-----------*/


    .more-less {
        float: left;
    }

    /* #bs-collapse icon scale option */
    .panel-title a.accordion-toggle:before {
        padding: 0 0 0 10px;
        float: left;
    }

    .panel-title a.accordion-toggle.collapsed:before {
        padding: 0 0 0 10px;
        float: left;
    }

    .panel-heading1 a.collapsed:before {
        left: 10px;
        right: inherit;
    }

    .panel-heading1 a:before {
        left: 10px;
        right: inherit;
    }

    #bs-collapse .panel-heading1 a:after {
        left: 5px;
        right: inherit;
    }

    /* #accordion rotate icon option */
    #accordion .panel-heading1 a:before {
        left: 5px;
        right: inherit;
    }

    /*-----Alerts-----*/


    .alert-dismissible {
        padding-left: 3.90625rem;
        padding-right: inherit;
    }

    .alert-dismissible .btn-close {
        left: 0;
        right: inherit;
    }

    .alert-icon {
        padding-right: 3rem;
        padding-left: inherit;
    }

    .alert-icon>i {
        right: 1rem;
        left: inherit;
    }

    .alert-avatar {
        padding-right: 3.75rem;
        padding-left: inherit;
    }

    .alert-avatar .avatar {
        right: 0.75rem;
        left: inherit;
    }

    /*----- Avatars -----*/

    .avatar-status {
        left: -2px;
        right: inherit;
    }

    .avatar-list .avatar:not(:last-child) {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    .avatar-list-stacked .avatar {
        margin-left: -0.8em !important;
        margin-right: inherit !important;
    }

    @-webkit-keyframes indeterminate {
        0% {
            right: -35%;
            left: 100%;
        }

        100%,
        60% {
            right: 100%;
            left: -90%;
        }
    }

    @keyframes indeterminate {
        0% {
            right: -35%;
            left: 100%;
        }

        100%,
        60% {
            right: 100%;
            left: -90%;
        }
    }

    @-webkit-keyframes indeterminate-short {
        0% {
            right: -200%;
            left: 100%;
        }

        100%,
        60% {
            right: 107%;
            left: -8%;
        }
    }

    @keyframes indeterminate-short {
        0% {
            right: -200%;
            left: 100%;
        }

        100%,
        60% {
            right: 107%;
            left: -8%;
        }
    }

    /*-----Badges-----*/

    .badgetext {
        float: left;
    }

    .shape {
        border-width: 0 0 45px 45px;
        float: left;
        border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) #d9534f;
    }

    .offer-danger .shape {
        border-color: transparent transparent transparent #dc0441;
    }

    .offer-success .shape {
        border-color: transparent transparent transparent #2dce89;
    }

    .offer-default .shape {
        border-color: transparent transparent transparent #6b6f80;
    }

    .offer-primary .shape {
        border-color: transparent transparent transparent #{$primary};
    }

    .offer-info .shape {
        border-color: transparent transparent transparent #45aaf2;
    }

    .offer-warning .shape {
        border-color: transparent transparent transparent #ecb403;
    }

    .shape-text {
        left: -27px;
        right: inherit;
        transform: rotate(-45deg);
    }

    /*------ Breadcrumb ------*/

    .breadcrumb-arrow li:first-child a {
        border-radius: 4px 4px 0 0;
    }

    .breadcrumb-arrow li:not(:first-child) {
        margin-right: -5px;
        margin-left: inherit;
    }

    .breadcrumb-arrow li a,
    .breadcrumb-arrow li:not(:first-child) span {
        padding: 0 25px 0 10px;
    }

    .breadcrumb-arrow li a:after {
        left: -12px;
        right: inherit;
        border-right: 11px solid #{$primary-01};
        border-left: 0;
    }

    .breadcrumb-arrow li a:before {
        left: -11px;
        right: inherit;
        border-right: 11px solid #{$primary};
        border-left: 0;
    }

    .breadcrumb-style4 .breadcrumb-icon {
        margin-right: 4px;
        margin-left: inherit;
    }

    /*----- Btn styles------*/

    .btn-list>.btn:not(:last-child),
    .btn-list>.dropdown:not(:last-child) {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    .btn-loading:after {
        border-left-color: transparent !important;
        border-right-color: inherit !important;
        right: calc(50% - 0.7em);
        left: inherit;
    }

    .btn-loading.btn-sm:after {
        right: calc(50% - 0.5em);
        left: inherit;
    }

    .btn-group-sm>.btn-loading.btn:after {
        right: calc(50% - 0.5em);
        left: inherit;
    }

    .btn-svgs .svg-icon {
        margin-left: 6px;
        margin-right: inherit;
    }

    .data-table-btn {
        right: 80px;
        left: inherit;
    }

    /*------ Default -------*/

    @media (min-width: 992px) {

        .card-group>.card+.card {
            margin-right: 0;
            margin-left: inherit;
            border-right: 0;
            border-left: inherit;
        }

        .card-group>.card:first-child {
            border-top-left-radius: 0;
            border-top-right-radius: 8px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 8px;
        }

        .card-group>.card:first-child .card-img-top,
        .card-group>.card:first-child .card-header {
            border-top-left-radius: 0;
            border-top-right-radius: inherit;
        }

        .card-group>.card:first-child .card-img-bottom,
        .card-group>.card:first-child .card-footer {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: inherit;
        }

        .card-group>.card:last-child {
            border-top-right-radius: 0;
            border-top-left-radius: 8px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 8px;
        }

        .card-group>.card:last-child .card-img-top,
        .card-group>.card:last-child .card-header {
            border-top-right-radius: 0;
            border-top-left-radius: inherit;
        }

        .card-group>.card:last-child .card-img-bottom,
        .card-group>.card:last-child .card-footer {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: inherit;
        }
    }

    /*------ Default Card Values -------*/

    .reg {
        float: left;
    }

    .card-table tr td:first-child,
    .card-table tr th:first-child {
        padding-right: 1.5rem;
        padding-left: inherit;
    }

    .card-table tr td:last-child,
    .card-table tr th:last-child {
        padding-left: 1.5rem;
        padding-right: 0.75rem;
    }

    .card-link+.card-link {
        margin-right: 0;
        margin-left: 1rem;
    }

    .list-media .media-img {
        float: right;
        margin-left: 20px;
        margin-right: inherit;
    }

    .list-media .info {
        padding-right: 55px;
        padding-left: inherit;
    }

    .list-media .info .text-right {
        left: 0;
        right: inherit;
    }

    .card-options {
        margin-left: -0.5rem;
        margin-right: auto;
    }

    .widgets-cards .wrp.icon-circle {
        margin-left: 15px;
        margin-right: inherit;
    }

    ul li .legend-dots {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    .legend li {
        float: right;
    }

    .card-img-absolute {
        left: 0;
        right: inherit;
    }

    .card-status-left {
        left: auto;
        right: 0;
        border-radius: 3px 0 0 3px;
    }

    /*------ Carousel -------*/

    .carousel-control-prev {
        left: 0;
        right: inherit;
    }

    .carousel-control-next {
        right: 0;
        left: inherit;
    }

    .carousel-indicators {
        padding-right: 0;
        padding-left: inherit;
    }

    .carousel-indicators li::before {
        right: 0;
        left: inherit;
    }

    .carousel-indicators li::after {
        right: 0;
        left: inherit;
    }

    .carousel-indicators1 {
        padding-right: 0;
        padding-left: inherit;
    }

    .carousel-indicators1 li::before {
        right: 0;
        left: inherit;
    }

    .carousel-indicators1 li::after {
        right: 0;
        left: inherit;
    }

    .carousel-indicators2 {
        left: 10px;
        right: inherit;
        padding-right: 0;
        padding-left: inherit;
    }

    .carousel-indicators2 li::before {
        right: 0;
        left: inherit;
    }

    .carousel-indicators2 li::after {
        right: 0;
        left: inherit;
    }

    .carousel-indicators3 {
        right: 10px;
        left: inherit;
        padding-right: 0;
        padding-left: inherit;
    }

    .carousel-indicators3 li::before {
        right: 0;
        left: inherit;
    }

    .carousel-indicators3 li::after {
        right: 0;
        left: inherit;
    }

    .carousel-indicators4 {
        left: 10px;
        right: inherit;
        padding-right: 0;
        padding-left: inherit;
    }

    .carousel-indicators4 li::before {
        right: 0;
        left: inherit;
    }

    .carousel-indicators4 li::after {
        right: 0;
        left: inherit;
    }

    .carousel-indicators5 {
        right: 10px;
        left: inherit;
        padding-right: 0;
        padding-left: inherit;
    }

    .carousel-indicators5 li::before {
        right: 0;
        left: inherit;
    }

    .carousel-indicators5 li::after {
        right: 0;
        left: inherit;
    }

    .carousel-top-right-indicators {
        left: 15px !important;
        right: auto !important;
    }

    .carousel-top-left-indicators {
        right: 15px !important;
        left: auto !important;
    }

    .carousel-bottom-right-indicators {
        left: 15px !important;
        right: auto !important;
    }

    .carousel-bottom-left-indicators {
        right: 15px !important;
        left: auto !important;
    }

    /*------Drop Downs-------*/

    .dropdown-toggle::after {
        margin-right: 0.255em;
        margin-left: inherit;
    }

    .dropdown-toggle:empty::after {
        margin-right: 0;
        margin-left: inherit;
    }

    .dropup .dropdown-toggle::after {
        margin-right: 0.255em;
        margin-left: inherit;
    }

    .dropup .dropdown-toggle:empty::after {
        margin-right: 0;
        margin-left: inherit;
    }

    .dropright .dropdown-menu {
        left: auto;
        right: 100%;
        margin-right: 0.125rem;
        margin-left: inherit;
    }

    .dropright .dropdown-toggle::after {
        margin-right: 0.255em;
        margin-left: inherit;
        border-left: 0;
        border-right: 0.3em solid;
    }

    .dropright .dropdown-toggle:empty::after {
        margin-right: 0;
        margin-left: inherit;
    }

    .dropleft .dropdown-menu {
        left: 100%;
        right: auto;
        margin-left: 0.125rem;
        margin-right: inherit;
    }

    .dropleft .dropdown-toggle::after {
        margin-right: 0.255em;
        margin-left: inherit;
    }

    .dropleft .dropdown-toggle::before {
        margin-left: 0.255em;
        margin-right: inherit;
        border-left: 0.3em solid;
        border-right: inherit;
    }

    .dropleft .dropdown-toggle:empty::after {
        margin-right: 0;
        margin-left: inherit;
    }

    .header-notify .show.dropdown-menu[data-popper-placement^=bottom] {
        right: -180px !important;
        left: inherit !important;
    }

    .profile-dropdown .show.dropdown-menu[data-popper-placement^=bottom] {
        right: -140px !important;
        left: inherit !important;
    }

    .notifyimg {
        float: right;
        margin-left: 10px;
        margin-right: inherit;
    }

    .drop-icon-wrap .drop-icon-item {
        float: right;
    }

    .dropdown-menu-arrow.dropdown-menu-end:before,
    .dropdown-menu-arrow.dropdown-menu-start:before {
        right: 15px;
        left: initial;
    }

    .dropdown-menu-arrow.dropdown-menu-end:after,
    .dropdown-menu-arrow.dropdown-menu-start:after {
        right: 16px;
        left: initial;
    }

    .btn-group .btn+.btn,
    .btn-group .btn+.btn-group {
        margin-right: -1px;
        margin-left: inherit;
    }

    .btn-group .btn-group+.btn,
    .btn-group .btn-group+.btn-group {
        margin-right: -1px;
        margin-left: inherit;
    }

    .btn-group-vertical .btn+.btn,
    .btn-group-vertical .btn+.btn-group {
        margin-right: -1px;
        margin-left: inherit;
    }

    .btn-group-vertical .btn-group+.btn,
    .btn-group-vertical .btn-group+.btn-group {
        margin-right: -1px;
        margin-left: inherit;
    }

    .btn-group>.btn:first-child {
        margin-right: 0;
        margin-left: initial;
    }

    .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
        border-top-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 5px;
    }

    .btn-group>.btn-group:not(:last-child)>.btn {
        border-top-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: inherit;
    }

    .btn-group>.btn:not(:first-child),
    .btn-group>.btn-group:not(:first-child)>.btn {
        border-top-right-radius: 0;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 5px;
    }

    .dropdown-toggle-split::after {
        margin-right: 0;
        margin-left: inherit;
    }

    .dropup .dropdown-toggle-split::after,
    .dropright .dropdown-toggle-split::after {
        margin-right: 0;
        margin-left: inherit;
    }

    .dropleft .dropdown-toggle-split::before {
        margin-left: 0;
        margin-right: inherit;
    }

    .btn-group-vertical>.btn+.btn,
    .btn-group-vertical>.btn+.btn-group {
        margin-right: 0;
        margin-left: inherit;
    }

    .btn-group-vertical>.btn-group+.btn,
    .btn-group-vertical>.btn-group+.btn-group {
        margin-right: 0;
        margin-left: inherit;
    }

    /*-----List Of Dropdwons-----*/

    .dropdown-menu {
        left: 0;
        right: inherit;
        float: right;
        text-align: right;
    }

    .dropdown-menu-end[data-bs-popper] {
        left: 0;
        right: auto;
    }

    .dropdown-menu[data-bs-popper] {
        left: 0;
        right: inherit;
    }

    .dropdown-menu[data-popper-placement^=top],
    .dropdown-menu[data-popper-placement^=right],
    .dropdown-menu[data-popper-placement^=bottom],
    .dropdown-menu[data-popper-placement^=left] {
        left: auto;
        right: inherit;
        // transform: translate(0, 47px) !important;
    }

    .dropdown-media-list .media-body {
        margin-right: 15px;
        margin-left: inherit;
    }

    .dropdown-menu-arrow.dropdown-menu-end:before,
    .dropdown-menu-arrow.dropdown-menu-end:after {
        right: auto;
        left: 12px;
    }

    /*-----Lists-----*/
    .list-group {
        padding-right: 0;
        padding-left: inherit;
    }

    .btn-close {
        float: left;
    }

    .list-inline-dots .list-inline-item+.list-inline-item:before {
        margin-right: -2px;
        margin-left: 3px;
    }

    .icon-absolute {
        left: 10px;
        right: inherit;
    }

    /*------ Modal -------*/
    .modal {
        padding-left: 0 !important;
        padding-right: inherit !important;
    }

    .modal-open,
    .modal-backdrop.fade.show {
        padding-left: 0 !important;
        padding-right: inherit !important;
    }


    .modal-footer> :not(:first-child) {
        margin-right: 0.25rem;
        margin-left: inherit;
    }

    .modal-footer> :not(:last-child) {
        margin-left: 0.25rem;
        margin-right: inherit;
    }

    /*------ Navigation -------*/

    .nav-tabs .nav-item1 i {
        margin-left: 10px;
        margin-right: inherit;
    }

    .nav-tabs .nav-item i {
        margin-left: 10px;
        margin-right: inherit;
    }

    .navbar-brand {
        margin-left: 1rem;
        margin-right: inherit;
    }

    .navbar-nav {
        padding-right: 0;
        padding-left: inherit;
    }

    /*--navtabs--*/

    .nav1 {
        padding-right: 0;
        padding-left: inherit;
    }

    .header .nav-link .badge,
    .header .nav-item .badge {
        left: -6px;
        right: inherit;
    }

    .progress-bar-indeterminate:after {
        right: 0;
        left: inherit;
    }

    .progress-bar-indeterminate:before {
        right: 0;
        left: inherit;
    }

    /*---- Tags-----*/

    .tag-addon {
        margin: 0 0.5rem 0 -0.5rem;
    }

    .tag-addon:last-child {
        border-top-left-radius: 3px;
        border-top-right-radius: 0;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 0;
    }

    .tag-avatar {
        border-radius: 0 3px 3px 0;
        margin: 0 -0.5rem 0 0.5rem;
    }

    .tags>.tag:not(:last-child) {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    /*---------Thumbnails----------*/

    .video-list-thumbs .fa {
        left: 39%;
        right: inherit;
    }

    .video-list-thumbs .duration {
        right: 12px;
        left: inherit;
    }

    @media (max-width: 768px) {
        .video-list-thumbs .fa {
            left: 44%;
            right: inherit;
        }
    }

    /*-------Tooptip &popover------*/



    /*------ Media object ------*/

    .media-right,
    .media>.pull-right {
        padding-right: 10px;
        padding-left: inherit;
    }

    .media-left,
    .media>.pull-left {
        padding-left: 10px;
        padding-right: inherit;
    }

    /* ###### components ####### */

    .fc-view.fc-listMonth-view .fc-list-item,
    .fc-view.fc-listWeek-view .fc-list-item {
        border-right: 4px solid transparent;
        border-left: inherit;
    }

    .fc-view.fc-listMonth-view .fc-list-item>td.fc-list-item-time,
    .fc-view.fc-listWeek-view .fc-list-item>td.fc-list-item-time {
        border-left: 1px solid $border;
        border-right: inherit;
    }

    .fc-view.fc-listMonth-view .fc-list-item>td.fc-list-item-title,
    .fc-view.fc-listWeek-view .fc-list-item>td.fc-list-item-title {
        border-left: 1px solid $border;
        border-right: inherit;
    }

    .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
        margin-right: 20%;
        margin-left: inherit;
    }

    @media (min-width: 992px) {

        .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
        .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
            margin-right: 10%;
            margin-left: inherit;
        }
    }

    @media (min-width: 576px) {

        .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
        .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
            margin-right: 15%;
            margin-left: inherit;
        }
    }

    @media (min-width: 768px) {

        .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
        .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
            margin-right: 12%;
            margin-left: inherit;
        }
    }

    @media (min-width: 1200px) {

        .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
        .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
            margin-right: 8%;
            margin-left: inherit;
        }
    }

    /*-----Chat----*/

    #messages-main .ms-user {
        border-right: 0;
        border-left: inherit;
    }

    #messages-main .ms-user>div {
        padding: 3px 15px 0 5px;
    }

    #messages-main #ms-compose {
        left: 30px;
        right: inherit;
    }

    @media (min-width: 992px) {
        #messages-main .ms-menu {
            right: 0;
            left: inherit;
            border-left: 1px solid $border;
            border-right: 0;
        }

        #messages-main .ms-body {
            padding-right: 300px;
            padding-left: inherit;
        }
    }

    @media (max-width: 480px) {
        .ah-actions {
            margin-right: 50px !important;
            margin-left: inherit !important;
        }
    }

    #ms-menu-trigger {
        right: 0;
        left: inherit;
        padding-left: 10px;
        padding-right: inherit;
        text-align: left;
    }

    .message-feed.right>.pull-right {
        margin-right: 15px;
        margin-left: inherit;
    }

    .message-feed:not(.right) .mf-content {
        margin-right: 5px;
        margin-left: inherit;
    }

    .message-feed.right .mf-content {
        margin-left: 5px;
        margin-right: inherit;
    }

    .msb-reply button {
        left: 9px;
        right: inherit;
    }

    .message-feed.right {
        text-align: left;
    }

    .action-header {
        padding: 15px 17px 15px 13px;
    }

    /*=====================================
    Inbox Message Style
    =======================================*/

    .inbox-message .message-avatar {
        right: 30px;
        left: inherit;
    }

    .inbox-message .message-body {
        margin-right: 85px;
        margin-left: inherit;
    }

    .message-body h5 span {
        margin-right: 5px;
        margin-left: inherit;
    }

    .message-body-heading span {
        float: left;
    }

    .message-feed:not(.right) .mf-content:before {
        left: auto;
        right: -12px;
        transform: rotate(90deg);
    }

    .message-feed.right .mf-content:before {
        right: auto;
        left: -12px;
        transform: rotate(-90deg);
    }

    .chatbox .user_info {
        margin-right: 15px;
        margin-left: inherit;
    }

    .chat .action-header {
        padding: 15px 17px 15px 13px;
    }

    .chat .dropdown-menu.dropdown-menu-end.show {
        right: 0px;
        left: inherit;
    }

    .chat .msb-reply textarea {
        margin-right: -42px;
        margin-left: inherit;
    }

    .chat .video_cam {
        margin-right: 50px;
        margin-left: inherit;
    }

    .chat .video_cam span {
        margin-left: 20px;
        margin-right: inherit;
    }

    .chat .msg_cotainer {
        margin-right: 10px;
        margin-left: inherit;
    }

    .chat .msg_cotainer:before {
        left: auto;
        right: -12px;
        transform: rotate(90deg);
    }

    .chat .msg_cotainer_send {
        margin: 0 0 10px 12px;
    }

    .chat .msg_cotainer_send:after {
        right: auto;
        left: -12px;
        transform: rotate(-90deg);
    }

    .chat .msg_time {
        right: 0px;
        left: inherit;
    }

    .chat .msg_time_send {
        left: 0;
        right: inherit;
    }

    #action_menu_btn {
        left: 36px;
        right: inherit;
    }

    .action_menu {
        left: 15px;
        right: inherit;
    }

    .ah-actions {
        float: left !important;
    }

    .main-nav-line-chat .nav-link {
        margin-right: 10px;
        margin-left: inherit;
    }

    .main-chat-contacts {
        padding-right: 20px;
        padding-left: inherit;
    }

    .main-chat-list .main-img-user span {
        right: -2px;
        left: inherit;
    }

    .main-chat-list .media-body {
        margin-right: 15px;
        margin-left: inherit;
    }

    .main-chat-header .nav {
        margin-right: auto;
        margin-left: inherit;
    }

    .main-chat-header .nav-link+.nav-link {
        margin-right: 10px;
        margin-left: inherit;
    }

    .main-chat-msg-name {
        margin-right: 15px;
        margin-left: inherit;
    }

    .main-chat-body .media.flex-row-reverse .media-body {
        margin-right: 0;
        margin-left: 5px;
    }

    .main-chat-body .media.flex-row-reverse .main-msg-wrapper {
        margin-left: 15px;
        margin-right: inherit;
        border-radius: 0px 4px 4px 4px;
    }

    .main-chat-body .media-body {
        margin-right: 5px;
        margin-left: inherit;
    }

    .main-msg-wrapper {
        margin-right: 10px;
        margin-left: inherit;
        border-radius: 4px 0 4px 4px;
    }

    .main-chat-footer .nav-link+.nav-link {
        margin-right: 10px;
        margin-left: inherit;
    }

    .main-chat-footer .nav-link:last-child {
        margin-right: 0;
        margin-left: inherit;
    }

    @media (min-width: 992px) {

        .main-nav-line-chat {
            padding-left: 0;
            padding-right: inherit;
        }

        .main-chat-contacts-wrapper {
            padding: 10px 15px 10px 0px;
        }
    }

    @media (min-width: 576px) {

        .main-chat-body .media.flex-row-reverse .media-body {
            margin-right: 0;
            margin-left: 5px;
        }

        .main-chat-body .media-body {
            margin-left: 0;
            margin-right: 5px;
        }

        .main-chat-footer .form-control {
            margin-right: 20px;
            margin-left: inherit;
        }
    }

    .main-img-user.online:before {
        left: 2px;
        right: inherit;
    }

    .main-chat-body .media.flex-row-reverse .main-msg-wrapper:before {
        right: auto;
        left: -12px;
        transform: rotate(-90deg);
    }

    .main-msg-wrapper:before {
        left: auto;
        right: -12px;
        transform: rotate(90deg);
    }

    @media (max-width: 576px) {
        .main-contact-action {
            right: 10px !important;
            left: 0 !important;
        }
    }

    .main-content-left-contacts .main-content-breadcrumb,
    .main-content-left-contacts .main-content-title {
        padding-right: 20px;
        padding-left: inherit;
    }

    .main-contact-label {
        padding-right: 20px;
        padding-left: inherit;
    }

    .main-contact-item {
        border: 1px solid transparent;
        border-left-width: 0;
    }

    .main-contact-item.selected {
        border-right-color: #{$primary};
        border-left-color: inherit;
    }

    .main-contact-body {
        margin-right: 15px;
        margin-left: inherit;
    }

    .main-contact-info-header {
        padding-right: 20px;
        padding-left: inherit;
    }

    .main-contact-info-header .main-img-user a {
        left: 0;
        right: inherit;
    }

    .main-contact-info-header .media-body .nav-link i {
        margin-left: 5px;
        margin-right: inherit;
    }

    .main-contact-info-header .media-body .nav-link+.nav-link {
        margin-right: 10px;
        margin-left: inherit;
    }

    @media (min-width: 576px) {

        .main-contact-info-header .media-body .nav-link i {
            margin-left: 7px;
            margin-right: inherit;
        }

        .main-contact-info-header .media-body .nav-link+.nav-link {
            margin-right: 10px;
            margin-left: inherit;
        }
    }

    .main-contact-action {
        left: 10px;
        right: inherit;
    }

    .main-contact-action a i {
        margin-left: 5px;
        margin-right: inherit;
    }

    .main-contact-action a+a {
        margin-right: 15px;
        margin-left: inherit;
    }

    .main-contact-info-body {
        padding-right: 0px;
        padding-left: inherit;
    }

    .main-contact-info-body .media-body {
        margin-right: 30px;
        margin-left: inherit;
    }

    @media (min-width: 992px) {
        .main-contact-info-header {
            padding-right: 30px;
            padding-left: inherit;
        }

        .main-contact-label {
            padding-right: 10px;
            padding-left: inherit;
        }

        .main-content-left-contacts .main-content-breadcrumb,
        .main-content-left-contacts .main-content-title {
            padding-right: 0;
            padding-left: inherit;
        }
    }

    @media (min-width: 1280px) {
        .main-contact-action {
            left: 20px;
            right: inherit;
        }
    }

    /*----- Range slider -------*/

    .jsr_slider {
        right: 0;
        left: inherit;
    }

    .jsr_slider::before {
        right: 50%;
        left: inherit;
    }

    /* Merged labels */
    .jsr_label .jsr_label {
        padding-left: 0;
        padding-right: inherit;
    }

    .range output {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: inherit;
        border-top-right-radius: 0;
        border-top-left-radius: inherit;
    }

    /*------ Headers -------*/

    .page-header .breadcrumb-icon {
        margin-right: 4px;
        margin-left: inherit;
    }

    .page-subtitle {
        margin-right: 2rem;
        margin-left: inherit;
    }

    .page-options {
        margin-right: auto;
        margin-left: inherit;
    }

    .aside {
        left: 0;
        right: inherit;
        border-right: 1px solid $border;
        border-left: inherit;
    }

    .nav-unread {
        left: 0.4rem;
        right: inherit;
    }

    .nav-unread.badge {
        left: 0;
        right: inherit;
    }

    .header-btn.has-new:before {
        left: 4px;
        right: inherit;
    }

    .header-toggler-icon {
        right: 50%;
        left: inherit;
        margin: -2px -0.5rem 0 0;
    }

    @media (min-width: 992px) {
        footer.footer {
            padding: 1.25rem 250px 1.25rem 1.25rem;
        }
    }

    .footer .social ul li {
        float: right;
    }

    .br-tl-7 {
        border-top-right-radius: 7px !important;
        border-top-left-radius: 0 !important;
    }

    .br-bl-7 {
        border-bottom-right-radius: 7px !important;
        border-bottom-left-radius: 0 !important;
    }

    .br-tr-7 {
        border-top-left-radius: 7px !important;
        border-top-right-radius: 0 !important;
    }

    .br-br-7 {
        border-bottom-left-radius: 7px !important;
        border-bottom-right-radius: 0 !important;
    }

    .br-tl-0 {
        border-top-right-radius: 0px !important;
        border-top-left-radius: 7px !important;
    }

    .br-bl-0 {
        border-bottom-right-radius: 0px !important;
        border-bottom-left-radius: 7px !important;
    }

    .br-tr-0 {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 7px !important;
    }

    .br-br-0 {
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 7px !important;
    }

    /*-----Countdown-----*/

    .card-blog-overlay:before {
        left: 0;
        right: inherit;
    }

    .card-blog-overlay1:before,
    .card-blog-overlay2:before {
        left: 0;
        right: inherit;
    }

    .login-transparent:before {
        left: 0;
        right: inherit;
    }

    @media (max-width: 320px) {
        .fc-toolbar .fc-right {
            float: left;
        }
    }

    .pagination-pager .page-prev {
        margin-left: auto;
        margin-right: inherit;
    }

    .pagination-pager .page-next {
        margin-right: auto;
        margin-left: inherit;
    }

    .page-total-text {
        margin-left: 1rem;
        margin-right: inherit;
    }

    .icons-list {
        margin: 0 0 -1px -1px;
    }

    /* --------Added--------- css*/

    .richText .richText-toolbar ul li a {
        border-left: 1px solid $black-1;
        border-right: inherit;
    }

    .richText .richText-undo,
    .richText .richText-redo {
        border-left: 1px solid $border  !important;
        border-right: inherit !important;
    }

    .cal1 .clndr .clndr-table .header-days .header-day {
        border-right: 1px solid rgba(23, 183, 148, 0.1);
        border-left: inherit;
    }

    .cal1 .clndr .clndr-table tr .empty,
    .cal1 .clndr .clndr-table tr .adjacent-month,
    .cal1 .clndr .clndr-table tr .my-empty,
    .cal1 .clndr .clndr-table tr .my-adjacent-month {
        border-right: 1px solid $border;
        border-left: inherit;
    }

    .cal1 .clndr .clndr-table tr .day {
        border-right: 1px solid $border;
        border-left: inherit;
    }

    .cal1 .clndr .clndr-table tr .day:last-child {
        border-left: 1px solid $border;
        border-right: inherit;
    }

    div.conv-form-wrapper div#messages div.message.from {
        border-top-left-radius: 0;
        border-top-right-radius: inherit;
    }

    .job-box-filter a.filtsec {
        margin-left: 15px;
        margin-right: inherit;
    }

    .job-box-filter a.filtsec i {
        margin-left: 5px;
        margin-right: inherit;
    }

    .job-box-filter h4 i {
        margin-left: 10px;
        margin-right: inherit;
    }

    .job-box-filter label input.form-control {
        margin-right: 5px;
        margin-left: inherit;
    }

    .text-right {
        text-align: left;
    }

    /*--- img container---*/

    .image_outer_container .green_icon {
        left: 10px;
        right: inherit;
    }

    .input-search {
        left: 20px;
        right: 0;
    }

    .search-label {
        right: 50%;
        left: inherit;
        margin-right: -54px;
        margin-left: inherit;
    }

    .search-inline {
        right: 0;
        left: inherit;
    }

    .search-inline button[type=submit] {
        left: 80px;
        right: inherit;
    }

    .search-inline .form-control {
        padding-right: 0;
        padding-left: inherit;
        right: 1%;
        left: inherit;
    }

    .search-inline .search-close {
        left: 0;
        right: inherit;
    }

    .lockscreen:before {
        right: -25px;
        left: 0;
    }

    .table-stats table th img,
    .table-stats table td img {
        margin-left: 10px;
        margin-right: inherit;
    }

    .traffic-sales-amount {
        float: left;
    }

    .pulse {
        left: 0.7rem;
        right: inherit;
    }

    @media (max-width: 992px) and (min-width: 768px) {
        .animated-arrow.hor-toggle span {
            margin-right: 8px;
            margin-left: inherit;
        }
    }

    .activity {
        border-right: 3px solid $border;
        border-left: inherit;
        margin-right: 16px;
        margin-left: inherit;
    }

    .activity .img-activity {
        right: -20px;
        left: inherit;
    }

    .activity .item-activity {
        margin-right: 40px;
        margin-left: inherit;
    }

    #AreaChart2 {
        right: -7px;
        left: inherit;
    }

    #CryptoChart,
    #CryptoChart1,
    #CryptoChart2,
    #CryptoChart3,
    #CryptoChart11 {
        right: -55px;
        left: inherit;
    }

    @media (max-width: 991px) {

        #CryptoChart,
        #CryptoChart1,
        #CryptoChart2,
        #CryptoChart3,
        #CryptoChart11 {
            right: -65px;
            left: inherit;
        }
    }

    /*latetes news timeline */

    .latest-timeline ul.timeline:before {
        right: 15px;
        left: inherit;
    }

    .latest-timeline ul.timeline>li {
        padding-right: 3rem;
        padding-left: inherit;
    }

    .latest-timeline ul.timeline>li:before {
        right: 6px;
        left: inherit;
    }

    /*Horizontal Centerlogo */
    @media (min-width: 992px) {
        .top-header.hor-top-header .header-brand {
            margin-left: 0rem;
            margin-right: inherit;
        }
    }

    .latest-timeline1-icon {
        right: 0;
        left: inherit;
    }

    .inde4-table tr td:first-child:before {
        right: 0;
        left: inherit;
    }

    .crypto-icon {
        margin-left: 18px;
        margin-right: inherit;
    }

    table.table-bordered.dataTable td:first-child {
        border-right: 0px solid $border  !important;
        border-left: inherit !important;
    }

    .expenses-card #expense {
        right: -53px;
        left: inherit;
    }

    @media (max-width: 576px) {
        .expenses-card #expense {
            right: -67px;
            left: inherit;
        }
    }

    /*----- clipboard-----*/

    .clipboard-icon {
        left: 10px;
        right: inherit;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr[role=row]>td:first-child:before,
    table.dataTable.dtr-inline.collapsed>tbody>tr[role=row]>th:first-child:before {
        right: 4px;
        left: inherit;
    }

    .profile-media-img h6 {
        right: 15px;
        left: inherit;
    }

    .timelineleft:before {
        right: 31px;
        left: inherit;
    }

    .timelineleft>li {
        margin-left: 0;
        margin-right: inherit;
    }

    .timelineleft>li>.timelineleft-item {
        margin-right: 60px;
        margin-left: 0;
    }

    .timelineleft>li>.timelineleft-item>.time {
        float: left;
    }

    .timelineleft>li>.fa,
    .timelineleft>li>.glyphicon,
    .timelineleft>li>.ion {
        right: 18px;
        left: inherit;
    }

    .form-switch-indicator-lg:before {
        right: 1px;
        left: inherit;
    }

    .form-switch-input:checked~.form-switch-indicator-lg:before {
        right: calc(1.46rem + 1px);
        left: inherit;
    }

    .form-switch-indicator-xl:before {
        right: 1px;
        left: inherit;
    }

    .form-switch-input:checked~.form-switch-indicator-xl:before {
        right: calc(1.96rem + 1px) !important;
        left: inherit !important;
    }

    .header-icon2 {
        right: 10px;
        left: inherit;
    }

    .page-rightheader .date-range-btn {
        padding-right: 40px;
        padding-left: 1em;
    }

    .page-rightheader .date-range-btn .header-icon2 {
        right: 10px;
        left: inherit;
    }

    .social-profile-buttons li {
        float: right;
    }

    .form-wizard-title {
        margin-right: 10px;
        margin-left: inherit;
    }

    .crypto-content .select2-container--default .select2-selection--single {
        border-radius: 5px 0 0 5px !important;
    }

    .header-notify.show .dropdown-menu {
        right: -180px !important;
        left: inherit !important;
    }

    .profile-dropdown.show .dropdown-menu {
        right: -140px !important;
        left: inherit !important;
    }

    .countryscroll .ps__thumb-y {
        left: 12px !important;
        right: inherit !important;
    }

    /*---- IE11 Support ----*/

    .btn-close {
        float: left;
        margin-right: 3px;
        margin-left: inherit;
    }

    .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 5px !important;
        border-top-right-radius: 0 !important;
        border-top-left-radius: 5px !important;
    }

    .input-group>.btn-group>.btn,
    .input-group>.btn-group>.input-group-text {
        border-top-left-radius: 3px !important;
        border-bottom-left-radius: 3px !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    table.table-bordered.dataTable th:first-child,
    table.table-bordered.dataTable th:first-child,
    table.table-bordered.dataTable td:first-child,
    table.table-bordered.dataTable td:first-child {
        border-right-width: 1px !important;
        border-left-width: inherit !important;
    }

    &.error-page .header-setting-icon {
        right: auto;
        left: 30px;
    }

    @media (max-width: 767px) {
        &.error-page .header-setting-icon {
            right: auto;
            left: 20px;
        }
    }

    @media (min-width: 576px) and (max-width: 991px) {
        &.error-page .card-group>.card:not(:last-child) {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }

    .jq-star-svg {
        padding-right: 0;
        padding-left: inherit;
    }

    .handle-counter input {
        float: right;
    }

    .handle-counter .counter-minus,
    .handle-counter .handle-counter .counter-plus {
        float: right;
    }

    .handle-counter .counter-minus {
        border-radius: 0px 3px 3px 0px !important;
    }

    .handle-counter .counter-plus {
        border-radius: 3px 0px 0px 3px !important;
    }

    .form-control.form-file-input::after {
        left: 0;
        right: inherit;
    }

    /*------ Email services -------*/

    .mail-box .sm-side {
        border-radius: 4px 4px 0 0;
    }

    .mail-box .lg-side {
        border-radius: 0 0 4px 4px;
    }

    .user-head .inbox-avatar {
        float: right;
    }

    ul.inbox-nav li a i {
        padding-left: 10px;
        padding-right: inherit;
    }

    ul.labels-info li a i {
        padding-left: 10px;
        padding-right: inherit;
    }

    .inbox-head .sr-input {
        border-radius: 4px 4px 0 0;
        float: right;
    }

    .inbox-head .sr-btn {
        border-radius: 0 0 4px 4px;
    }

    ul.inbox-pagination {
        float: left;
    }

    ul.inbox-pagination li {
        float: right;
    }

    .mail-option .chk-all,
    .mail-option .btn-group {
        margin-left: 5px;
        margin-right: inherit;
    }

    .inbox-pagination a.np-btn {
        margin-right: 5px;
        margin-left: inherit;
    }

    .inbox-pagination li span {
        margin-left: 5px;
        margin-right: inherit;
    }

    .attachment-mail ul li {
        float: right;
        margin-left: 10px;
        margin-right: inherit;
    }

    .attachment-mail ul li span {
        float: left;
    }

    .attachment-mail .file-name {
        float: right;
    }

    .fileinput-button {
        float: right;
        margin-left: 4px;
        margin-right: inherit;
    }

    .fileinput-button input {
        direction: rtl;
        left: 0;
        right: inherit;
    }

    /*------Email---------*/
    .mail-chats {
        padding: 10px 0 10px 50px;
    }

    .mail-chats li.chat-persons a span.pro-pic {
        float: right;
        margin-left: 20px;
        margin-right: inherit;
    }

    .mail-chats li.chat-persons.user {
        padding: 5px 15px 0px 10px;
    }

    /*-------- Loaders ---------*/
    /*Sk circle*/
    .sk-circle .sk-child {
        right: 0;
        left: inherit;
    }

    /*Sk cube*/
    .sk-cube-grid .sk-cube {
        float: right;
    }

    /*sk-folding-cube*/

    .sk-folding-cube .sk-cube {
        float: right;
    }

    .sk-folding-cube .sk-cube:before {
        right: 0;
        left: inherit;
    }

    .double-bounce1 {
        right: 0;
        left: inherit;
    }

    .double-bounce2 {
        right: 0;
        left: inherit;
    }

    .cube1 {
        right: 0;
        left: inherit;
    }

    .cube2 {
        right: 0;
        left: inherit;
    }

    .lds-heart div {
        right: 19px;
        left: inherit;
    }

    .lds-heart div:before {
        right: -17px;
        left: inherit;
        border-radius: 50% 50% 0 0;
    }

    /*------ Tables -----*/

    .table-calendar-link:before {
        right: 0.25rem;
        left: inherit;
    }

    .table-header:after {
        margin-right: 0.5rem;
        margin-left: inherit;
    }

    .jvectormap-zoomin,
    .jvectormap-zoomout,
    .jvectormap-goback {
        right: 10px;
        left: inherit;
    }

    .jvectormap-legend-cnt-v {
        left: 0;
        right: inherit;
    }

    .jvectormap-legend-cnt-h .jvectormap-legend {
        float: right;
        margin: 0 0 10px 10px;
    }

    .jvectormap-legend-cnt-h .jvectormap-legend .jvectormap-legend-tick {
        float: right;
    }

    .jvectormap-legend-cnt-v .jvectormap-legend {
        margin: 10px 0 0 10px;
    }

    .jvectormap-legend-cnt-h {
        left: 0;
        right: inherit;
    }

    .jvectormap-legend-cnt-v .jvectormap-legend-tick-text {
        padding-right: 3px;
        padding-left: inherit;
    }

    /**widgets*/

    .widget-line-list li {
        padding: 5px 15px 0 20px;
    }

    /*----Profile----*/

    .input-icon .form-control:not(:last-child),
    .input-icon .form-control:not(:first-child) {
        padding-right: 2.5rem;
        padding-left: inherit;
    }

    .input-icon-addon {
        right: 0;
        left: inherit;
    }

    .input-icon-addon:last-child {
        right: auto;
        left: inherit;
    }

    .state-valid {
        padding-left: 2rem;
        padding-right: inherit;
    }

    .state-invalid {
        padding-left: 2rem;
        padding-right: inherit;
    }

    .status-icon {
        margin-left: 0.375rem;
        margin-right: inherit;
    }

    .team i {
        margin-right: 10px;
        margin-left: inherit;
        float: left;
    }

    .chips .chip {
        margin: 0 0 0.5rem 0.5rem;
    }

    .chip .avatar {
        float: left;
        margin: 0 -0.75rem 0 0.5rem;
    }

    .chat-line {
        text-align: left;
    }

    .chat-message {
        text-align: right;
        margin: 0 2.5rem 0 0.5rem;
    }

    .chat-message:after {
        left: -5px;
        right: inherit;
        border-right: 6px solid #467fcf;
        border-left: inherit;
    }

    .chat-line-friend .chat-message {
        margin-right: 0.5rem;
        margin-left: 2.5rem;
    }

    .chat-line-friend .chat-message:after {
        left: auto;
        right: -5px;
        border-right-width: 0;
        border-left-width: inherit;
        border-left: 5px solid #f3f3f3;
        border-right: inherit;
    }

    /*-----Back to Top-----*/
    #back-to-top {
        left: 20px;
        right: inherit;
    }

    .item-card .cardprice {
        left: 0;
        right: inherit;
    }

    @media (min-width: 992px) {
        .invoicedetailspage.tabs-menu-body {
            border-radius: 8px 0 0 8px;
        }
    }

    /*----- Custom control -----*/
    .card-custom-icon {
        left: 25px;
        right: inherit;
    }

    .custom-control {
        padding-right: 1.5rem;
        padding-left: inherit;
    }

    .custom-control-inline {
        margin-left: 1rem;
        margin-right: inherit;
    }

    .custom-control-label::before {
        right: 0;
        left: inherit;
    }

    .custom-control-label::after {
        right: 0;
        left: inherit;
    }

    .custom-control-label-md {
        padding-right: 15px;
        padding-left: inherit;
    }

    .custom-control-label-lg {
        padding-right: 15px;
        padding-left: inherit;
    }

    .form-select[multiple],
    .form-select[size]:not([size="1"]) {
        padding-left: 0.75rem;
        padding-right: inherit;
    }

    div.dataTables_wrapper div.dataTables_filter input {
        margin-right: 0.5em;
        margin-left: inherit;
    }

    .form-file-label::after {
        left: 0;
        right: inherit;
        border-right: 1px solid #{$primary};
        border-left: inherit;
        border-radius: 0 0 3px 3px;
    }

    .form-label-small {
        float: left;
    }

    .input-group-text>.input-group-text {
        border-left: 0;
        border-right: inherit;
    }

    .input-group-text>.input-group-text {
        border-right: 0;
        border-left: inherit;
    }

    .form-range {
        padding-right: 0;
        padding-left: inherit;
    }

    .form-range::-ms-fill-upper {
        margin-left: 15px;
        margin-right: inherit;
    }

    /*----- Date Picker ----*/

    .ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
        left: 5px;
        right: inherit;
    }

    .ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before {
        right: 5px;
        left: inherit;
    }

    .ui-datepicker .ui-datepicker-calendar td {
        text-align: left;
    }

    .ui-datepicker-multi .ui-datepicker-group {
        padding-left: 15px;
        padding-right: inherit;
        float: right;
    }

    .ui-datepicker-multi .ui-datepicker-group .ui-datepicker-prev::before {
        right: 10px;
        left: inherit;
    }

    .ui-datepicker-multi .ui-datepicker-group .ui-datepicker-next::before {
        left: 10px;
        right: inherit;
    }

    .ui-datepicker-multi .ui-datepicker-group-last {
        padding-left: 0;
        padding-right: inherit;
    }

    .custom-checkbox:before {
        right: 0;
        left: inherit;
    }

    .custom-checkbox:after {
        right: 0;
        left: inherit;
    }

    /*------- Forms -------*/

    .form-check {
        padding-right: 1.25rem;
        padding-left: inherit;
    }

    .form-check-input {
        margin-right: -1.25rem;
        margin-left: inherit;
    }

    .form-check-inline {
        padding-right: 0;
        padding-left: inherit;
        margin-left: 0.75rem;
        margin-right: inherit;
    }

    .form-check-inline .form-check-input {
        margin-left: 0.3125rem;
        margin-right: 0;
    }

    @media (min-width: 576px) {

        .form-inline .form-check {
            padding-right: 0;
            padding-left: inherit;
        }

        .form-inline .form-check-input {
            margin-left: 0.25rem;
            margin-right: 0;
        }
    }

    /*--- WIZARD ELEMENTS ---*/

    .wizard>.steps>ul li+li {
        margin-right: 5px;
        margin-left: inherit;
    }

    .wizard>.steps a .number,
    .wizard>.steps a:hover .number,
    .wizard>.steps a:active .number {
        margin-left: 5px;
        margin-right: inherit;
    }

    .wizard.vertical>.steps ul li+li {
        margin-right: 10px;
        margin-left: inherit;
    }

    @media (min-width: 992px) {
        .wizard.vertical>.steps {
            float: right;
        }

        .wizard.vertical>.steps ul li+li {
            margin-right: 0;
            margin-left: inherit;
        }

        .wizard.vertical>.actions {
            float: left;
            border-right: 1px solid $border;
            border-left: 0;
        }

        .wizard>.steps>ul li+li {
            margin-right: 20px;
            margin-left: inherit;
        }

        .wizard>.steps>ul li .title {
            margin-right: 10px;
            margin-left: inherit;
        }
    }


    /***** CUSTOM STYLES *****/

    .wizard-style-2>.steps>ul a .title,
    .wizard-style-2>.steps>ul a:hover .title,
    .wizard-style-2>.steps>ul a:active .title {
        margin-left: 20px;
        margin-right: inherit;
    }

    /*--- WIZARD ELEMENTS ---*/

    @media (min-width: 992px) {
        .wizard>.steps>ul li+li {
            margin-right: 30px;
            margin-left: inherit;
        }

        .wizard.vertical>.content {
            float: left;
            border-right: 1px solid $border;
            border-left: 0;
        }
    }

    .input-group-addon {
        border: 1px solid $border;
        border-left: 0;
        border-radius: 0.25rem 0.2rem 0 0;
    }

    .input-group>.form-control+.form-control,
    .input-group>.form-control+.form-select,
    .input-group>.form-control+.form-file {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group>.form-select+.form-control,
    .input-group>.form-select+.form-select,
    .input-group>.form-select+.form-file {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group>.form-file+.form-control,
    .input-group>.form-file+.form-select,
    .input-group>.form-file+.form-file {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group>.form-control:not(:last-child),
    .input-group>.form-select:not(:last-child) {
        border-top-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 5px;
    }

    .input-group>.form-control:not(:first-child),
    .input-group>.form-select:not(:first-child) {
        border-top-right-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: inherit;
    }

    .input-group>.form-file:not(:last-child) .form-file-label {
        border-top-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: inherit;
    }

    .input-group>.form-file:not(:last-child) .form-file-label::after {
        border-top-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: inherit;
    }

    .input-group>.form-file:not(:first-child) .form-file-label {
        border-top-right-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: inherit;
    }

    .input-group>.form-file:not(:first-child) .form-file-label::after {
        border-top-right-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: inherit;
    }

    .input-group>.input-group-text>.btn,
    .input-group>.input-group-text>.input-group-text {
        border-top-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: inherit;
    }

    .input-group>.input-group-text:not(:last-child)>.btn,
    .input-group>.input-group-text:not(:last-child)>.input-group-text {
        border-top-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: inherit;
    }

    .input-group>.input-group-text:last-child>.btn:not(:last-child):not(.dropdown-toggle),
    .input-group>.input-group-text:last-child>.input-group-text:not(:last-child) {
        border-top-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: inherit;
    }

    .input-group>.input-group-text>.btn,
    .input-group>.input-group-text>.input-group-text {
        border-top-right-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: inherit;
    }

    .input-group>.input-group-text:not(:first-child)>.btn,
    .input-group>.input-group-text:not(:first-child)>.input-group-text {
        border-top-right-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: inherit;
    }

    .input-group>.input-group-text:first-child>.btn:not(:first-child),
    .input-group>.input-group-text:first-child>.input-group-text:not(:first-child) {
        border-top-right-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: inherit;
    }

    .input-group-text .btn+.btn,
    .input-group-text .btn+.input-group-text {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group-text .input-group-text+.input-group-text,
    .input-group-text .input-group-text+.btn {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group-text .btn+.btn,
    .input-group-text .btn+.input-group-text {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group-text .input-group-text+.input-group-text,
    .input-group-text .input-group-text+.btn {
        margin-right: -1px;
        margin-left: inherit;
    }

    .selectgroup-item+.selectgroup-item {
        margin-right: -1px;
        margin-left: inherit;
    }

    .selectgroup-item:not(:first-child) .selectgroup-button {
        border-top-right-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: inherit;
    }

    .selectgroup-item:not(:last-child) .selectgroup-button {
        border-top-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: inherit;
    }

    .selectgroup-input {
        right: 0;
        left: inherit;
    }

    .selectgroup-pills .selectgroup-item {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    .form-switch {
        padding-right: 0;
        padding-left: inherit;
    }

    .form-switch-indicator:before {
        right: 1px;
        left: inherit;
        transition: 0.3s right;
    }

    .form-switch-input:checked~.form-switch-indicator:before {
        right: calc(1rem + 1px);
        left: inherit;
    }

    .form-switch-indicator-lg:before {
        right: 1px;
        left: inherit;
    }

    .form-switch-input:checked~.form-switch-indicator-lg:before {
        right: calc(1.46rem + 1px);
        left: inherit;
    }

    .form-switch-indicator-xl:before {
        right: 1px;
        left: inherit;
    }

    .form-switch-description {
        margin-right: 0.5rem;
        margin-left: inherit;
    }

    .material-switch>label::after {
        right: -4px;
        left: inherit;
    }

    .material-switch>input[type=checkbox]:checked+label::after {
        right: 20px;
        left: inherit;
    }

    .imagecheck-figure:before {
        right: 0.25rem;
        left: inherit;
    }

    .colorinput-color:before {
        right: 0.25rem;
        left: inherit;
    }

    /*-----selectize ------*/

    .selectize-dropdown-header-close {
        left: 8px;
        right: inherit;
    }

    .selectize-dropdown.plugin-optgroup_columns .optgroup {
        border-left: 1px solid $border;
        border-right: inherit;
        float: right;
    }

    .selectize-dropdown.plugin-optgroup_columns .optgroup:last-child {
        border-left: 0 none;
        border-right: inherit;
    }

    .selectize-control.single .selectize-input:after {
        left: 12px;
        right: inherit;
    }

    .selectize-control.plugin-remove_button [data-value] {
        padding-left: 24px !important;
        padding-right: inherit !important;
    }

    .selectize-control.plugin-remove_button [data-value] .remove {
        left: 0;
        right: inherit;
        border-right: 1px solid #d0d0d0;
        border-left: inherit;
        border-radius: 0 0 2px 2px;
    }

    .selectize-control.plugin-remove_button [data-value].active .remove {
        border-right-color: #cacaca;
        border-left-color: inherit;
    }

    .selectize-control.plugin-remove_button .disabled [data-value] .remove {
        border-right-color: $white;
        border-left-color: inherit;
    }

    .selectize-control.plugin-remove_button .remove-single {
        left: 28px;
        right: inherit;
    }

    .selectize-control.multi .selectize-input.has-items {
        padding: 7px 7px 4px 0.75rem;
    }

    .selectize-input>input {
        margin: 0 0 0 2px !important;
    }

    .selectize-control.multi .selectize-input>div {
        margin: 0 0 3px 3px;
    }

    .selectize-dropdown .image,
    .selectize-input .image {
        margin: -1px -4px -1px 0.5rem;
        float: right;
    }

    .selectize-input .image {
        margin: -3px -5px -3px 0.75rem;
    }

    /* ###### layouts ####### */

    /*------ Alignments -------*/

    .border-end {
        border-left: 1px solid $border  !important;
        border-right: inherit !important;
    }

    .border-start {
        border-right: 1px solid $border  !important;
        border-left: inherit !important;
    }

    .border-end-0 {
        border-left: 0 !important;
        border-right: 1px solid $border  !important;
    }

    .border-start-0 {
        border-right: 0 !important;
        border-left: 1px solid $border  !important;
    }

    .rounded-right {
        border-top-left-radius: 3px !important;
        border-top-right-radius: inherit !important;
        border-bottom-left-radius: 3px !important;
        border-bottom-right-radius: inherit !important;
    }

    .rounded-left {
        border-top-right-radius: 3px !important;
        border-top-left-radius: inherit !important;
        border-bottom-right-radius: 3px !important;
        border-bottom-left-radius: inherit !important;
    }

    .embed-responsive .embed-responsive-item,
    .embed-responsive iframe,
    .embed-responsive embed,
    .embed-responsive object,
    .embed-responsive video {
        right: 0;
        left: inherit;
    }

    .float-start {
        float: right !important;
    }

    .float-end {
        float: left !important;
    }

    @media (min-width: 576px) {
        .float-sm-start {
            float: right !important;
        }

        .float-sm-end {
            float: left !important;
        }
    }

    @media (min-width: 768px) {
        .float-md-start {
            float: right !important;
        }

        .float-md-end {
            float: left !important;
        }
    }

    @media (min-width: 992px) {
        .float-lg-start {
            float: right !important;
        }

        .float-lg-end {
            float: left !important;
        }
    }

    @media (min-width: 1280px) {
        .float-xl-start {
            float: right !important;
        }

        .float-xl-end {
            float: left !important;
        }
    }

    .me-0 {
        margin-left: 0 !important;
        margin-right: inherit !important;
    }

    .ms-0 {
        margin-right: 0 !important;
        margin-left: inherit !important;
    }

    .me-1 {
        margin-left: 0.25rem !important;
        margin-right: inherit !important;
    }

    .ms-1 {
        margin-right: 0.25rem !important;
        margin-left: inherit !important;
    }

    .me-2 {
        margin-left: 0.5rem !important;
        margin-right: initial !important;
    }

    .ms-2 {
        margin-right: 0.5rem !important;
        margin-left: inherit !important;
    }

    .me-3 {
        margin-left: 0.75rem !important;
        margin-right: inherit !important;
    }

    .ms-3 {
        margin-right: 0.75rem !important;
        margin-left: inherit !important;
    }

    .me-4 {
        margin-left: 1rem !important;
        margin-right: inherit !important;
    }

    .ms-4 {
        margin-right: 1rem !important;
        margin-left: initial !important;
    }

    .me-5 {
        margin-left: 1.5rem !important;
        margin-right: inherit !important;
    }

    .ms-5 {
        margin-right: 1.5rem !important;
        margin-left: inherit !important;
    }

    .me-6 {
        margin-left: 2rem !important;
        margin-right: inherit !important;
    }

    .ms-6 {
        margin-right: 2rem !important;
        margin-left: inherit !important;
    }

    .me-7 {
        margin-left: 3rem !important;
        margin-right: inherit !important;
    }

    .ms-7 {
        margin-right: 3rem !important;
        margin-left: inherit !important;
    }

    .me-8 {
        margin-left: 4rem !important;
        margin-right: inherit !important;
    }

    .ms-8 {
        margin-right: 4rem !important;
        margin-left: inherit !important;
    }

    .me-9 {
        margin-left: 6rem !important;
        margin-right: inherit !important;
    }

    .ms-9 {
        margin-right: 6rem !important;
        margin-left: inherit !important;
    }

    // padding                                                               

    .pe-0 {
        padding-left: 0 !important;
        padding-right: inherit !important;
    }

    .ps-0 {
        padding-right: 0 !important;
        padding-left: inherit !important;
    }

    .pe-1 {
        padding-left: 0.25rem !important;
        padding-right: inherit !important;
    }

    .ps-1 {
        padding-right: 0.25rem !important;
        padding-left: inherit !important;
    }

    .pe-2 {
        padding-left: 0.5rem !important;
        padding-right: initial !important;
    }

    .ps-2 {
        padding-right: 0.5rem !important;
        padding-left: inherit !important;
    }

    .pe-3 {
        padding-left: 0.75rem !important;
        padding-right: inherit !important;
    }

    .ps-3 {
        padding-right: 0.75rem !important;
        padding-left: inherit !important;
    }

    .pe-4 {
        padding-left: 1rem !important;
        padding-right: inherit !important;
    }

    .ps-4 {
        padding-right: 1rem !important;
        padding-left: inherit !important;
    }

    .pe-5 {
        padding-left: 1.5rem !important;
        padding-right: inherit !important;
    }

    .ps-5 {
        padding-right: 1.5rem !important;
        padding-left: inherit !important;
    }

    .pe-6 {
        padding-left: 2rem !important;
        padding-right: inherit !important;
    }

    .ps-6 {
        padding-right: 2rem !important;
        padding-left: inherit !important;
    }

    .pe-7 {
        padding-left: 3rem !important;
        padding-right: inherit !important;
    }

    .ps-7 {
        padding-right: 3rem !important;
        padding-left: inherit !important;
    }

    .pe-8 {
        padding-left: 4rem !important;
        padding-right: inherit !important;
    }

    .ps-8 {
        padding-right: 4rem !important;
        padding-left: inherit !important;
    }

    .pe-9 {
        padding-left: 6rem !important;
        padding-right: inherit !important;
    }

    .ps-9 {
        padding-right: 6rem !important;
        padding-left: inherit !important;
    }

    //

    .me-auto {
        margin-left: auto !important;
        margin-right: inherit !important;
    }

    .ms-auto {
        margin-right: auto !important;
        margin-left: inherit !important;
    }

    @media (min-width: 576px) {
        .me-sm-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }

        .ms-sm-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        .me-sm-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }

        .me-sm-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }

        .me-sm-3 {
            margin-left: 0.75rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-3 {
            margin-right: 0.75rem !important;
            margin-left: inherit !important;
        }

        .me-sm-4 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-4 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }

        .me-sm-5 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-5 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }

        .me-sm-6 {
            margin-left: 2rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-6 {
            margin-right: 2rem !important;
            margin-left: inherit !important;
        }

        .me-sm-7 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-7 {
            margin-right: 3rem !important;
            margin-left: inherit !important;
        }

        .me-sm-8 {
            margin-left: 4rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-8 {
            margin-right: 4rem !important;
            margin-left: inherit !important;
        }

        .me-sm-9 {
            margin-left: 6rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-9 {
            margin-right: 6rem !important;
            margin-left: inherit !important;
        }


        .pe-sm-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }

        .ps-sm-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .pe-sm-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-3 {
            padding-left: 0.75rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-3 {
            padding-right: 0.75rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-4 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-4 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-5 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-5 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-6 {
            padding-left: 2rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-6 {
            padding-right: 2rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-7 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-7 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-8 {
            padding-left: 4rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-8 {
            padding-right: 4rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-9 {
            padding-left: 6rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-9 {
            padding-right: 6rem !important;
            padding-left: inherit !important;
        }


        .me-sm-auto {
            margin-left: auto !important;
            margin-right: inherit !important;
        }

        .ms-sm-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }
    }

    @media (min-width: 768px) {
        .me-md-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }

        .ms-md-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        .me-md-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-md-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }

        .me-md-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-md-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }

        .me-md-3 {
            margin-left: 0.75rem !important;
            margin-right: inherit !important;
        }

        .ms-md-3 {
            margin-right: 0.75rem !important;
            margin-left: inherit !important;
        }

        .me-md-4 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }

        .ms-md-4 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }

        .me-md-5 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-md-5 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }

        .me-md-6 {
            margin-left: 2rem !important;
            margin-right: inherit !important;
        }

        .ms-md-6 {
            margin-right: 2rem !important;
            margin-left: inherit !important;
        }

        .me-md-7 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }

        .ms-md-7 {
            margin-right: 3rem !important;
            margin-left: inherit !important;
        }

        .me-md-8 {
            margin-left: 4rem !important;
            margin-right: inherit !important;
        }

        .ms-md-8 {
            margin-right: 4rem !important;
            margin-left: inherit !important;
        }

        .me-md-9 {
            margin-left: 6rem !important;
            margin-right: inherit !important;
        }

        .ms-md-9 {
            margin-right: 6rem !important;
            margin-left: inherit !important;
        }


        .pe-md-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }

        .ps-md-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .pe-md-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }

        .ps-md-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }

        .pe-md-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }

        .ps-md-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .pe-md-3 {
            padding-left: 0.75rem !important;
            padding-right: inherit !important;
        }

        .ps-md-3 {
            padding-right: 0.75rem !important;
            padding-left: inherit !important;
        }

        .pe-md-4 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }

        .ps-md-4 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }

        .pe-md-5 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }

        .ps-md-5 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }

        .pe-md-6 {
            padding-left: 2rem !important;
            padding-right: inherit !important;
        }

        .ps-md-6 {
            padding-right: 2rem !important;
            padding-left: inherit !important;
        }

        .pe-md-7 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }

        .ps-md-7 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }

        .pe-md-8 {
            padding-left: 4rem !important;
            padding-right: inherit !important;
        }

        .ps-md-8 {
            padding-right: 4rem !important;
            padding-left: inherit !important;
        }

        .pe-md-9 {
            padding-left: 6rem !important;
            padding-right: inherit !important;
        }

        .ps-md-9 {
            padding-right: 6rem !important;
            padding-left: inherit !important;
        }


        .me-md-auto {
            margin-left: auto !important;
            margin-right: inherit !important;
        }

        .ms-md-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }
    }

    @media (min-width: 992px) {
        .me-lg-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }

        .ms-lg-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        .me-lg-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }

        .me-lg-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }

        .me-lg-3 {
            margin-left: 0.75rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-3 {
            margin-right: 0.75rem !important;
            margin-left: inherit !important;
        }

        .me-lg-4 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-4 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }

        .me-lg-5 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-5 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }

        .me-lg-6 {
            margin-left: 2rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-6 {
            margin-right: 2rem !important;
            margin-left: inherit !important;
        }

        .me-lg-7 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-7 {
            margin-right: 3rem !important;
            margin-left: inherit !important;
        }

        .me-lg-8 {
            margin-left: 4rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-8 {
            margin-right: 4rem !important;
            margin-left: inherit !important;
        }

        .me-lg-9 {
            margin-left: 6rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-9 {
            margin-right: 6rem !important;
            margin-left: inherit !important;
        }


        .pe-lg-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }

        .ps-lg-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .pe-lg-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-3 {
            padding-left: 0.75rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-3 {
            padding-right: 0.75rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-4 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-4 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-5 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-5 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-6 {
            padding-left: 2rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-6 {
            padding-right: 2rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-7 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-7 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-8 {
            padding-left: 4rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-8 {
            padding-right: 4rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-9 {
            padding-left: 6rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-9 {
            padding-right: 6rem !important;
            padding-left: inherit !important;
        }


        .me-lg-auto {
            margin-left: auto !important;
            margin-right: inherit !important;
        }

        .ms-lg-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }
    }

    @media (min-width: 1280px) {
        .me-xl-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }

        .ms-xl-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        .me-xl-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }

        .me-xl-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }

        .me-xl-3 {
            margin-left: 0.75rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-3 {
            margin-right: 0.75rem !important;
            margin-left: inherit !important;
        }

        .me-xl-4 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-4 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }

        .me-xl-5 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-5 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }

        .me-xl-6 {
            margin-left: 2rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-6 {
            margin-right: 2rem !important;
            margin-left: inherit !important;
        }

        .me-xl-7 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-7 {
            margin-right: 3rem !important;
            margin-left: inherit !important;
        }

        .me-xl-8 {
            margin-left: 4rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-8 {
            margin-right: 4rem !important;
            margin-left: inherit !important;
        }

        .me-xl-9 {
            margin-left: 6rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-9 {
            margin-right: 6rem !important;
            margin-left: inherit !important;
        }


        .pe-xl-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }

        .ps-xl-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .pe-xl-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-3 {
            padding-left: 0.75rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-3 {
            padding-right: 0.75rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-4 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-4 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-5 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-5 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-6 {
            padding-left: 2rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-6 {
            padding-right: 2rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-7 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-7 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-8 {
            padding-left: 4rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-8 {
            padding-right: 4rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-9 {
            padding-left: 6rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-9 {
            padding-right: 6rem !important;
            padding-left: inherit !important;
        }


        .me-xl-auto {
            margin-left: auto !important;
            margin-right: inherit !important;
        }

        .ms-xl-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }
    }

    /*------- Alignments & values-------*/

    .text-start {
        text-align: right !important;
    }

    .text-end {
        text-align: left !important;
    }

    @media (min-width: 576px) {
        .text-sm-start {
            text-align: right !important;
        }

        .text-sm-end {
            text-align: left !important;
        }
    }

    @media (min-width: 768px) {
        .text-md-start {
            text-align: right !important;
        }

        .text-md-end {
            text-align: left !important;
        }
    }

    @media (min-width: 992px) {
        .text-lg-start {
            text-align: right !important;
        }

        .text-lg-end {
            text-align: left !important;
        }
    }

    @media (min-width: 1280px) {
        .text-xl-start {
            text-align: right !important;
        }

        .text-xl-end {
            text-align: left !important;
        }
    }

    blockquote {
        padding-right: 2rem;
        padding-left: initial;
        border-right: 2px solid rgba(0, 40, 100, 0.12);
        border-left: inherit;
    }

    blockquote cite {
        text-align: left;
    }

    pre {
        border-right: 3px solid #{$primary};
        border-left: inherit;
    }

    @media (min-width: 1600px) {
        &.aside-opened .page {
            margin-left: 22rem;
            margin-right: inherit;
        }
    }

    .offset-1 {
        margin-right: 8.33333333%;
        margin-left: inherit;
    }

    .offset-2 {
        margin-right: 16.66666667%;
        margin-left: inherit;
    }

    .offset-3 {
        margin-right: 25%;
        margin-left: inherit;
    }

    .offset-4 {
        margin-right: 33.33333333%;
        margin-left: inherit;
    }

    .offset-5 {
        margin-right: 41.66666667%;
        margin-left: inherit;
    }

    .offset-6 {
        margin-right: 50%;
        margin-left: inherit;
    }

    .offset-7 {
        margin-right: 58.33333333%;
        margin-left: inherit;
    }

    .offset-8 {
        margin-right: 66.66666667%;
        margin-left: inherit;
    }

    .offset-9 {
        margin-right: 75%;
        margin-left: inherit;
    }

    .offset-10 {
        margin-right: 83.33333333%;
        margin-left: inherit;
    }

    .offset-11 {
        margin-right: 91.66666667%;
        margin-left: inherit;
    }

    @media (min-width: 576px) {
        .offset-sm-0 {
            margin-right: 0;
            margin-left: inherit;
        }

        .offset-sm-1 {
            margin-right: 8.33333333%;
            margin-left: inherit;
        }

        .offset-sm-2 {
            margin-right: 16.66666667%;
            margin-left: inherit;
        }

        .offset-sm-3 {
            margin-right: 25%;
            margin-left: inherit;
        }

        .offset-sm-4 {
            margin-right: 33.33333333%;
            margin-left: inherit;
        }

        .offset-sm-5 {
            margin-right: 41.66666667%;
            margin-left: inherit;
        }

        .offset-sm-6 {
            margin-right: 50%;
            margin-left: inherit;
        }

        .offset-sm-7 {
            margin-right: 58.33333333%;
            margin-left: inherit;
        }

        .offset-sm-8 {
            margin-right: 66.66666667%;
            margin-left: inherit;
        }

        .offset-sm-9 {
            margin-right: 75%;
            margin-left: inherit;
        }

        .offset-sm-10 {
            margin-right: 83.33333333%;
            margin-left: inherit;
        }

        .offset-sm-11 {
            margin-right: 91.66666667%;
            margin-left: inherit;
        }
    }

    @media (min-width: 768px) {

        .offset-md-0 {
            margin-right: 0;
            margin-left: inherit;
        }

        .offset-md-1 {
            margin-right: 8.33333333%;
            margin-left: inherit;
        }

        .offset-md-2 {
            margin-right: 16.66666667%;
            margin-left: inherit;
        }

        .offset-md-3 {
            margin-right: 25%;
            margin-left: inherit;
        }

        .offset-md-4 {
            margin-right: 33.33333333%;
            margin-left: inherit;
        }

        .offset-md-5 {
            margin-right: 41.66666667%;
            margin-left: inherit;
        }

        .offset-md-6 {
            margin-right: 50%;
            margin-left: inherit;
        }

        .offset-md-7 {
            margin-right: 58.33333333%;
            margin-left: inherit;
        }

        .offset-md-8 {
            margin-right: 66.66666667%;
            margin-left: inherit;
        }

        .offset-md-9 {
            margin-right: 75%;
            margin-left: inherit;
        }

        .offset-md-10 {
            margin-right: 83.33333333%;
            margin-left: inherit;
        }

        .offset-md-11 {
            margin-right: 91.66666667%;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {

        .offset-lg-0 {
            margin-right: 0;
            margin-left: inherit;
        }

        .offset-lg-1 {
            margin-right: 8.33333333%;
            margin-left: inherit;
        }

        .offset-lg-2 {
            margin-right: 16.66666667%;
            margin-left: inherit;
        }

        .offset-lg-3 {
            margin-right: 25%;
            margin-left: inherit;
        }

        .offset-lg-4 {
            margin-right: 33.33333333%;
            margin-left: inherit;
        }

        .offset-lg-5 {
            margin-right: 41.66666667%;
            margin-left: inherit;
        }

        .offset-lg-6 {
            margin-right: 50%;
            margin-left: inherit;
        }

        .offset-lg-7 {
            margin-right: 58.33333333%;
            margin-left: inherit;
        }

        .offset-lg-8 {
            margin-right: 66.66666667%;
            margin-left: inherit;
        }

        .offset-lg-9 {
            margin-right: 75%;
            margin-left: inherit;
        }

        .offset-lg-10 {
            margin-right: 83.33333333%;
            margin-left: inherit;
        }

        .offset-lg-11 {
            margin-right: 91.66666667%;
            margin-left: inherit;
        }
    }

    @media (min-width: 1280px) {

        .offset-xl-0 {
            margin-right: 0;
            margin-left: inherit;
        }

        .offset-xl-1 {
            margin-right: 8.33333333%;
            margin-left: inherit;
        }

        .offset-xl-2 {
            margin-right: 16.66666667%;
            margin-left: inherit;
        }

        .offset-xl-3 {
            margin-right: 25%;
            margin-left: inherit;
        }

        .offset-xl-4 {
            margin-right: 33.33333333%;
            margin-left: inherit;
        }

        .offset-xl-5 {
            margin-right: 41.66666667%;
            margin-left: inherit;
        }

        .offset-xl-6 {
            margin-right: 50%;
            margin-left: inherit;
        }

        .offset-xl-7 {
            margin-right: 58.33333333%;
            margin-left: inherit;
        }

        .offset-xl-8 {
            margin-right: 66.66666667%;
            margin-left: inherit;
        }

        .offset-xl-9 {
            margin-right: 75%;
            margin-left: inherit;
        }

        .offset-xl-10 {
            margin-right: 83.33333333%;
            margin-left: inherit;
        }

        .offset-xl-11 {
            margin-right: 91.66666667%;
            margin-left: inherit;
        }
    }

    .app-sidebar .mCSB_draggerContainer {
        left: -11px !important;
        right: inherit !important;
    }

    @media (min-width: 992px) {
        &.app.sidenav-toggled .app-content {
            margin-right: 70px;
            margin-left: inherit;
        }

        &.app.sidenav-toggled .app-sidebar {
            right: 0;
            left: inherit;
        }
    }

    @media (max-width: 991px) {

        &.app .app-sidebar {
            right: -250px;
            left: inherit;
        }

        &.app.sidenav-toggled .app-content {
            margin-right: 0;
            margin-left: inherit;
        }

        &.app.sidenav-toggled .app-sidebar {
            right: 0;
            left: inherit;
        }

        &.app.sidebar-gone.sidenav-toggled .app-sidebar {
            right: 0;
            left: inherit;
        }

        &.app.sidenav-toggled .side-menu .side-menu__icon {
            margin-left: 13px !important;
            margin-right: 0 !important;
        }
    }

    .app-content {
        -webkit-transition: margin-right 0.2s ease;
        -o-transition: margin-right 0.2s ease;
        transition: margin-right 0.2s ease;
    }

    /*----- Componant: Top Navigation Bar ----- */

    .app-search {
        margin-left: 15px;
        margin-right: inherit;
    }

    .app-search__input {
        padding-left: 30px;
        padding-right: inherit;
    }

    .app-search__button {
        left: 0;
        right: inherit;
    }

    .app-notification__icon {
        padding-left: 10px;
        padding-right: inherit;
    }

    .app-sidebar {
        right: 0;
        left: inherit;
        border-left: 1px solid $border;
        border-right: inherit;
        transition: right 0.3s ease, width 0.3s ease;
    }

    .app-sidebar__user-avatar {
        margin-left: 15px;
        margin-right: inherit;
    }

    .side-menu .slide .side-menu__item {
        padding: 10px 22px 10px 20px;
    }

    .slide .angle {
        transform: rotate(180deg);
    }

    .slide.is-expanded .angle {
        transform: rotate(90deg);
    }

    .slide .sub-angle {
        transform: rotate(180deg);
    }

    .slide.is-expanded .sub-slide.is-expanded .sub-angle {
        transform: rotate(90deg);
    }

    @media (min-width: 992px) {
        &.app.sidebar-mini.sidenav-toggled .profile-status {
            left: 22px;
            right: inherit;
        }

        &.app.sidenav-toggled .side-menu__label {
            right: 0;
            left: inherit;
        }
    }

    .sub-slide .sub-angle {
        margin-right: auto;
        margin-left: inherit;
        float: left;
    }

    .slide.is-expanded .sub-side-menu__item:before {
        margin-left: 8px;
        margin-right: inherit;
    }

    .side-menu .side-menu__icon {
        margin-left: 8px;
        margin-right: -8px;
    }

    .app-sidebar .side-item.side-item-category {
        padding: 0 25px 0 20px;
    }

    .slide-item {
        padding: 8px 47px 8px 14px;
    }

    .slide-item .icon {
        margin-left: 5px;
        margin-right: inherit;
    }

    @media (min-width: 992px) {

        &.sidenav-toggled .profile-img {
            left: 19px;
            right: inherit;
        }

        &.sidenav-toggled .app-content {
            margin-right: 0;
            margin-left: inherit;
        }

        &.sidenav-toggled .side-menu__label {
            right: 50px;
            left: inherit;
            // padding: 12px 20px 12px 5px;
            margin-right: -3px;
            margin-left: inherit;
            border-top-left-radius: 4px;
            border-top-right-radius: inherit;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: inherit;
        }

        &.sidenav-toggled .slide .side-menu__label {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: inherit;
        }

        &.sidenav-toggled .slide-menu {
            right: 90px;
            left: inherit;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: inherit;
        }

        &.app.sidenav-toggled .side-menu__label {
            right: 0;
            left: inherit;
        }

        &.app.sidenav-toggled .nav-badge {
            left: 28px;
            right: inherit;
        }
    }

    .dropdown-menu.dropdown-menu-right {
        right: auto;
        left: inherit;
    }

    .app-breadcrumb {
        text-align: right;
    }

    &.sidenav-toggled .main-wrapper .side-menu .side-menu__item .nav-badge {
        right: 57px;
        left: inherit;
    }

    .app-header .header-brand-img.desktop-logo {
        margin-left: 0;
        margin-right: inherit;
    }

    &.sidenav-toggled .app-sidebar .app-header .header-brand-img.toggle-logo {
        margin-left: 0;
        margin-right: inherit;
    }

    .app-header {
        -webkit-transition: right 0.3s ease;
        -o-transition: right 0.3s ease;
        transition: right 0.3s ease;
    }

    .side-menu h3:after {
        right: 21px;
        left: inherit;
    }

    /*-- Sub-slide--**/

    .sub-slide-item {
        padding: 8px 65px 8px 0 !important;
    }

    .sub-slide-menu {
        padding-right: 0;
        padding-left: inherit;
    }

    .sub-side-menu__item {
        padding-right: 48px !important;
        padding-left: 20px !important;
    }

    .app-sidebar .slide-menu a:before {
        margin-left: 8px;
        margin-right: inherit;
        content: "\e046";
    }

    .sub-slide-menu1 .sub-slide-item2:before {
        right: 35px !important;
        left: inherit !important;
    }

    &.sidenav-toggled .app-content {
        transition: margin-right 0.3s ease;
    }

    @media (min-width: 992px) {
        &.app.sidebar-mini.sidenav-toggled-open .app-sidebar {
            right: 0;
            left: inherit;
        }

        &.app.sidebar-mini.sidenav-toggled-open .side-menu .slide .side-menu__item {
            padding: 10px 22px 10px 20px !important;
            text-align: right;
        }

        &.app.sidebar-mini.sidenav-toggled-open .side-menu .side-menu__icon {
            margin-left: 12px;
            margin-right: inherit;
        }

        &.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .profile-status {
            left: 97px;
            right: inherit;
        }

        &.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
            right: 0;
            left: inherit;
        }
    }

    @media only screen and (max-width: 991px) {
        &.app .desktop-logo {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        &.main-body.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-header form[role=search].active input {
            padding-right: 250px;
            padding-left: inherit;
        }

        &.main-body.app.sidebar-mini.sidenav-toggled .app-header form[role=search].active input {
            padding-right: 90px;
            padding-left: inherit;
        }

        &.main-body.app.sidebar-mini.sidebar-gone .app-header form[role=search].active input {
            padding-right: 20px;
            padding-left: inherit;
        }

        &.main-body.app.sidebar-mini .app-header form[role=search].active input {
            padding-right: 250px;
            padding-left: inherit;
        }
    }

    .sub-slide-menu-sub .sub-slide-item {
        padding-right: 55px !important;
        padding-left: inherit !important;
    }

    .app-sidebar .slide-menu .sub-slide-menu .sub-slide-menu-sub a:before {
        right: 35px;
        left: inherit;
    }

    .sub-slide-menu1 .sub-slide-item2 {
        padding: 6px 48px 6px 0 !important;
    }

    .sub-slide-menu1 {
        padding-right: 0;
        padding-left: inherit;
    }

    @media (min-width: 992px) {
        &.closed-menu.app.sidenav-toggled .app-content {
            margin-right: 0px !important;
            margin-left: inherit !important;
        }

        &.closed-menu.app.sidenav-toggled .app-header {
            padding-right: 0px !important;
            padding-left: inherit !important;
        }
    }

    @media (min-width: 992px) {
        &.icontext-menu.app.sidenav-toggled .app-header {
            padding-right: 90px !important;
            padding-left: inherit !important;
        }

        &.icontext-menu.app.sidenav-toggled .app-content {
            margin-right: 90px;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        &.hover-submenu.sidenav-toggled-open .slide-menu {
            border-radius: 7px 0px 0 7px !important;
        }

        &.hover-submenu.sidenav-toggled-open .sub-slide-item {
            padding: 8px 30px 8px 0 !important;
        }

        &.hover-submenu .slide-menu {
            border-radius: 7px 0px 0 7px;
        }

        &.hover-submenu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
            right: 90px;
            left: inherit;
        }

        &.hover-submenu.app.sidenav-toggled-open .slide-menu {
            right: 90px;
            left: inherit;
        }

        &.hover-submenu.app.sidenav-toggled-open .side-menu__label {
            right: 0;
            left: inherit;
        }

        &.hover-submenu.app.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
            border-left: 0 !important;
            border-right: inherit !important;
        }

        &.hover-submenu.app.sidenav-toggled .app-header {
            padding-right: 90px;
            padding-left: inherit;
        }

        &.hover-submenu.app.sidenav-toggled .app-content {
            margin-right: 90px;
            margin-left: inherit;
        }

        &.hover-submenu.app.sidenav-toggled .app-sidebar {
            right: 0;
            left: inherit;
        }
    }

    &.hover-submenu .sub-slide-menu .sub-side-menu__item:before {
        right: 25px !important;
        left: inherit !important;
    }

    @media (min-width: 992px) {
        &.hover-submenu.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .profile-status {
            left: 22px;
            right: inherit;
        }
    }

    @media (min-width: 992px) {

        &.hover-submenu1.sidenav-toggled-open .sub-slide-item {
            padding: 8px 30px 8px 0 !important;
        }

        &.hover-submenu1 .slide-menu {
            border-radius: 7px 0px 0 7px !important;
        }

        &.hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
            right: 90px;
            left: inherit;
        }

        &.hover-submenu1.app.sidenav-toggled-open .slide-menu {
            right: 90px;
            left: inherit;
        }

        &.hover-submenu1.app.sidenav-toggled-open .side-menu__label {
            right: 0;
            left: inherit;
        }

        &.hover-submenu1.app.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
            border-left: 0 !important;
            border-right: inherit !important;
        }

        &.hover-submenu1.app.sidenav-toggled .app-header {
            padding-right: 90px;
            padding-left: inherit;
        }

        &.hover-submenu1.app.sidenav-toggled .app-content {
            margin-right: 90px;
            margin-left: inherit;
        }

        &.hover-submenu1.app.sidenav-toggled .app-sidebar {
            right: 0;
            left: inherit;
        }
    }

    &.hover-submenu1 .sub-slide-menu .sub-side-menu__item:before {
        right: 25px !important;
        left: inherit !important;
    }

    @media (min-width: 992px) {
        &.hover-submenu1.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .profile-status {
            left: 22px;
            right: inherit;
        }
    }

    .main-sidebar-header {
        border-left: 1px solid $border;
        border-right: inherit;
        transition: right 0.3s ease, width 0.3s ease;
    }

    @media (min-width: 992px) {
        .main-content {
            margin-right: 250px;
            margin-left: inherit;
        }
    }

    .app-sidebar-help {
        border-left: 1px solid $border;
        border-right: inherit;
    }

    .app-sidebar .dropdown-menu-arrow.dropdown-menu-end::before,
    .app-sidebar .dropdown-menu-arrow.dropdown-menu-end::after {
        left: auto;
        right: 12px;
    }

    @media (min-width: 992px) {

        &.app.sidenav-toggled .app-header {
            padding-right: 70px;
            padding-left: inherit;
        }
    }

    /*------ App Header -------*/

    .app-header {
        -webkit-transition: padding-right 0.2s ease;
        -o-transition: padding-right 0.2s ease;
        transition: padding-right 0.2s ease;
    }

    .app-header .header-brand .header-brand-img {
        margin-left: 0;
        margin-right: inherit;
    }

    .header-brand-img {
        margin-left: 0;
        margin-right: inherit;
    }

    .nav-link.icon .nav-span {
        margin-right: 0.5rem;
        margin-left: inherit;
    }

    .header .form-inline .form-control {
        padding-right: 20px;
        padding-left: 40px;
    }

    .header .form-inline .btn {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: inherit;
        border-top-left-radius: 3px;
        border-top-right-radius: inherit;
        margin-right: -56px;
        margin-left: inherit;
        left: 0;
        right: auto;
    }

    @media (max-width: 991px) and (min-width: 576px) {

        .search-element .form-control {
            float: right;
        }

        .animated-arrow.hor-toggle span {
            margin-right: 8px;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .header.top-header {
            padding-right: 250px;
            padding-left: inherit;
        }
    }

    @media (max-width: 575.98px) {
        .header .form-inline .search-element .form-control {
            float: right;
        }

        .animated-arrow.hor-toggle span {
            margin-right: 8px;
            margin-left: inherit;
        }
    }


    @media (min-width: 992px) {

        &.horizontal .hor-header .header-brand {
            margin: 0 0 0 1rem;
        }

        &.horizontal .sub-side-menu__item {
            padding: 8px 25px 8px 35px !important;
        }

        &.horizontal .slide.is-expanded .sub-slide-item2:before {
            right: 20px !important;
            left: inherit !important;
        }

        &.horizontal .sub-slide-menu .sub-slide-item2 {
            padding: 8px 35px 8px 20px !important;
        }

        &.horizontal .sub-slide .sub-angle {
            left: -20px;
            right: auto;
            margin-left: 0 !important;
            margin-right: inherit !important;
        }

        &.horizontal .sub-slide .sub-slide-menu {
            right: 180px;
            left: inherit;
        }

        &.horizontal .main-content {
            margin-right: 0px !important;
            margin-left: inherit !important;
        }

        &.horizontal .main-sidemenu .slide-right {
            left: 0;
            right: inherit;
        }

        &.horizontal .main-sidemenu .slide-left {
            right: 0;
            left: inherit;
        }

        &.horizontal .hor-header {
            padding-right: 0;
            padding-left: inherit;
        }
    }

    @media (max-width: 991.98px) {
        &.horizontal .main-content {
            -webkit-transition: margin-right 0.2s ease;
            -o-transition: margin-right 0.2s ease;
            transition: margin-right 0.2s ease;
        }
    }

    @media (min-width: 992px) {
        &.horizontal-hover.horizontal .app-sidebar .slide-menu .sub-slide-menu1 a:before {
            right: 20px !important;
            left: inherit !important;
        }

        &.horizontal-hover.horizontal .sub-slide-menu .sub-side-menu__item:before {
            right: 20px !important;
            left: inherit !important;
        }
    }

    /***********Boxed Styles****************/
    @media (min-width: 1400px) {

        &.layout-boxed .app-sidebar {
            right: auto !important;
            left: inherit !important;
        }

        &.layout-boxed .main-content-left-chat .main-nav-line-chat .nav-link+.nav-link {
            margin-right: 0;
            margin-left: inherit;
        }
    }



    .demo-gallery>ul>li {
        float: right;
    }

    .demo-gallery>ul>li a {
        float: right;
    }

    .demo-gallery>ul>li a .demo-gallery-poster>img {
        right: 50%;
        left: inherit;
        margin-right: -10px;
        margin-left: inherit;
    }

    .demo-gallery .justified-gallery>a .demo-gallery-poster>img {
        right: 50%;
        left: inherit;
        margin-right: -10px;
        margin-left: inherit;
    }

    .demo-gallery .video .demo-gallery-poster img {
        margin-right: -24px;
        margin-left: inherit;
    }

    .gallery-list>li {
        float: right;
    }

    .col-sm-4 .pricing-table .table td {
        text-align: right;
    }

    /*------ Pricing Styles ---------*/

    @media (min-width: 992px) {
        // .plan-card.active {
        //     margin-right: -20px;
        //     margin-left: inherit;
        // }

        // .pricing-sctive {
        //     margin-right: -20px;
        //     margin-left: inherit;
        // }

        .panel.price.active {
            margin-right: -20px;
            margin-left: inherit;
            border-left-color: 1px !important;
            border-right: inherit !important;
        }

        .no-gutters .panel.price {
            border-left: 0;
            border-right: inherit;
        }

        .no-gutters .panel.price:last-child {
            border-left: 1px;
            border-right: inherit;
        }
    }

    .pricing-tabs .pri-tabs-heading3 ul.nav-price li:first-child a {
        border-radius: 0 50px 50px 0;
        border-right: 0;
        border-left: inherit;
    }

    .pricing-tabs .pri-tabs-heading3 ul.nav-price li:last-child a {
        border-radius: 50px 0 0 50px;
    }

    .pricing-tabs .pri-tabs-heading3 ul.nav-price li a {
        border-right: 1px solid $border;
        border-left: inherit;
    }

    /*---profile---*/

    .profile-cover .cover .inner-cover .cover-menu-mobile {
        left: 10px;
        right: inherit;
    }

    .profile-cover .cover ul.cover-menu {
        padding-right: 150px;
        padding-left: inherit;
        right: 1px;
        left: inherit;
        float: right;
    }

    .profile-cover .cover ul.cover-menu li {
        float: right;
        margin-left: 0px;
        margin-right: inherit;
    }

    .tags .tag-files span {
        margin-left: 104px;
        margin-right: inherit;
        right: 51px;
        left: inherit;
    }

    .image-pic {
        padding: 20px 20px 20px 0px;
    }

    .file-image-1 .icons {
        left: 7px;
        right: inherit;
        text-align: left;
    }

    #checkoutsteps .item .purchase {
        margin-right: 21px;
        margin-left: inherit;
    }

    #checkoutsteps .total {
        float: left;
    }

    @media (max-width: 400px) {
        #checkoutsteps .item .purchase {
            margin-right: 0;
            margin-left: inherit;
        }

        #wizard3 {
            &.wizard.vertical>.steps ul li+li {
                margin-right: 0;
                margin-left: inherit;
            }
        }
    }

}


//plugins 

.rtl {
    .accordionjs .acc_section .acc_head h3 {
        padding-right: 30px;
        padding-left: inherit;
    }

    .accordionjs .acc_section.acc_active .acc_head h3:before {
        right: 17px;
        left: inherit;
    }

    .accordionjs .acc_section .acc_head h3:before {
        right: 17px;
        left: inherit;
    }

    .daterangepicker {
        right: 20px;
        left: inherit;
    }

    .daterangepicker.opensleft:before {
        left: 9px;
        right: inherit;
    }

    .daterangepicker.opensleft:after {
        left: 10px;
        right: inherit;
    }

    .daterangepicker.opensright:before {
        right: 9px;
        left: inherit;
    }

    .daterangepicker.opensright:after {
        right: 10px;
        left: inherit;
    }

    .daterangepicker .drp-calendar.left {
        padding: 8px 8px 8px 0;
    }

    .daterangepicker .calendar-table .next span,
    .daterangepicker .calendar-table .prev span {
        border-width: 0 0 2px 2px;
    }

    .daterangepicker .calendar-table .next span {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }

    .daterangepicker .calendar-table .prev span {
        transform: rotate(225deg);
        -webkit-transform: rotate(225deg);
    }

    .daterangepicker td.start-date {
        border-radius: 0 4px 4px 0;
    }

    .daterangepicker td.end-date {
        border-radius: 4px 0 0 4px;
    }

    .daterangepicker select.monthselect {
        margin-left: 2%;
        margin-right: inherit;
    }

    .daterangepicker .drp-buttons {
        text-align: left;
    }

    .daterangepicker .drp-selected {
        padding-left: 8px;
        padding-right: inherit;
    }

    .daterangepicker .drp-buttons .btn {
        margin-right: 8px;
        margin-left: inherit;
    }

    .daterangepicker.show-ranges.single.rtl .drp-calendar.left {
        border-left: 1px solid #ddd;
        border-right: inherit;
    }

    .daterangepicker.show-ranges.single.ltr .drp-calendar.left {
        border-right: 1px solid #ddd;
        border-left: inherit;
    }

    .daterangepicker.show-ranges.rtl .drp-calendar.right {
        border-left: 1px solid #ddd;
        border-right: inherit;
    }

    .daterangepicker.show-ranges.ltr .drp-calendar.left {
        border-right: 1px solid #ddd;
        border-left: inherit;
    }

    .daterangepicker .ranges {
        text-align: right;
    }

    /*  Larger Screen Styling */
    @media (min-width: 564px) {
        .daterangepicker {
            left: 20px !important;
            right: inherit !important;
            direction: rtl;
            text-align: right;
        }

        .daterangepicker.single .ranges,
        .daterangepicker.single .drp-calendar {
            float: right;
        }

        .daterangepicker .drp-calendar.left .calendar-table {
            border-left: none;
            border-right: initial;
            border-top-left-radius: 0;
            border-top-right-radius: inherit;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: inherit;
        }

        .daterangepicker .drp-calendar.right {
            margin-right: 0;
            margin-left: inherit;
        }

        .daterangepicker .drp-calendar.right .calendar-table {
            border-right: none;
            border-left: inherit;
            border-top-right-radius: 0;
            border-top-left-radius: inherit;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: inherit;
        }

        .daterangepicker .drp-calendar.left .calendar-table {
            padding-left: 8px;
            padding-right: inherit;
        }

        .daterangepicker .ranges,
        .daterangepicker .drp-calendar {
            float: right;
        }
    }

    @media (min-width: 730px) {
        .daterangepicker .ranges {
            float: right;
        }

        .daterangepicker.rtl .ranges {
            float: left;
        }
    }

    .c3-tooltip th {
        text-align: right;
    }

    .c3-tooltip td>span {
        margin-left: 8px;
        margin-right: inherit;
    }

    .c3-tooltip td.value {
        text-align: left;
    }

    .cookie-popup {
        right: 0;
        left: inherit;
    }

    .cookie-popup-right {
        text-align: left;
    }

    .cookie-popup-learn-more {
        margin-right: 5px;
        margin-left: inherit;
    }

    .cookie-popup.position-bottomright {
        left: 10px;
        right: inherit;
    }

    .cookie-popup.position-bottomleft {
        right: 10px;
        left: inherit;
    }

    .cookie-popup.position-topright {
        left: 10px;
        right: inherit;
    }

    .cookie-popup.position-topleft {
        right: 10px;
        left: inherit;
    }

    @media (max-width: 480px) {

        .cookie-popup-right {
            text-align: right;
        }
    }

    .content .button {
        margin: 10px 0 0 10px;
        float: right;
    }

    .button-group.option-buttons.custom-text div {
        float: right;
    }

    /* Button Colours */

    table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control {
        padding-right: 30px;
        padding-left: inherit;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
        padding-right: 30px;
        padding-left: inherit;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
    table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
        right: 5px;
        left: inherit;
    }

    table.dataTable.dtr-inline.collapsed.compact>tbody>tr>td.dtr-control,
    table.dataTable.dtr-inline.collapsed.compact>tbody>tr>th.dtr-control {
        padding-right: 27px;
        padding-left: inherit;
    }

    table.dataTable.dtr-inline.collapsed.compact>tbody>tr>td.dtr-control:before,
    table.dataTable.dtr-inline.collapsed.compact>tbody>tr>th.dtr-control:before {
        right: 4px;
        left: inherit;
    }

    table.dataTable.dtr-column>tbody>tr>td.dtr-control:before,
    table.dataTable.dtr-column>tbody>tr>th.dtr-control:before,
    table.dataTable.dtr-column>tbody>tr>td.control:before,
    table.dataTable.dtr-column>tbody>tr>th.control:before {
        right: 50%;
        left: inherit;
        margin-right: -0.5em;
        margin-left: inherit;
    }

    div.dtr-modal {
        right: 0;
        left: inherit;
    }

    div.dtr-modal div.dtr-modal-close {
        left: 6px;
        right: inherit;
    }

    div.dt-button-info {
        right: 50%;
        left: inherit;
        margin-right: -200px;
        margin-left: inherit;
    }

    div.dt-button-collection.fixed {
        right: 50%;
        left: inherit;
        margin-right: -75px;
        margin-left: inherit;
    }

    div.dt-button-collection.fixed.two-column {
        margin-right: -200px;
        margin-left: inherit;
    }

    div.dt-button-collection.fixed.three-column {
        margin-right: -225px;
        margin-left: inherit;
    }

    div.dt-button-collection.fixed.four-column {
        margin-right: -300px;
        margin-left: inherit;
    }

    div.dt-button-background {
        right: 0;
        left: inherit;
    }

    div.dt-buttons button.btn.processing:after,
    div.dt-buttons div.btn.processing:after,
    div.dt-buttons a.btn.processing:after {
        right: 50%;
        left: inherit;
        margin: -8px -8px 0 0;
    }

    div.dataTables_wrapper div.dataTables_length label {
        text-align: right;
    }

    div.dataTables_wrapper div.dataTables_filter {
        text-align: left;
    }

    div.dataTables_wrapper div.dataTables_filter label {
        text-align: right;
    }

    div.dataTables_wrapper div.dataTables_filter input {
        margin-right: 0.5em;
        margin-left: inherit;
    }

    div.dataTables_wrapper div.dataTables_paginate {
        text-align: left;
    }

    div.dataTables_wrapper div.dataTables_processing {
        right: 50%;
        left: inherit;
        margin-right: -100px;
        margin-left: inherit;
    }

    table.dataTable>thead>tr>th:not(.sorting_disabled),
    table.dataTable>thead>tr>td:not(.sorting_disabled) {
        padding-left: 30px;
        padding-right: 0.75rem;
    }

    table.dataTable>thead .sorting:before,
    table.dataTable>thead .sorting_asc:before,
    table.dataTable>thead .sorting_desc:before,
    table.dataTable>thead .sorting_asc_disabled:before,
    table.dataTable>thead .sorting_desc_disabled:before {
        left: 1em;
        right: inherit;
    }

    table.dataTable>thead .sorting:after,
    table.dataTable>thead .sorting_asc:after,
    table.dataTable>thead .sorting_desc:after,
    table.dataTable>thead .sorting_asc_disabled:after,
    table.dataTable>thead .sorting_desc_disabled:after {
        left: 0.5em;
        right: inherit;
    }

    table.dataTable.table-sm>thead>tr>th:not(.sorting_disabled) {
        padding-left: 20px;
        padding-right: inherit;
    }

    table.dataTable.table-sm .sorting:before,
    table.dataTable.table-sm .sorting_asc:before,
    table.dataTable.table-sm .sorting_desc:before {
        left: 0.85em;
        right: inherit;
    }

    table.table-bordered.dataTable {
        border-left-width: 0;
        border-right-width: inherit;
    }

    table.table-bordered.dataTable th,
    table.table-bordered.dataTable td {
        border-right-width: 1px;
        border-left-width: inherit;
    }

    table.table-bordered.dataTable th:first-child,
    table.table-bordered.dataTable td:first-child {
        border-right-width: 0;
        border-left-width: inherit;
    }

    table.table-bordered.dataTable th:last-child,
    table.table-bordered.dataTable td:last-child {
        border-left-width: 1px;
        border-right-width: 1px;
    }

    div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:first-child {
        padding-right: 0;
        padding-left: inherit;
    }

    div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:last-child {
        padding-left: 0;
        padding-right: inherit;
    }

    .ff_fileupload_wrap .ff_fileupload_dropzone_tools {
        left: 10px;
        right: inherit;
    }

    .ff_fileupload_wrap .ff_fileupload_recordvideo_preview {
        left: 60px;
        right: inherit;
    }

    .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions button.ff_fileupload_start_upload {
        margin-left: 0.5em;
        margin-right: inherit;
    }

    .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions {
        text-align: left;
    }

    /* Colored buttons based on file extension for non-images. */
    @media (max-width: 420px) {
        .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary {
            padding-left: 0;
            padding-right: inherit;
        }
    }

    .ff_fileupload_dialog_background {
        right: 0;
        left: inherit;
    }

    .ff_fileupload_dialog_main {
        right: 10%;
        left: inherit;
    }

    .dropify-wrapper .dropify-clear {
        left: 10px;
        right: inherit;
    }

    .dropify-wrapper .dropify-preview .dropify-render i {
        right: 50%;
        left: inherit;
    }

    .dropify-wrapper .dropify-preview .dropify-render .dropify-extension {
        right: 50%;
        left: inherit;
    }

    .dropify-wrapper .dropify-preview .dropify-infos .dropify-infos-inner p.dropify-infos-message::before {
        right: 50%;
        left: inherit;
    }

    .dropify-wrapper.touch-fallback .dropify-preview .dropify-render .dropify-font-file {
        right: 0;
        left: inherit;
    }

    .dropify-wrapper.touch-fallback .dropify-preview .dropify-infos .dropify-infos-inner {
        padding: 5px 0 5px 90px;
    }

    .dropify-wrapper.touch-fallback .dropify-preview .dropify-infos .dropify-infos-inner p {
        text-align: right;
    }

    .dropify-wrapper .dropify-loader {
        left: 15px;
        right: inherit;
    }

    .dropify-wrapper .dropify-errors-container {
        text-align: right;
    }

    .dropify-wrapper .dropify-errors-container ul {
        right: 0;
        left: inherit;
    }

    .dropify-wrapper .dropify-errors-container ul li {
        margin-right: 20px;
        margin-left: inherit;
    }

    .dropify-wrapper~.dropify-errors-container ul li {
        margin-right: 20px;
        margin-left: inherit;
    }

    .sw-main .sw-toolbar {
        margin-right: 0;
        margin-left: inherit;
    }

    .sw-theme-default .step-content {
        text-align: right;
    }

    .sw-theme-default>ul.step-anchor>li {
        margin-left: 2px;
        margin-right: inherit;
    }

    .sw-theme-default>ul.step-anchor>li>a::after {
        right: 0;
        left: inherit;
    }

    .sw-theme-default>ul.step-anchor>li.danger>a::after {
        border-right-color: #f8d7da;
        border-left-color: inherit;
    }

    /* Common Loader */
    .sw-loading::after {
        right: 0;
        left: inherit;
    }

    .sw-loading::before {
        right: 50%;
        left: inherit;
        margin-right: -40px;
        margin-left: inherit;
    }

    .sw-theme-dots .step-content {
        text-align: right;
    }

    .sw-theme-dots>ul.step-anchor:before {
        right: 20px;
        left: inherit;
    }

    .sw-theme-dots>ul.step-anchor>li>a:before {
        right: 37%;
        left: inherit;
    }

    .sw-theme-dots>ul.step-anchor>li>a:after {
        right: 38%;
        left: inherit;
    }

    @media screen and (max-width: 480px) {
        .sw-theme-dots>ul.step-anchor:before {
            right: 19px;
            left: inherit;
            margin-left: 10px;
            margin-right: auto;
        }

        .sw-theme-dots>ul.step-anchor>li {
            margin-right: 20px;
            margin-left: inherit;
        }

        .sw-theme-dots>ul.step-anchor>li>a {
            text-align: right;
            margin-right: 0;
            margin-left: inherit;
        }

        .sw-theme-dots>ul.step-anchor>li>a:before {
            right: -14px;
            left: inherit;
            margin-left: 10px;
            margin-right: inherit;
        }

        .sw-theme-dots>ul.step-anchor>li>a:after {
            right: -33px;
            left: inherit;
            margin-left: 10px;
            margin-right: inherit;
        }
    }

    .fc {
        direction: rtl;
        text-align: right;
    }

    .fc .fc-button-group>* {
        float: right;
        margin: 0 -1px 0 0;
    }

    .fc .fc-button-group> :first-child {
        margin-right: 0;
        margin-left: inherit;
    }

    .fc-ltr .fc-popover .fc-header .fc-title,
    .fc-rtl .fc-popover .fc-header .fc-close {
        float: right;
    }

    .fc-ltr .fc-popover .fc-header .fc-close,
    .fc-rtl .fc-popover .fc-header .fc-title {
        float: right;
    }

    .fc-event.fc-selected .fc-resizer:before {
        right: 50%;
        left: inherit;
        margin-right: -20px;
        margin-left: inherit;
    }

    .fc-ltr .fc-h-event.fc-not-start,
    .fc-rtl .fc-h-event.fc-not-end {
        margin-right: 0;
        margin-left: 2px;
        border-right-width: 0;
        border-left-width: inherit;
        padding-right: 1px;
        padding-left: 8px;
        border-top-right-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: inherit;
    }

    .fc-ltr .fc-h-event.fc-not-end,
    .fc-rtl .fc-h-event.fc-not-start {
        margin-left: 0;
        margin-right: inherit;
        border-left-width: 0;
        border-right-width: inherit;
        padding-left: 1px;
        padding-right: inherit;
        border-top-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: inherit;
    }

    .fc-ltr .fc-h-event .fc-start-resizer,
    .fc-rtl .fc-h-event .fc-end-resizer {
        right: -1px;
        left: inherit;
    }

    .fc-ltr .fc-h-event .fc-end-resizer,
    .fc-rtl .fc-h-event .fc-start-resizer {
        left: -1px;
        right: inherit;
    }

    .fc-ltr .fc-h-event.fc-selected .fc-start-resizer,
    .fc-rtl .fc-h-event.fc-selected .fc-end-resizer {
        margin-right: -4px;
        margin-left: inherit;
    }

    .fc-ltr .fc-h-event.fc-selected .fc-end-resizer,
    .fc-rtl .fc-h-event.fc-selected .fc-start-resizer {
        margin-left: -4px;
        margin-right: inherit;
    }

    .fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer,
    .fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer {
        margin-right: -2px;
        margin-left: inherit;
    }

    .fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer,
    .fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer {
        margin-left: -2px;
        margin-right: inherit;
    }

    .fc-day-header {
        text-align: right !important;
    }

    .fc-toolbar .fc-left {
        float: right;
    }

    .fc-toolbar .fc-right {
        float: left;
    }

    .fc .fc-button .fc-icon {
        transform: rotate(180deg);
    }

    .fc .fc-toolbar>*> :first-child {
        margin-right: 0;
        margin-left: inherit;
    }

    .fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
        float: right;
    }

    .fc-rtl .fc-basic-view .fc-day-top .fc-day-number {
        float: right;
    }

    .fc-ltr .fc-basic-view .fc-day-top .fc-week-number {
        float: right;
    }

    .fc-rtl .fc-basic-view .fc-day-top .fc-week-number {
        float: left;
    }

    .fc-ltr .fc-axis {
        text-align: left;
    }

    .fc-rtl .fc-axis {
        text-align: right;
    }

    .fc-ltr .fc-time-grid .fc-event-container {
        margin: 0 2px 0 2.5%;
    }

    .fc-rtl .fc-time-grid .fc-event-container {
        margin: 0 2.5% 0 2px;
    }

    .fc-time-grid-event.fc-selected .fc-resizer {
        right: 50%;
        left: inherit;
        margin-right: -5px;
        margin-left: inherit;
    }

    .fc-ltr .fc-time-grid .fc-now-indicator-arrow {
        right: 0;
        left: inherit;
        border-width: 5px 6px 5px 0;
    }

    .fc-rtl .fc-time-grid .fc-now-indicator-arrow {
        left: 0;
        right: inherit;
        border-width: 5px 0 5px 6px;
    }

    .fc-ltr .fc-list-heading-main {
        float: right;
    }

    .fc-ltr .fc-list-heading-alt {
        float: left;
    }

    .fc-rtl .fc-list-heading-main {
        float: left;
    }

    .fc-rtl .fc-list-heading-alt {
        float: right;
    }

    .fc-ltr .fc-list-item-marker {
        padding-left: 0;
        padding-right: inherit;
    }

    .fc-rtl .fc-list-item-marker {
        padding-right: 0;
        padding-left: inherit;
    }

    .twentytwenty-vertical .twentytwenty-before-label:before,
    .twentytwenty-vertical .twentytwenty-after-label:before {
        right: 50%;
        left: inherit;
        margin-right: -45px;
        margin-left: inherit;
    }

    .twentytwenty-horizontal .twentytwenty-before-label:before {
        right: 10px;
        left: inherit;
    }

    .twentytwenty-horizontal .twentytwenty-after-label:before {
        left: 10px;
        right: inherit;
    }

    .iti input {
        padding-left: 36px;
        padding-right: inherit;
        margin-left: 0;
        margin-right: inherit;
    }

    .iti input[type=text],
    .iti input[type=tel] {
        padding-left: 36px;
        padding-right: inherit;
        margin-left: 0;
        margin-right: inherit;
    }

    .iti__flag-container {
        left: 0;
        right: inherit;
    }

    .iti__selected-flag {
        padding: 0 8px 0 6px;
    }

    .iti__arrow {
        margin-right: 6px;
        margin-left: inherit;
    }

    .iti__country-list {
        text-align: right;
        margin: 0 -1px 0 0;
    }

    .iti__flag-box,
    .iti__country-name {
        margin-left: 6px;
        margin-right: inherit;
    }

    .iti--allow-dropdown input {
        padding-left: 6px;
        padding-right: 52px;
        margin-right: 0;
        margin-left: inherit;
    }

    .iti--allow-dropdown input[type=text],
    .iti--allow-dropdown input[type=tel] {
        padding-left: 6px;
        padding-right: 52px;
        margin-right: 0;
        margin-left: inherit;
    }

    .iti--separate-dial-code input {
        padding-left: 6px;
        padding-right: 52px;
        margin-right: 0;
        margin-left: inherit;
    }

    .iti--separate-dial-code input[type=text],
    .iti--separate-dial-code input[type=tel] {
        padding-left: 6px;
        padding-right: 52px;
        margin-right: 0;
        margin-left: inherit;
    }

    .iti--allow-dropdown .iti__flag-container,
    .iti--separate-dial-code .iti__flag-container {
        left: auto;
        right: 0;
    }

    .iti--separate-dial-code .iti__selected-dial-code {
        margin-right: 6px;
        margin-left: inherit;
    }

    .iti--container {
        right: -1000px;
        left: inherit;
    }

    /* Accessibility */
    .wizard>.steps .current-info,
    .tabcontrol>.steps .current-info,
    .wizard>.content>.title,
    .tabcontrol>.content>.title {
        right: -999em;
        left: inherit;
    }

    .tabcontrol>.steps>ul>li {
        float: right;
        margin: 5px 0 0 2px;
    }

    .tabcontrol>.content>.body {
        float: right;
    }

    .checkbox-group label {
        padding-right: 25px;
        padding-left: inherit;
    }

    .checkbox-group+.checkbox-group label {
        margin-right: 15px;
        margin-left: inherit;
    }

    .radio-group label:before {
        right: 0;
        left: inherit;
    }

    .checkbox-group label:before {
        right: 0;
        left: inherit;
    }

    .multi-select-active:after {
        margin-right: 64%;
        margin-left: inherit;
    }

    .icon-single-add,
    .icon-double-add {
        margin-right: -30px;
        margin-left: inherit;
    }

    .transfer-double {
        right: 30%;
        left: inherit;
    }

    .transfer-double-content-left {
        float: right;
    }

    .transfer-double-content-middle {
        float: right;
    }

    .transfer-double-list-search-input {
        padding: 5px 8px 4px 0;
    }

    .transfer-double-list-main .transfer-double-list-ul {
        padding-right: 7px;
        padding-left: inherit;
    }

    .transfer-double-list-main .transfer-double-list-ul .transfer-double-list-li input {
        margin-left: 10px;
        margin-right: inherit;
    }

    .transfer-double-list-main .transfer-double-group-list-ul {
        padding-right: 7px;
        padding-left: inherit;
    }

    .transfer-double-list-main .transfer-double-group-list-ul .transfer-double-group-list-li .transfer-double-group-list-li-ul {
        padding-right: 20px;
        padding-left: inherit;
    }

    .transfer-double-selected-list-search-input {
        padding: 5px 8px 4px 0;
    }

    .transfer-double-selected-list-main .transfer-double-selected-list-ul {
        padding-right: 7px;
        padding-left: inherit;
    }

    .transfer-double-selected-list-main .transfer-double-selected-list-ul .transfer-double-selected-list-li input[type=checkbox] {
        margin-left: 10px;
        margin-right: inherit;
    }

    .transfer-double-list-footer {
        padding-right: 0;
        padding-left: inherit;
    }

    .transfer-double-list-footer span {
        margin-right: 20px;
        margin-left: inherit;
    }

    .transfer-double-list-footer input {
        margin-right: 75px;
        margin-left: inherit;
    }

    .transfer-double-list-footer .btn-setting {
        margin-right: 5px;
        margin-left: inherit;
    }

    .transfer-double-list-footer label {
        margin-right: 10px;
        margin-left: inherit;
    }

    .transfer-double-footer {
        text-align: left;
    }

    /** responsive **/
    @media (max-width: 480px) {
        .transfer-double-content-middle {
            margin: 15px 40% 15px 15px;
        }
    }

    @media (min-width: 481px) and (max-width: 992px) {
        .transfer-double-content-middle {
            margin: 15px 40% 15px 15px;
        }

        .transfer {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }
    }

    .jqvmap-zoomin,
    .jqvmap-zoomout {
        right: 10px;
        left: inherit;
    }

    .jvectormap-zoomin,
    .jvectormap-zoomout,
    .jvectormap-goback {
        right: 10px;
        left: inherit;
    }

    .jvectormap-legend-cnt-h {
        left: 0;
        right: inherit;
    }

    .jvectormap-legend-cnt-v {
        left: 0;
        right: inherit;
    }

    .jvectormap-legend-cnt-h .jvectormap-legend {
        float: right;
        margin: 0 0 10px 10px;
    }

    .jvectormap-legend-cnt-h .jvectormap-legend .jvectormap-legend-tick {
        float: right;
    }

    .jvectormap-legend-cnt-v .jvectormap-legend {
        margin: 10px 0 0 10px;
    }

    .jvectormap-legend-cnt-v .jvectormap-legend-tick-text {
        padding-right: 3px;
        padding-left: inherit;
    }

    .leaflet-pane>svg,
    .leaflet-pane>canvas {
        right: 0;
        left: inherit;
    }

    .leaflet-zoom-box,
    .leaflet-image-layer,
    .leaflet-layer {
        right: 0;
        left: inherit;
    }

    .leaflet-right {
        left: 0;
        right: inherit;
    }

    .leaflet-left {
        right: 0;
        left: inherit;
    }

    .leaflet-control {
        float: right;
    }

    .leaflet-right .leaflet-control {
        float: left;
    }

    .leaflet-left .leaflet-control {
        margin-right: 10px;
        margin-left: inherit;
    }

    .leaflet-right .leaflet-control {
        margin-left: 10px;
        margin-right: inherit;
    }

    .leaflet-control-layers-scrollbar {
        padding-left: 5px;
        padding-right: inherit;
    }

    .leaflet-control-layers-separator {
        margin: 5px -6px 5px -10px;
    }

    .leaflet-left .leaflet-control-scale {
        margin-right: 5px;
        margin-left: inherit;
    }

    .leaflet-popup-content-wrapper {
        text-align: right;
    }

    .leaflet-popup-tip-container {
        right: 50%;
        left: inherit;
        margin-right: -20px;
        margin-left: inherit;
    }

    .leaflet-container a.leaflet-popup-close-button {
        left: 0;
        right: inherit;
        padding: 4px 0 0 4px;
    }

    .leaflet-tooltip-bottom:before {
        right: 50%;
        left: inherit;
        margin-right: -6px;
        margin-left: inherit;
    }

    .leaflet-tooltip-top:before {
        right: 50%;
        left: inherit;
        margin-right: -6px;
        margin-left: inherit;
    }

    .leaflet-tooltip-bottom:before {
        margin-right: -6px;
        margin-left: inherit;
    }

    .leaflet-tooltip-left {
        margin-right: -6px;
        margin-left: inherit;
    }

    .leaflet-tooltip-right {
        margin-right: 6px;
        margin-left: inherit;
    }

    .leaflet-tooltip-left:before {
        left: 0;
        right: inherit;
        margin-left: -12px;
        margin-right: inherit;
        border-right-color: $white;
        border-left-color: inherit;
    }

    .leaflet-tooltip-right:before {
        right: 0;
        left: inherit;
        margin-right: -12px;
        margin-left: inherit;
        border-left-color: $white;
        border-right-color: inherit;
    }

    .lg-actions .lg-next {
        left: 20px;
        right: inherit;
    }

    .lg-actions .lg-prev {
        right: 20px;
        left: inherit;
    }

    @-webkit-keyframes lg-right-end {
        0% {
            right: 0;
            left: inherit;
        }

        50% {
            right: -30px;
            left: inherit;
        }

        100% {
            right: 0;
            left: inherit;
        }
    }

    @-moz-keyframes lg-right-end {
        0% {
            right: 0;
            left: inherit;
        }

        50% {
            right: -30px;
            left: inherit;
        }

        100% {
            right: 0;
            left: inherit;
        }
    }

    @-ms-keyframes lg-right-end {
        0% {
            right: 0;
            left: inherit;
        }

        50% {
            right: -30px;
            left: inherit;
        }

        100% {
            right: 0;
            left: inherit;
        }
    }

    @keyframes lg-right-end {
        0% {
            right: 0;
            left: inherit;
        }

        50% {
            right: -30px;
            left: inherit;
        }

        100% {
            right: 0;
            left: inherit;
        }
    }

    @-webkit-keyframes lg-left-end {
        0% {
            right: 0;
            left: inherit;
        }

        50% {
            right: 30px;
            left: inherit;
        }

        100% {
            right: 0;
            left: inherit;
        }
    }

    @-moz-keyframes lg-left-end {
        0% {
            right: 0;
            left: inherit;
        }

        50% {
            right: 30px;
            left: inherit;
        }

        100% {
            right: 0;
            left: inherit;
        }
    }

    @-ms-keyframes lg-left-end {
        0% {
            right: 0;
            left: inherit;
        }

        50% {
            right: 30px;
            left: inherit;
        }

        100% {
            right: 0;
            left: inherit;
        }
    }

    @keyframes lg-left-end {
        0% {
            right: 0;
            left: inherit;
        }

        50% {
            right: 30px;
            left: inherit;
        }

        100% {
            right: 0;
            left: inherit;
        }
    }

    .lg-toolbar {
        right: 0;
        left: inherit;
    }

    .lg-toolbar .lg-icon {
        float: left;
    }

    #lg-counter {
        padding-right: 20px;
        padding-left: inherit;
    }

    .lg-outer .lg-thumb-item {
        float: right;
    }

    .lg-outer .lg-toggle-thumb {
        border-radius: 2px 0 02px;
        left: 20px;
        right: inherit;
    }

    .lg-outer .lg-video .lg-object {
        right: 0;
        left: inherit;
    }

    .lg-outer .lg-video .lg-video-play {
        right: 50%;
        left: inherit;
        margin-right: -42px;
        margin-left: inherit;
    }

    .lg-outer .lg-video-object {
        right: 0;
        left: inherit;
    }

    .lg-progress-bar {
        right: 0;
        left: inherit;
    }

    .lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
        -webkit-transition: right 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
        -moz-transition: right 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
        -o-transition: right 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
        transition: right 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
    }

    .lg-outer .lg-pager-thumb-cont {
        right: 0;
        left: inherit;
        margin-right: -60px;
        margin-left: inherit;
    }

    .lg-outer .lg-caret {
        right: 50%;
        left: inherit;
        margin-right: -5px;
        margin-left: inherit;
    }

    .lg-outer .lg-dropdown {
        left: 0;
        right: inherit;
        text-align: right;
    }

    .lg-outer .lg-dropdown:after {
        left: 16px;
        right: inherit;
    }

    .lg-outer .lg-dropdown .lg-icon {
        margin-left: 8px;
        margin-right: inherit;
    }

    .lg-outer {
        right: 0;
        left: inherit;
    }

    .lg-outer .lg-inner {
        right: 0;
        left: inherit;
    }

    .lg-outer .lg-item:before {
        margin-left: -1px;
        margin-right: inherit;
    }

    .lg-outer .lg-img-wrap:before {
        margin-left: -1px;
        margin-right: inherit;
    }

    .lg-css3.lg-slide.lg-use-left .lg-item {
        right: 0;
        left: inherit;
    }

    .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
        right: -100%;
        left: inherit;
    }

    .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
        right: 100%;
        left: inherit;
    }

    .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
        right: 0;
        left: inherit;
    }

    .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide,
    .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide,
    .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
        -webkit-transition: right 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
        -moz-transition: right 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
        -o-transition: right 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
        transition: right 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    }

    .lSSlideWrapper .lSFade>* {
        right: 0;
        left: inherit;
        margin-left: 0;
        margin-right: inherit;
    }

    .lSSlideOuter .lSPager.lSGallery {
        padding-right: 0;
        padding-left: inherit;
    }

    .lSAction>.lSPrev {
        right: 10px;
        left: inherit;
    }

    .lSAction>.lSNext {
        left: 10px;
        right: inherit;
    }

    .lSSlideOuter.vertical.noPager {
        padding-left: 0 !important;
        padding-right: inherit !important;
    }

    .lSSlideOuter.vertical .lSGallery {
        left: 0;
        right: inherit;
    }

    .lSSlideOuter.vertical .lSAction>a {
        right: 50%;
        left: inherit;
        margin-right: -14px;
        margin-left: inherit;
    }

    .lSSlideOuter .lSPager,
    .lSSlideOuter .lightSlider {
        padding-right: 0;
        padding-left: inherit;
    }

    .lSSlideOuter.lSrtl .lSPager,
    .lSSlideOuter.lSrtl .lightSlider {
        padding-left: 0;
        padding-right: inherit;
    }

    .lSSlideOuter .lSGallery li,
    .lSSlideOuter .lightSlider>* {
        float: right;
    }

    .lSSlideOuter.lSrtl .lSGallery li,
    .lSSlideOuter.lSrtl .lightSlider>* {
        float: left !important;
    }

    @-webkit-keyframes rightEnd {

        0%,
        100% {
            right: 0;
            left: inherit;
        }

        50% {
            right: -15px;
            left: inherit;
        }
    }

    @keyframes rightEnd {

        0%,
        100% {
            right: 0;
            left: inherit;
        }

        50% {
            right: -15px;
            left: inherit;
        }
    }

    @-webkit-keyframes leftEnd {

        0%,
        100% {
            right: 0;
            left: inherit;
        }

        50% {
            right: 15px;
            left: inherit;
        }
    }

    @keyframes leftEnd {

        0%,
        100% {
            right: 0;
            left: inherit;
        }

        50% {
            right: 15px;
            left: inherit;
        }
    }

    .multi-wrapper .non-selected-wrapper {
        border-left: 1px solid $border;
        border-right: inherit;
    }

    .multi-wrapper .search-input {
        border-radius: 5px 0 0 5px;
    }

    .ms-choice>span {
        right: 0;
        left: inherit;
        padding-right: 18px;
        padding-left: inherit;
    }

    .ms-choice>div {
        left: 8px;
        right: inherit;
    }

    .ms-drop {
        border-radius: 0 4px 4px 0;
    }

    .ms-search input {
        padding: 0 5px 0 20px;
    }

    .ms-drop ul>li.multiple {
        float: right;
    }

    .ms-drop .custom-control-label::before {
        right: 0;
        left: inherit;
    }

    .ms-drop .custom-control-label::after {
        right: 0;
        left: inherit;
    }

    // .jctkr-wrapper ul {
    //     right: 0;
    //     left: inherit;
    // }

    #growls-default {
        left: 10px;
        right: inherit;
    }

    #growls-tl {
        right: 10px;
        left: inherit;
    }

    #growls-tr {
        left: 10px;
        right: inherit;
    }

    #growls-bl {
        right: 10px;
        left: inherit;
    }

    #growls-br {
        left: 10px;
        right: inherit;
    }

    #growls-cc {
        right: 50%;
        left: inherit;
        margin-right: -125px;
        margin-left: inherit;
    }

    #growls-cl {
        right: 10px;
        left: inherit;
    }

    #growls-cr {
        left: 10px;
        right: inherit;
    }

    .growl .growl-close {
        float: left;
    }

    #ui_notifIt {
        left: 10px;
        right: inherit;
    }

    #notifIt_close {
        left: 0;
        right: inherit;
    }

    /* notifit confirm */
    .notifit_confirm_bg,
    .notifit_prompt_bg {
        right: 0;
        left: inherit;
    }

    .notifit_confirm,
    .notifit_prompt {
        right: 0;
        left: inherit;
    }

    .owl-carousel .owl-item {
        float: right;
    }

    .owl-carousel .owl-video-play-icon {
        right: 50%;
        left: inherit;
        margin-right: -40px;
        margin-left: inherit;
    }

    .owl-nav .owl-next {
        left: -25px;
        right: inherit;
    }

    .owl-nav .owl-prev {
        right: -25px;
        left: inherit;
    }

    .ps__rail-y {
        left: 0;
        right: inherit;
    }

    .ps__thumb-y {
        left: 2px;
        right: inherit;
    }

    .ql-clipboard {
        right: -100000px;
        left: inherit;
    }

    .ql-editor {
        text-align: right;
    }

    .ql-editor ol,
    .ql-editor ul {
        padding-right: 1.5em;
        padding-left: inherit;
    }

    .ql-editor li:not(.ql-direction-rtl)::before {
        margin-right: -1.5em;
        margin-left: 0.3em;
        text-align: left;
    }

    .ql-editor li.ql-direction-rtl::before {
        margin-right: 0.3em;
        margin-left: -1.5em;
    }

    .ql-editor ol li:not(.ql-direction-rtl),
    .ql-editor ul li:not(.ql-direction-rtl) {
        padding-right: 1.5em;
        padding-left: inherit;
    }

    .ql-editor ol li.ql-direction-rtl,
    .ql-editor ul li.ql-direction-rtl {
        padding-left: 1.5em;
        padding-right: inherit;
    }

    .ql-editor .ql-indent-1:not(.ql-direction-rtl) {
        padding-right: 3em;
        padding-left: inherit;
    }

    .ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
        padding-right: 4.5em;
        padding-left: inherit;
    }

    .ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
        padding-left: 3em;
        padding-right: inherit;
    }

    .ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
        padding-left: 4.5em;
        padding-right: inherit;
    }

    .ql-editor .ql-indent-2:not(.ql-direction-rtl) {
        padding-right: 6em;
        padding-left: inherit;
    }

    .ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
        padding-right: 7.5em;
        padding-left: inherit;
    }

    .ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
        padding-left: 6em;
        padding-right: inherit;
    }

    .ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
        padding-left: 7.5em;
        padding-right: inherit;
    }

    .ql-editor .ql-indent-3:not(.ql-direction-rtl) {
        padding-right: 9em;
        padding-left: inherit;
    }

    .ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
        padding-right: 10.5em;
        padding-left: inherit;
    }

    .ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
        padding-left: 9em;
        padding-right: inherit;
    }

    .ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
        padding-left: 10.5em;
        padding-right: inherit;
    }

    .ql-editor .ql-indent-4:not(.ql-direction-rtl) {
        padding-right: 12em;
        padding-left: inherit;
    }

    .ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
        padding-right: 13.5em;
        padding-left: inherit;
    }

    .ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
        padding-left: 12em;
        padding-right: inherit;
    }

    .ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
        padding-left: 13.5em;
        padding-right: inherit;
    }

    .ql-editor .ql-indent-5:not(.ql-direction-rtl) {
        padding-right: 15em;
        padding-left: inherit;
    }

    .ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
        padding-right: 16.5em;
        padding-left: inherit;
    }

    .ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
        padding-left: 15em;
        padding-right: inherit;
    }

    .ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
        padding-left: 16.5em;
        padding-right: inherit;
    }

    .ql-editor .ql-indent-6:not(.ql-direction-rtl) {
        padding-right: 18em;
        padding-left: inherit;
    }

    .ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
        padding-right: 19.5em;
        padding-left: inherit;
    }

    .ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
        padding-left: 18em;
        padding-right: inherit;
    }

    .ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
        padding-left: 19.5em;
        padding-right: inherit;
    }

    .ql-editor .ql-indent-7:not(.ql-direction-rtl) {
        padding-right: 21em;
        padding-left: inherit;
    }

    .ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
        padding-right: 22.5em;
        padding-left: inherit;
    }

    .ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
        padding-left: 21em;
        padding-right: inherit;
    }

    .ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
        padding-left: 22.5em;
        padding-right: inherit;
    }

    .ql-editor .ql-indent-8:not(.ql-direction-rtl) {
        padding-right: 24em;
        padding-left: inherit;
    }

    .ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
        padding-right: 25.5em;
        padding-left: inherit;
    }

    .ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
        padding-left: 24em;
        padding-right: inherit;
    }

    .ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
        padding-left: 25.5em;
        padding-right: inherit;
    }

    .ql-editor .ql-indent-9:not(.ql-direction-rtl) {
        padding-right: 27em;
        padding-left: inherit;
    }

    .ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
        padding-right: 28.5em;
        padding-left: inherit;
    }

    .ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
        padding-left: 27em;
        padding-right: inherit;
    }

    .ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
        padding-left: 28.5em;
        padding-right: inherit;
    }

    .ql-editor .ql-video.ql-align-right {
        margin: 0 auto 0 0;
    }

    .ql-editor .ql-align-right {
        text-align: left;
    }

    .ql-bubble.ql-toolbar button,
    .ql-bubble .ql-toolbar button {
        float: right;
    }

    .ql-bubble.ql-toolbar button svg,
    .ql-bubble .ql-toolbar button svg {
        float: right;
    }

    .ql-bubble .ql-editor blockquote {
        border-right: 4px solid $color;
        border-left: inherit;
        padding-right: 16px;
        padding-left: inherit;
    }

    .ql-bubble .ql-picker {
        float: right;
    }

    .ql-bubble .ql-picker-label {
        padding-right: 8px;
        padding-left: 2px;
    }

    .ql-bubble .ql-color-picker .ql-picker-label svg {
        left: 4px;
        right: inherit;
    }

    .ql-bubble .ql-icon-picker .ql-picker-label svg {
        left: 4px;
        right: inherit;
    }

    .ql-bubble .ql-color-picker .ql-picker-item {
        float: right;
    }

    .ql-bubble .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
        left: 0;
        right: inherit;
    }

    .ql-bubble .ql-toolbar .ql-formats {
        margin: 8px 0 8px 12px;
    }

    .ql-bubble .ql-toolbar .ql-formats:first-child {
        margin-right: 12px;
        margin-left: inherit;
    }

    .ql-bubble .ql-tooltip-arrow {
        right: 50%;
        left: inherit;
        margin-right: -6px;
        margin-left: inherit;
    }

    .ql-bubble .ql-tooltip-editor a {
        left: 20px;
        right: inherit;
    }

    .ql-container.ql-bubble:not(.ql-disabled) a::before,
    .ql-container.ql-bubble:not(.ql-disabled) a::after {
        right: 0;
        left: inherit;
        margin-right: 50%;
        margin-left: inherit;
    }

    .ql-clipboard {
        right: -100000px;
        left: inherit;
    }

    .ql-editor {
        text-align: right;
    }

    .ql-editor ol,
    .ql-editor ul {
        padding-right: 1.5em;
        padding-left: inherit;
    }


    .ql-editor li:not(.ql-direction-rtl)::before {
        margin-right: -1.5em;
        margin-left: 0.3em;
        text-align: left;
    }

    .ql-editor li.ql-direction-rtl::before {
        margin-right: 0.3em;
        margin-left: -1.5em;
    }

    .ql-snow.ql-toolbar button,
    .ql-snow .ql-toolbar button {
        float: right;
        border-left: 0;
        border-right: 1px solid $border;
    }

    .ql-snow.ql-toolbar button:first-child,
    .ql-snow .ql-toolbar button:first-child {
        border-radius: 0 3px 3px 0;
    }

    .ql-snow.ql-toolbar button:last-child,
    .ql-snow .ql-toolbar button:last-child {
        border-left: 1px solid $border;
        border-radius: 0 0 3px 3px;
    }

    .ql-snow.ql-toolbar button svg,
    .ql-snow .ql-toolbar button svg {
        float: right;
    }

    .ql-snow .ql-editor blockquote {
        border-right: 4px solid #e7eaf3;
        border-left: inherit;
        padding-right: 16px;
        padding-left: inherit;
    }

    .ql-snow .ql-picker {
        float: right;
    }

    .ql-snow .ql-picker-label {
        padding-right: 8px;
        padding-left: 2px;
    }

    .ql-snow .ql-color-picker .ql-picker-label svg {
        left: 4px;
        right: inherit;
    }

    .ql-snow .ql-icon-picker .ql-picker-label svg {
        left: 4px;
        right: inherit;
    }

    .ql-snow .ql-color-picker .ql-picker-item {
        float: right;
    }

    .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
        left: 6px;
        right: inherit;
    }

    .ql-toolbar.ql-snow .ql-formats {
        margin-left: 25px;
        margin-right: inherit;
    }

    .ql-snow .ql-tooltip::before {
        margin-left: 8px;
        margin-right: inherit;
    }

    .ql-snow .ql-tooltip a.ql-action::after {
        border-left: 1px solid #e7eaf3;
        border-right: inherit;
        margin-right: 16px;
        margin-left: inherit;
        padding-left: 8px;
        padding-right: inherit;
    }

    .ql-snow .ql-tooltip a.ql-remove::before {
        content: "Remove";
        margin-right: 8px;
        margin-left: inherit;
    }

    .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
        border-left: 0px;
        border-right: inherit;
        padding-left: 0;
        padding-right: inherit;
    }

    .select2-container .select2-selection--single .select2-selection__rendered {
        padding-right: 8px;
        padding-left: 20px;
    }

    // .select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
    //     padding-left: 8px;
    //     padding-right: 20px;
    // }

    .select2-container .select2-selection--multiple .select2-selection__rendered {
        padding-right: 8px;
        padding-left: inherit;
    }

    .select2-container .select2-search--inline {
        float: right;
    }

    // .select2-dropdown {
    //     right: -100000px;
    //     left: inherit;
    // }

    // .select2-container--open .select2-dropdown {
    //     right: 0;
    //     left: inherit;
    // }

    .select2-close-mask {
        right: 0;
        left: inherit;
    }

    .select2-container--default .select2-selection--single .select2-selection__clear {
        float: left;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        left: 1px;
        right: inherit;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow b {
        right: 50%;
        left: inherit;
        margin-right: -4px;
        margin-left: inherit;
    }

    .select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
        float: right;
    }

    // .select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
    //     right: 1px;
    //     left: auto;
    // }

    .select2-container--default .select2-selection--multiple .select2-selection__placeholder {
        float: right;
    }

    .select2-container--default .select2-selection--multiple .select2-selection__clear {
        float: left;
        margin-left: 10px;
        margin-right: inherit;
    }

    .select2-container--default .select2-selection--multiple .select2-selection__choice {
        float: right;
        margin-left: 5px;
        margin-right: inherit;
    }

    .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
        margin-left: 2px;
        margin-right: inherit;
    }

    // .select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice,
    // .select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__placeholder,
    // .select2-container--default[dir=rtl] .select2-selection--multiple .select2-search--inline {
    //     float: left;
    // }

    // .select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
    //     margin-right: 5px;
    //     margin-left: auto;
    // }

    // .select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
    //     margin-right: 2px;
    //     margin-left: auto;
    // }

    .select2-container--default .select2-results__option .select2-results__option {
        padding-right: 1em;
        padding-left: inherit;
    }

    .select2-container--default .select2-results__option .select2-results__option .select2-results__group {
        padding-right: 0;
        padding-left: inherit;
    }

    .select2-container--default .select2-results__option .select2-results__option .select2-results__option {
        margin-right: -1em;
        margin-left: inherit;
        padding-right: 2em;
        padding-left: inherit;
    }

    .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
        margin-right: -2em;
        margin-left: inherit;
        padding-right: 3em;
        padding-left: inherit;
    }

    .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
        margin-right: -3em;
        margin-left: inherit;
        padding-right: 4em;
        padding-left: inherit;
    }

    .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
        margin-right: -4em;
        margin-left: inherit;
        padding-right: 5em;
        padding-left: inherit;
    }

    .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
        margin-right: -5em;
        margin-left: inherit;
        padding-right: 6em;
        padding-left: inherit;
    }

    .select2-container--classic .select2-selection--single .select2-selection__clear {
        margin-left: 10px;
        margin-right: inherit;
    }

    .select2-container--classic .select2-selection--single .select2-selection__arrow {
        border-right: 1px solid #aaa;
        border-left: inherit;
        border-top-left-radius: 4px;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: inherit;
        left: 1px;
        right: inherit;
    }

    .select2-container--classic .select2-selection--single .select2-selection__arrow b {
        right: 50%;
        left: inherit;
        margin-right: -4px;
        margin-left: inherit;
    }

    .select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
        float: right;
    }

    .select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
        border-left: 1px solid #aaa;
        border-right: inherit;
        border-top-right-radius: 4px;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: inherit;
        right: 1px;
        left: auto;
    }

    .select2-container--classic .select2-selection--multiple .select2-selection__choice {
        float: right;
        margin-left: 5px;
        margin-right: inherit;
    }

    .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
        margin-left: 2px;
        margin-right: inherit;
    }

    .select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
        float: left;
        margin-right: 5px;
        margin-left: auto;
    }

    .select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
        margin-right: 2px;
        margin-left: auto;
    }

    .SumoSelect.open .search-txt {
        right: 0;
        left: inherit;
    }

    .SumoSelect>.CaptionCont>span {
        padding-left: 30px;
        padding-right: 0;
    }

    .SumoSelect>.CaptionCont>label {
        left: 0;
        right: inherit;
    }

    .SumoSelect>.CaptionCont>label>i:before {
        margin-right: 0.255em;
        margin-left: inherit;
    }

    .SumoSelect>.optWrapper {
        right: 0;
        left: inherit;
    }

    .SumoSelect.open>.optWrapper {
        border-radius: 0 5px 5px 0;
    }

    .SumoSelect>.optWrapper.okCancelInMulti>.options {
        border-radius: 2px 0 0 2px;
    }

    .SumoSelect>.optWrapper.selall>.options {
        border-radius: 0 2px 2px 0;
    }

    .SumoSelect>.optWrapper>.options li ul li.opt {
        padding-right: 22px;
        padding-left: inherit;
    }

    .SumoSelect>.optWrapper.multiple>.options li ul li.opt {
        padding-right: 50px;
        padding-left: inherit;
    }

    .SumoSelect>.optWrapper>.options>li.opt:first-child {
        border-radius: 2px 0 0 2px;
    }

    .SumoSelect>.optWrapper>.options>li.opt:last-child {
        border-radius: 0 2px 2px 0;
    }

    .SumoSelect .select-all>span {
        margin-right: -35px;
        margin-left: inherit;
    }

    .SumoSelect>.optWrapper.multiple>.options li.opt {
        padding-right: 35px;
        padding-left: inherit;
    }

    .SumoSelect>.optWrapper.multiple>.options li.opt span {
        margin-right: -35px;
        margin-left: inherit;
    }

    .SumoSelect>.optWrapper>.MultiControls {
        border-radius: 0 3px 3px 0;
    }

    .SumoSelect>.optWrapper.multiple>.MultiControls>p.btnOk {
        border-left: 1px solid $border;
        border-right: inherit;
        border-radius: 0 3px 0 0;
    }

    .SumoSelect>.optWrapper.multiple.isFloating>.options li.opt {
        padding-right: 35px;
        padding-left: inherit;
    }

    .SumoSelect .select-all {
        border-radius: 3px 0 0 3px;
        padding: 8px 35px 3px 0;
    }

    .SumoSelect>.optWrapper>.options li.optGroup {
        padding-right: 5px;
        padding-left: inherit;
    }

    .sweet-modal-overlay {
        right: 0;
        left: inherit;
    }

    .sweet-modal-close {
        left: 18px;
        right: inherit;
    }

    .sweet-modal-overlay.tabbed .sweet-modal-close {
        left: 12px;
        right: inherit;
    }

    @media screen and (max-width: 420px) {
        .sweet-modal-close {
            left: 18px;
            right: inherit;
        }
    }

    .sweet-modal-box {
        right: 18%;
        left: inherit;
    }

    .sweet-modal-box.alert,
    .sweet-modal-box.prompt {
        right: 25%;
        left: inherit;
    }

    @media screen and (max-width: 777px) {
        .sweet-modal-box {
            right: 9%;
            left: inherit;
        }
    }

    @media screen and (max-width: 420px) {
        .sweet-modal-box {
            right: 0;
            left: inherit;
        }

        .sweet-modal-box.prompt,
        .sweet-modal-box.alert {
            right: 0;
            left: inherit;
        }
    }

    .sweet-modal-title-wrap {
        padding-right: 36px;
        padding-left: 16px;
    }

    .sweet-modal-title .sweet-modal-tabs-links {
        margin-right: -36px;
        margin-left: -16px;
    }

    .sweet-modal-title .sweet-modal-tabs-links ul li a::after {
        right: 50%;
        left: inherit;
        margin-right: -6px;
        margin-left: inherit;
    }

    .sweet-modal-title .sweet-modal-tabs-links ul li+li a {
        border-right: 1px solid #e0e0e0;
        border-left: inherit;
    }

    .sweet-modal-title .sweet-modal-tabs-links ul li:last-child a {
        border-left: 1px solid #e0e0e0;
        border-right: inherit;
    }

    .sweet-modal-buttons {
        text-align: left;
    }

    .sweet-modal-buttons a.button,
    .sweet-modal-buttons button {
        margin: 4px;
        margin-right: 12px;
    }

    @media screen and (max-width: 420px) {
        .sweet-modal-buttons {
            right: 0;
            left: inherit;
        }
    }

    @-webkit-keyframes animateSuccessTip {
        0% {
            right: 1px;
            left: inherit;
        }

        54% {
            right: 1px;
            left: inherit;
        }

        70% {
            right: -8px;
            left: inherit;
        }

        84% {
            right: 21px;
            left: inherit;
        }

        100% {
            right: 14px;
            left: inherit;
        }
    }

    @keyframes animateSuccessTip {
        0% {
            right: 1px;
            left: inherit;
        }

        54% {
            right: 1px;
            left: inherit;
        }

        70% {
            right: -8px;
            left: inherit;
        }

        84% {
            right: 21px;
            left: inherit;
        }

        100% {
            right: 14px;
            left: inherit;
        }
    }

    @-webkit-keyframes animateSuccessLong {
        0% {
            left: 46px;
            right: inherit;
        }

        65% {
            left: 46px;
            right: inherit;
        }

        84% {
            left: 0;
            right: inherit;
        }

        100% {
            left: 8px;
            right: inherit;
        }
    }

    @keyframes animateSuccessLong {
        0% {
            left: 46px;
            right: inherit;
        }

        65% {
            left: 46px;
            right: inherit;
        }

        84% {
            left: 0;
            right: inherit;
        }

        100% {
            left: 8px;
            right: inherit;
        }
    }

    .sweet-modal-icon.sweet-modal-error .sweet-modal-line.sweet-modal-left {
        right: 17px;
        left: inherit;
    }

    .sweet-modal-icon.sweet-modal-error .sweet-modal-line.sweet-modal-right {
        left: 16px;
        right: inherit;
    }

    .sweet-modal-icon.sweet-modal-warning .sweet-modal-body {
        right: 50%;
        left: inherit;
        margin-right: -2px;
        margin-left: inherit;
    }

    .sweet-modal-icon.sweet-modal-warning .sweet-modal-dot {
        right: 50%;
        left: inherit;
        margin-right: -3px;
        margin-left: inherit;
    }

    .sweet-modal-icon.sweet-modal-info::before {
        right: 50%;
        left: inherit;
        margin-right: -2px;
        margin-left: inherit;
    }

    .sweet-modal-icon.sweet-modal-info::after {
        margin-right: -3px;
        margin-left: inherit;
    }

    .sweet-modal-icon.sweet-modal-success::before {
        border-radius: 120px 120px 0 0;
        right: -33px;
        left: inherit;
    }

    .sweet-modal-icon.sweet-modal-success::after {
        border-radius: 0 0 120px 120px;
        right: 30px;
        left: inherit;
    }

    .sweet-modal-icon.sweet-modal-success .sweet-modal-placeholder {
        right: -4px;
        left: inherit;
    }

    .sweet-modal-icon.sweet-modal-success .sweet-modal-fix {
        right: 28px;
        left: inherit;
    }

    .sweet-modal-icon.sweet-modal-success .sweet-modal-line.sweet-modal-tip {
        right: 14px;
        left: inherit;
    }

    .sweet-modal-icon.sweet-modal-success .sweet-modal-line.sweet-modal-long {
        left: 8px;
        right: inherit;
    }

    .sweet-modal-overlay.dark-modal .sweet-modal-box .sweet-modal-title .sweet-modal-tabs-links ul li+li a {
        border-right-color: #1c1e23;
        border-left-color: inherit;
    }

    .sweet-modal-overlay.dark-modal .sweet-modal-box .sweet-modal-title .sweet-modal-tabs-links ul li:last-child a {
        border-left-color: #1c1e23;
        border-right-color: inherit;
    }

    .sweet-alert {
        right: 50%;
        left: inherit;
        margin-right: -256px;
        margin-left: inherit;
    }

    .sweet-alert .sa-error-container .icon {
        margin-left: 3px;
        margin-right: inherit;
    }

    .sweet-alert .sa-input-error {
        left: 26px;
        right: inherit;
    }

    .sweet-alert .sa-input-error::before,
    .sweet-alert .sa-input-error::after {
        right: 50%;
        left: inherit;
        margin-right: -9px;
        margin-left: inherit;
    }

    .sweet-alert .la-ball-fall {
        right: 50%;
        left: inherit;
        margin-right: -27px;
        margin-left: inherit;
    }

    .sweet-alert .sa-icon.sa-error .sa-line.sa-left {
        right: 17px;
        left: inherit;
    }

    .sweet-alert .sa-icon.sa-error .sa-line.sa-right {
        left: 16px;
        right: inherit;
    }

    .sweet-alert .sa-icon.sa-warning .sa-body {
        right: 50%;
        left: inherit;
        margin-right: -2px;
        margin-left: inherit;
    }

    .sweet-alert .sa-icon.sa-warning .sa-dot {
        right: 50%;
        left: inherit;
        margin-right: -3px;
        margin-left: inherit;
    }

    .sweet-alert .sa-icon.sa-info::before {
        right: 50%;
        left: inherit;
        margin-right: -2px;
        margin-left: inherit;
    }

    .sweet-alert .sa-icon.sa-info::after {
        margin-right: -3px;
        margin-left: inherit;
    }

    .sweet-alert .sa-icon.sa-success::before {
        border-radius: 120px 120px 0 0;
        right: -33px;
        left: inherit;
    }

    .sweet-alert .sa-icon.sa-success::after {
        border-radius: 0 0 120px 120px;
        right: 30px;
        left: inherit;
    }

    .sweet-alert .sa-icon.sa-success .sa-placeholder {
        right: -4px;
        left: inherit;
    }

    .sweet-alert .sa-icon.sa-success .sa-fix {
        right: 28px;
        left: inherit;
    }

    .sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
        right: 14px;
        left: inherit;
    }

    .sweet-alert .sa-icon.sa-success .sa-line.sa-long {
        left: 8px;
        right: inherit;
    }

    @-webkit-keyframes animateSuccessTip {
        0% {
            right: 1px;
            left: inherit;
        }

        54% {
            right: 1px;
            left: inherit;
        }

        70% {
            right: -8px;
            left: inherit;
        }

        84% {
            right: 21px;
            left: inherit;
        }

        100% {
            right: 14px;
            left: inherit;
        }
    }

    @keyframes animateSuccessTip {
        0% {
            right: 1px;
            left: inherit;
        }

        54% {
            right: 1px;
            left: inherit;
        }

        70% {
            right: -8px;
            left: inherit;
        }

        84% {
            right: 21px;
            left: inherit;
        }

        100% {
            right: 14px;
            left: inherit;
        }
    }

    @-webkit-keyframes animateSuccessLong {
        0% {
            left: 46px;
            right: inherit;
        }

        65% {
            left: 46px;
            right: inherit;
        }

        84% {
            left: 0;
            right: inherit;
        }

        100% {
            left: 8px;
            right: inherit;
        }
    }

    @keyframes animateSuccessLong {
        0% {
            left: 46px;
            right: inherit;
        }

        65% {
            left: 46px;
            right: inherit;
        }

        84% {
            left: 0;
            right: inherit;
        }

        100% {
            left: 8px;
            right: inherit;
        }
    }

    .tab_wrapper>ul li {
        float: right;
        margin: 0 0 0 5px;
    }

    .tab_wrapper>ul li.active:after {
        right: 0;
        left: inherit;
    }

    .tab_wrapper .controller {
        left: 0;
        right: inherit;
    }

    .tab_wrapper .controller span.next {
        float: left;
        margin-right: 10px;
        margin-left: inherit;
    }

    .tab_wrapper .content_wrapper {
        float: right;
    }

    .tab_wrapper .content_wrapper .accordian_header.active:after {
        right: 0;
        left: inherit;
    }

    .tab_wrapper .content_wrapper .accordian_header .arrow {
        float: left;
        border-right: 3px solid $color;
        border-left: inherit;
    }

    .tab_wrapper .content_wrapper .accordian_header.active .arrow {
        border-right: 3px solid #0061da;
        border-left: inherit;
    }

    .tab_wrapper.left_side>ul {
        float: right;
    }

    .tab_wrapper.left_side>ul li:after {
        right: 0;
        left: inherit;
    }

    .tab_wrapper.left_side>ul li.active:after {
        right: 0;
        left: inherit;
    }

    .tab_wrapper.left_side>ul li.active:before {
        left: -1px;
        right: inherit;
    }

    .tab_wrapper.left_side .content_wrapper {
        float: right;
    }

    .tab_wrapper.right_side>ul {
        float: left;
    }

    .tab_wrapper.right_side>ul li {
        border-right: 1px soild #eaeaea;
        border-left: inherit;
        text-align: left;
    }

    .tab_wrapper.right_side>ul li:after {
        left: 0;
        right: inherit;
    }

    .tab_wrapper.right_side>ul li.active:after {
        left: 0;
        right: inherit;
    }

    .tab_wrapper.right_side>ul li.active:before {
        right: -2px;
        left: inherit;
    }

    .tab_wrapper.right_side .content_wrapper {
        float: right;
    }

    .tab_wrapper .active_tab {
        padding-left: 50px;
        padding-right: inherit;
    }

    .tab_wrapper .active_tab .arrow {
        left: 6px;
        right: inherit;
    }

    .tab_wrapper .active_tab .arrow:after {
        border-left: 3px solid $black;
        border-right: inherit;
        right: 50%;
        left: inherit;
        margin-right: -6px;
        margin-left: inherit;
    }

    @media only screen and (max-width: 768px) {
        .tab_wrapper.show-as-dropdown>.tab_list:before {
            right: 50%;
            left: inherit;
            margin-right: -7px;
            margin-left: inherit;
        }
    }

    .tree ul {
        margin: 0;
        margin-right: 1em;
    }

    .tree ul ul {
        margin-right: 0.5em;
        margin-left: inherit;
    }

    .tree ul:before {
        right: -10px;
        left: inherit;
        border-right: 2px solid rgba(0, 0, 0, 0.1);
        border-left: inherit;
    }

    .tree li i {
        margin-left: 10px;
        margin-right: inherit;
    }

    .tree ul li:before {
        right: -10px;
        left: inherit;
    }

    .indicator {
        margin-left: 5px;
        margin-right: inherit;
    }

    .richText .richText-toolbar ul li {
        float: right;
    }

    .richText .richText-toolbar ul li a .richText-dropdown-outer .richText-dropdown .richText-dropdown-close {
        left: -23px;
        right: inherit;
    }

    .richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li.inline {
        float: right;
    }

    .richText .richText-editor {
        border-right: 0px;
        border-left: 0;
    }

    .richText .richText-editor:focus {
        border-right: #2d66f7 solid 5px;
        border-left: inherit;
    }

    .richText .richText-help {
        float: left;
    }

    .richText .richText-undo,
    .richText .richText-redo {
        float: right;
        border-left: #f6f6f6 solid 1px;
        border-right: inherit;
    }

    .richText .richText-list.list-rightclick {
        border-left: #EFEFEF solid 1px;
        border-right: inherit;
    }
}


.rtl {
    @media (max-width: 991px) and (min-width: 576px) {
        &.search-show .search-element {
            right: 0 !important;
            left: inherit !important;
        }
    }

    .app-header.header {

        .dropdown-menu[data-popper-placement^=top],
        .dropdown-menu[data-popper-placement^=right],
        .dropdown-menu[data-popper-placement^=bottom],
        .dropdown-menu[data-popper-placement^=left] {
            left: auto !important;
            right: inherit !important;
        }
    }

    .country-profit {
        border-right: 1px solid $border;
        border-left: 0;
        padding-right: 0 !important;
        padding-left: 0.75rem !important;
    }

    @media (min-width: 992px) {
        .border-lg-end {
            border-left: 1px solid $border;
            border-right: 0;
        }

        .border-lg-end-0 {
            border-left: 0 !important;
            border-right: 1px solid $border  !important;
        }
    }


    .crypto-content {
        .select2-container .select2-selection--single .select2-selection__rendered {
            padding-right: 20px;
            padding-left: 20px
        }
    }

    .chat-conatct-list .list-group-item .chat-time {
        left: 10px;
        right: inherit;
    }

    @media (min-width: 992px) {
        .main-contact-info-header .media-body {
            margin-right: 30px;
            margin-left: inherit;
        }
    }

    .lg-actions .lg-next:before {
        content: "\e094";
    }

    .lg-actions .lg-prev:after {
        content: "\e095";
    }

    .pull-right {
        float: left;
    }

    .form-control.is-invalid,
    .was-validated .form-control:invalid {
        background-position: top calc(.375em + .1875rem) left calc(.375em + .1875rem);
        padding-right: calc(0.5em + 0.75rem);
        padding-left: 0.75rem;
    }

    .form-control.is-valid,
    .was-validated .form-control:valid {
        padding-right: calc(0.5em + 0.75rem);
        padding-left: 0.75rem;
    }

    @media (min-width: 992px) {
        .ui-timepicker-wrapper {
            left: 0 !important;
            right: 345px !important;
        }
    }

    [type=email],
    [type=number],
    [type=tel],
    [type=url] {
        direction: rtl;
    }

    #closeHelp {
        left: 0;
        right: inherit !important;
    }

    @media (max-width: 1200px) {
        .richText .richText-toolbar ul li a {
            border-left: 0 !important;
            border-right: 0 !important;
        }
    }

    .btn-group>.btn:not(:first-child):not(.dropdown-toggle) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .btn-group>.btn:last-child:not(.dropdown-toggle) {
        border-top-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 0;
    }

    .carousel-item-start.active,
    .carousel-item-prev:not(.carousel-item-end) {
        transform: translateX(-100%) !important;
    }

    .active.carousel-item-start,
    .carousel-item-prev:not(.carousel-item-end) {
        transform: translateX(100%) !important;
    }

    .active.carousel-item-end {
        transform: translateX(-100%) !important;
    }

    .carousel-item-next:not(.carousel-item-start) {
        transform: translateX(-100%) !important;
        transform: translateX(-100%) !important;
    }

    .btn-group>.btn.btn-pill:not(:last-child):not(.dropdown-toggle) {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-top-right-radius: 10rem !important;
        border-bottom-right-radius: 10rem !important;
    }

    .btn-group>.btn.btn-pill:not(:first-child),
    .btn-group>.btn-group:not(:first-child)>.btn.btn-pill {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 10rem !important;
        border-bottom-left-radius: 10rem !important
    }

    .bs-popover-auto[data-popper-placement^=right]>.popover-arrow,
    .bs-popover-end>.popover-arrow {
        right: inherit;
    }

    .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
    .bs-popover-end>.popover-arrow::before {
        right: inherit;
    }

    .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
    .bs-popover-end>.popover-arrow::after {
        right: inherit;
    }

    .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow,
    .bs-tooltip-end .tooltip-arrow {
        right: inherit;
    }

    .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
    .bs-tooltip-end .tooltip-arrow::before {
        left: inherit;
    }

    .price-svg svg {
        float: left;
    }


    @media (min-width: 992px) {
        &.horizontal {
            .side-menu__item .angle {
                transform: rotate(90deg);
            }

            .slide-item {
                padding: 8px 25px;
            }

            .sub-slide-item {
                padding: 8px 25px !important;
            }

            footer.footer {
                padding: 1.25rem;
            }
        }
    }

    @media (max-width: 575px) {
        .main-contact-info-header .media-body .nav-link+.nav-link {
            margin-right: 0px;
            margin-left: inherit;
        }
    }

    .pagination {
        .page-item {
            .page-link {
                i {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .tab_wrapper .content_wrapper .accordian_header .arrow {
        float: left;
    }

    table {
        tbody {
            tr {
                td {
                    &:last-child {
                        border-left-width: 2px;
                    }
                }
            }
        }
    }

    @media (min-width: 992px) {

        &.horizontal .main-sidemenu .slide-left,
        &.horizontal .main-sidemenu .slide-right {
            transform: rotate(180deg);
        }
    }

    #chart3 {
        .apexcharts-canvas {
            .apexcharts-yaxis-texts-g {
                text {
                    transform: translateX(-20px);
                }
            }
        }
    }

    #chart2 {
        .apexcharts-canvas {
            .apexcharts-yaxis-texts-g {
                text {
                    transform: translateX(-70px);
                }
            }
        }
    }

    #chart,
    #chart-timeline {
        .apexcharts-canvas {
            .apexcharts-yaxis-texts-g {
                text {
                    transform: translateX(-30px);
                }
            }
        }
    }

    @media (min-width: 992px) {
        .sidenav-toggled.sidenav-toggled-open .app-sidebar-help .help .ms-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }
    }

    .ui-datepicker .ui-datepicker-header .ui-datepicker-next:before,
    .ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before {
        transform: rotate(180deg);
    }

    .dropdown-icon {
        margin-right: -0.5rem;
        margin-left: 0.5rem;
    }
    .np-btn{
        i {
            transform: rotate(180deg);
        }
    }
}