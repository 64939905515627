@charset "UTF-8";

/**
* Dashtic v9.0 (https://themeforest.net/user/sprukotechnologies)
* Copyright 2021 Spruko Technologies Private Limited
* Licensed under ThemeForest License
*

//---------- TABLE OF CONTENTS ----------//
	* Bootstrap Framework
	* Tables
	* Formsg
	* Buttons
	* Drop Downs
	* Loader
	* Custom control
	* Navigation
	* Card
	* Scroll
	* Email services
	* Range slider
	* Pricing tables
	* Pricing Styles
	* Tooptip &popover
	* Typography
	* Charts styles
	* Media object
	* Breadcrumb
	* Accordion
	* List Of Dropdwons
	* Badges
	* Jumbotron
	* Alerts
	* Progress
	* Lists
	* Modal
	* Carousel
	* Alignments
	* Widths, Margins & Paddings
	* Alignments & values
	* Headers
	* Background colors
	* Btn styles
	* Panel
	* Thumbnails
	* Default Card Values
	* Avatars
	* progress sizes
	* Email
	* Loaders
	* Profile
	* Icon input
	* Tags
	* Time line
	* Icons List
	* selectize
	* Gallery
	* Feather icons
	* Date Picker
	* Back to Top
*/


:root {
    --primary-bg-color: #4454c3;
    --primary-bg-hover: #3d4bb7;
    --primary-bg-border: #3d4bb7;
    --dark-theme: #10163a;
    --dark-theme2: rgba(0, 0, 0, 0.2);
    --dark-body: #262b48;
    --dark-color: #edf0f5;
    --dark-color2: #ffffff66;
    --dark-border: rgba(255, 255, 255, 0.1);
    --dark-shadow: #262b481c;
    --blue: #{$primary};
    --indigo: #{$primary};
    --purple: $purple;
    --pink: $pink;
    --red: $danger;
    --orange: $orange;
    --yellow: $warning;
    --green: $success;
    --teal: $teal;
    --cyan: $info;
    --white: $white;
    --gray: #8587a7;
    --gray-dark: $dark;
    --primary: #{$primary};
    --secondary: $secondary;
    --success: $success;
    --info: $info;
    --warning: $warning;
    --danger: $danger;
    --light: #f4f5f8;
    --dark: $dark;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

#area-chart,
#line-chart,
#bar-chart,
#stacked,
#pie-chart {
    min-height: 250px;
}

* {
    box-sizing: border-box;

    &::before,
    &::after {
        box-sizing: border-box;
    }
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
    width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

.nav-sidebar li ul {
    li:last-child {
        border-bottom: 0px;
    }

    background: #eeeeee;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: $color;
    text-align: left;
    background: #f2f6f9;
}

[tabindex="-1"]:focus {
    outline: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.66em;
}

.overflow-auto {
    overflow: auto;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 400;
}

abbr {

    &[title],
    &[data-original-title] {
        text-decoration: underline;
        -webkit-text-decoration: underline dotted;
        text-decoration: underline dotted;
        cursor: help;
        border-bottom: 0;
    }
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: .5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

dfn {
    font-style: italic;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -.25em;
}

sup {
    top: -.5em;
}

a {
    color: $color;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;

    &:hover {
        color: #{$primary};
        text-decoration: none;
    }

    &:not([href]):not([tabindex]) {
        color: inherit;
        text-decoration: none;

        &:hover {
            color: inherit;
            text-decoration: none;
        }

        &:focus {
            color: inherit;
            text-decoration: none;
            outline: 0;
        }
    }
}

pre,
code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg:not(:root) {
    overflow: hidden;
}

table {
    border-collapse: collapse;
    color: inherit !important;
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #505662;
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

button {
    border-radius: 0;

    &:focus {
        outline: 1px dotted;
        outline: 1px solid transparent;
    }
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input {
    outline: none;
    box-shadow: none;
    overflow: visible;

    &[type="radio"],
    &[type="checkbox"] {
        box-sizing: border-box;
        padding: 0;
    }

    &[type="date"],
    &[type="time"],
    &[type="datetime-local"],
    &[type="month"] {
        -webkit-appearance: listbox;
    }
}

textarea {
    overflow: auto;
    resize: vertical;
    outline: 0;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

.bcolor {
    background-color: #cf4c3a !important;
}

.bcolor1 {
    background-color: #d6124a !important;
}

progress {
    vertical-align: baseline;
}

[type="number"] {

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        height: auto;
    }
}

[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;

    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration {
        -webkit-appearance: none;
    }
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0.66em;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

h1,
.h1 {
    font-size: 2rem;
}

h2,
.h2 {
    font-size: 1.75rem;
}

h3,
.h3 {
    font-size: 1.5rem;
}

h4,
.h4 {
    font-size: 1.125rem;
}

h5,
.h5 {
    font-size: 1rem;
}

h6,
.h6 {
    font-size: 0.875rem;
}

.lead {
    font-size: 1.171875rem;
    font-weight: 300;
}

.display-1 {
    font-size: 10.5rem;
    font-weight: 500;
    line-height: 1.1;
}

.display-2 {
    font-size: 4rem;
    font-weight: 300;
    line-height: 1.1;
}

.display-3 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.1;
}

.display-4 {
    font-size: 3rem;
    font-weight: 300;
    line-height: 1.1;
}

.display-5 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.1;
}

.display-6 {
    font-size: 2.3rem;
    font-weight: 300;
    line-height: 1.1;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid $border;
}

small,
.small {
    font-size: 87.5%;
    font-weight: 400;
}

mark,
.mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;

    li {
        border-bottom: 1px solid $border;
    }
}

#lightgallery.list-unstyled li {
    margin: 10px 0;
    border: 0;
}

#sidebar .list-unstyled li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.03);
}

.nav-sidebar .list-unstyled li:last-child {
    border-bottom: 0px;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;

    &:not(:last-child) {
        margin-right: 0.5rem;
    }
}

.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.171875rem;
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #868e96;
    margin-top: 0;

    &::before {
        content: "\2014 \00A0";
    }
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid $border;
    border-radius: 3px;
    max-width: 100%;
    height: auto;
}

.figure {
    display: inline-block;
}

.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
}

.figure-caption {
    font-size: 90%;
    color: #868e96;
}

.fs-5 {
    font-size: 5px;
}

.fs-6 {
    font-size: 6px;
}

.fs-7 {
    font-size: 7px;
}

.fs-8 {
    font-size: 8px;
}

.fs-9 {
    font-size: 9px;
}

.fs-10 {
    font-size: 10px;
}

.fs-11 {
    font-size: 11px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.fs-17 {
    font-size: 17px;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-19 {
    font-size: 19px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-25 {
    font-size: 25px !important;
}

.fs-30 {
    font-size: 30px !important;
}

.fs-35 {
    font-size: 35px !important;
}

.fs-40 {
    font-size: 40px !important;
}

.fs-45 {
    font-size: 45px !important;
}

.fs-50 {
    font-size: 50px !important;
}

.fs-55 {
    font-size: 55px !important;
}

.fs-60 {
    font-size: 60px !important;
}

.fs-65 {
    font-size: 65px !important;
}

.fs-70 {
    font-size: 70px;
}

.fs-75 {
    font-size: 75px;
}

.fs-80 {
    font-size: 80px;
}

.fs-85 {
    font-size: 85px;
}

.fs-90 {
    font-size: 90px;
}

.fs-95 {
    font-size: 95px;
}

.fs-100 {
    font-size: 100px;
}

code,
kbd,
pre,
samp {
    font-family: Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

code {
    font-size: 85%;
    color: inherit;
    word-break: break-word;
}

a>code {
    color: inherit;
}

kbd {
    padding: 0.2rem 0.4rem;
    font-size: 85%;
    color: #fff;
    background-color: #343a40;
    border-radius: 3px;

    kbd {
        padding: 0;
        font-size: 100%;
        font-weight: 700;
    }
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
    display: block;
    font-size: 85%;
    color: #495057;

    code {
        font-size: inherit;
        color: inherit;
        word-break: normal;
    }
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}

.container,
.container-fluid {
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }

    #sticky {
        text-align: center;
        padding: 1px;
        font-size: 1.75em;
        color: #FFF;
        z-index: 0;

        &.stick {
            z-index: 1;
            height: 130px !important;
        }

        height: 130px !important;
    }

    .app-content.page-body {
        margin-top: 10rem;
    }

    .comb-page .app-content.page-body {
        margin-top: 0;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1200px;
    }
}


/*----- Global Loader -----*/

#global-loader {
    position: fixed;
    z-index: 50000;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    margin: 0 auto;
    text-align: center;

    img {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        top: 37%;
        margin: 0 auto;
    }
}


/*------ Custom Default -------*/

.loader {
    border: 2px solid #ffffff;
    border-top: 2px solid #000000;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    display: inline-block;
    margin: 1rem 0;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;

    &.animate {
        opacity: 1;
    }
}

h2.animate {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.custom-card {
    &.blue {
        color: #1687ed;
        background: #ebf5fd;
        border: 1px solid #5dacf2;
        border-radius: 8px;

        .loader {
            border-color: #99d0ff;
            border-top-color: #1687ed;
        }
    }

    &.red {
        color: #ff0000;
        background: #ffe6e6;
        border: 1px solid #ff8080;
        border-radius: 8px;

        .loader {
            border-color: #ff9696;
            border-top-color: #ff0000;
        }
    }

    &.green {
        color: #007e08;
        background: #cbffce;
        border: 1px solid #00cb0d;
        border-radius: 8px;

        .loader {
            border-color: #75cc7d;
            border-top-color: #007e08;
        }
    }
}

.default-card.default .loader {
    border-color: #e0e3f5;
    border-top-color: #{$primary};
}

.parent {
    -webkit-transform-origin: 66px 56px;
    -ms-transform-origin: 66px 56px;
    transform-origin: 66px 56px;
}

.child1 {
    -webkit-animation: child1 10s 2s alternate infinite;
    animation: child1 10s 2s alternate infinite;
}

.child2 {
    -webkit-animation: child2 5s alternate infinite;
    animation: child2 5s alternate infinite;
}

.child3 {
    -webkit-animation: child3 10s alternate infinite;
    animation: child3 10s alternate infinite;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes child1 {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    to {
        -webkit-transform: translate(35px, -20px);
        transform: translate(35px, -20px);
    }
}

@keyframes child1 {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    to {
        -webkit-transform: translate(35px, -20px);
        transform: translate(35px, -20px);
    }
}

@-webkit-keyframes child2 {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    to {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}

@keyframes child2 {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    to {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}

@-webkit-keyframes child3 {
    0% {
        -webkit-transform: translateY(-10px) rotate(50deg);
        transform: translateY(-10px) rotate(50deg);
    }

    to {
        -webkit-transform: translateY(20px) rotate(0deg);
        transform: translateY(20px) rotate(0deg);
    }
}

@keyframes child3 {
    0% {
        -webkit-transform: translateY(-10px) rotate(50deg);
        transform: translateY(-10px) rotate(50deg);
    }

    to {
        -webkit-transform: translateY(20px) rotate(0deg);
        transform: translateY(20px) rotate(0deg);
    }
}


/*------ Scroll -------*/

.index1 {
    height: 270px;
}

.topsales {
    height: 330px;
}

.salesscroll {
    height: 280px;
}

.countryscroll {
    height: 290px;
}

.countrytable {
    td {
        padding-bottom: 10px;
        padding-top: 10px;
    }

    tr {
        border-bottom: 1px solid $border;

        &:last-child {
            border-bottom: 0px;
        }
    }
}

* {
    html .fileinput-button {
        line-height: 24px;
        margin: 1px -3px 0 0;
    }

    +html .fileinput-button {
        margin: 1px 0 0;
        padding: 2px 15px;
    }
}

@media (max-width: 767px) {
    .files {
        .btn span {
            display: none;
        }

        .preview * {
            width: 40px;
        }

        .name * {
            display: inline-block;
            width: 80px;
            word-wrap: break-word;
        }

        .progress {
            width: 20px;
        }

        .delete {
            width: 60px;
        }
    }
}

ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    &:before,
    &:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

.list-icons {

    .svg-icon2,
    .svg-icon {
        width: 60px;
        height: 60px;
    }
}

.file-manger.list-group-transparent .list-group-item {
    &.active {
        background: transparent;
        color: #{$primary};
    }

    padding: 0.5rem;

    .svg-icon {
        width: 24px;
        height: 24px;
    }
}

.file-manger-absolute-icon {
    position: absolute;
    top: 30px;
    color: #fff;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.file-manger-icon {
    position: relative;

    i {
        font-size: 70px;
        margin: 0 auto;
        text-align: center;
    }

    img {
        width: 70px;
        height: 70px;
    }
}

.profile-footer {
    margin: 25px -25px -25px -25px;
    padding: 15px;
    background: #f3f4f7;
}

.w-200 {
    width: 200px;
}

.option-dots {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: transparent;
    margin: 0 auto;
    text-align: center;
    line-height: 40px;
    display: block;
    cursor: pointer;
    color: #728096;

    &:hover,
    &:focus,
    &[aria-expanded="true"] {
        background: #f3f4f7;
    }

    i.fa {
        line-height: 45px;
    }
}

.new.option-dots {
    line-height: 40px;
}

.new-list.option-dots {
    line-height: 40px;
    position: absolute;
    right: 10px;
    top: 10px;
}


.ms-body .action-header {
    background: #fff;
}

.user-contact-list {
    overflow: hidden;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 70px;
        border-radius: 0 0 50% 50%;
        background: linear-gradient(#{$primary-08}, #{$primary-05}), url(../../assets/images/pattern/1.png);
        background-size: cover;
        background-position: top;
    }
}

hr.divider:after {
    content: "OR";
    display: block;
    position: relative;
    top: -1.5em;
    font-size: 11px;
    padding: 0 .25em;
    background: #f3f4f7;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    z-index: 9;
    left: 0;
    right: 0;
    line-height: 30px;
    margin: 0 auto;
    font-weight: 500;
    border: 1px solid #f2f6f9;
    text-align: center;
}

.page-style1 {

    // position: relative;
    // height: 100vh;
    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(#{$primary-03}, #{$primary-02}), url(../../assets/images/pattern/4.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        opacity: 0.8;
    }

    .form-control {
        padding-top: 4px;
        height: 45px;
        padding-bottom: 6px;
        border: 1px solid $border;
    }

    .btn {
        height: 45px;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 45px;
    }

    .input-group-addon {
        border: 1px solid $border;
        background: #f3f4f7;
        border-right: 0;
        line-height: 0px;
    }

    .input-group-text {
        border: 1px solid $border;
        background: #f3f4f7;
        border-left: 0;
        line-height: 16px;
        border-radius: 0 5px 5px 0;
    }

    hr {
        border-top: 1px solid $border;
    }

    .custom-control-label {
        line-height: 26px;
    }

    .btn-svgs .svg-icon {
        position: absolute;
        left: 37%;
        top: auto;
    }

    .custom-control-label:before {
        border: 1px solid $border;
    }
}

.bg-style {
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(#{$primary-09}, #{$primary-08}), url(../../assets/images/pattern/1.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        opacity: 0.8;
    }

    .svg-icon {
        fill: #fff;
    }

    .header-brand-img {
        position: absolute;
        top: 30px;
        left: 55px;
    }
}

.page-style2 .h-100vh {
    max-height: 100vh;
}

.page-style1 .page-content,
.page-style2 .page-content {
    margin: 0;
}

@media (max-width: 767px) {
    .d-md-flex {
        .bg-style {
            display: none;
        }

        .w-80 {
            width: 100% !important;
        }
    }
}

.title-style h1 {
    position: absolute;
    width: 100%;
    background: #fff;
    display: block;
    left: 0;
    right: 0;
    width: fit-content;
    margin: 0 auto;
    display: block;
    top: 34px;
    padding: 0 20px;
}

.page-style2.page-style1:before {
    background: linear-gradient(rgba(255, 255, 255, 0.79), rgba(255, 255, 255, 0.75)), url(../../assets/images/pattern/4.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.relative {
    position: relative;
}


/* CROSS BROWSER SELECTOR*/

.custom-progress {
    /* RESETS */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    height: 10px;
    border-radius: 0px;
    background-color: #e8ebf2;
    width: 80%;

    &::-webkit-progress-bar {
        background-color: #e8ebf2;
        border-radius: 0px;
    }

    &::-webkit-progress-value {
        background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
        background-color: #{$primary};
        border-radius: 0px;
    }

    &::-moz-progress-bar {
        background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
        background-color: #{$primary};
    }

    &::-ms-fill {
        border-radius: 0px;
        background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
        background-color: #{$primary};
    }
}


/* WEBKIT BROWSERS - CHROME, OPERA AND SAFARI */


/* MOZILLA FIREFOX */


/* MICROSOFT EDGE & IE */

.map svg {
    height: 400px;
}

@media (max-width: 992px) {
    .map svg {
        height: 100%;
    }
}

.coming-footer {
    max-width: 400px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding-top: 30px;
    margin-top: 10px;

    .form-control:focus {
        border-color: $white-1;
        box-shadow: none !important;
    }
}

.construction {
    .form-control:focus {
        border-color: #D3DFEA;
        box-shadow: none !important;
    }
}

.card-pay .tabs-menu {
    margin-bottom: 25px;
    border-radius: 5px;
    overflow: hidden;

    li {
        width: 33.3%;
        display: block;

        a {
            padding: 0.5rem 1rem;
            background: #f3f4f7;
            display: block;
            text-align: center;

            // border-right: 1px solid $border;
            &.active {
                background: #{$primary} !important;
                color: #fff;
                text-align: center;
            }
        }

        &:last-child a {
            border-right: 0;
        }
    }
}

.morris-donut-wrapper-demo {
    height: 270px;
}

.shop-title {
    font-size: 16px;
}

.apexcharts-toolbar {
    display: none !important;
}

#SvgjsText1920 {
    font-weight: 500;
    font-size: 20px;
}

.html-code {
    padding: 9px 22px;
    background: #f3f4f7;
    width: fit-content;
    margin-left: 20px;
    border-radius: 5px 5px 0 0;
    border: 1px solid $border;
    border-bottom: 0;
    margin-bottom: -1px;
    z-index: 1;
    font-size: 15px;
    position: relative;
    padding-left: 45px;
    text-transform: uppercase;
    margin-top: 15px;
    font-weight: 500;

    .svg-icon {
        position: absolute;
        left: 14px;
    }
}

.list-style {
    list-style: inherit;
    padding-left: inherit;

    ul {
        list-style: inherit;
        padding-left: 30px;
    }

    li {
        line-height: 30px;
    }
}

.list-style2 {
    list-style: decimal;
    padding-left: inherit;

    ul {
        list-style: decimal;
        padding-left: 30px;
    }

    li {
        line-height: 30px;
    }
}

.list-style3 {
    list-style: circle;
    padding-left: inherit;

    ul {
        list-style: decimal;
        padding-left: 30px;
    }

    li {
        line-height: 30px;
    }
}

.list-style4 {
    list-style: lower-alpha;
    padding-left: inherit;

    ul {
        list-style: decimal;
        padding-left: 30px;
    }

    li {
        line-height: 30px;
    }
}

.order-list li {
    line-height: 30px;
}

.list-style5 {
    list-style: square;
    padding-left: inherit;

    ul {
        list-style: decimal;
        padding-left: 30px;
    }

    li {
        line-height: 30px;
    }
}

.list-style6 {
    list-style: lower-roman;
    padding-left: inherit;

    ul {
        list-style: decimal;
        padding-left: 30px;
    }

    li {
        line-height: 30px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .expenses-card {
        .fs-50 {
            font-size: 25px !important;
        }

        .fs-18 {
            font-size: 14px !important;
        }
    }
}

@media (max-width: 576px) {
    .card-pay .tabs-menu li {
        width: 100%;
    }
}

.browse-file.form-control {
    background-color: transparent;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 41px;
}

.input-group-btn .btn-primary {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    padding: .51rem 15px;
    border-radius: 0;
}

.apexcharts-pie-label,
.apexcharts-datalabels,
.apexcharts-datalabel,
.apexcharts-datalabel-label,
.apexcharts-datalabel-value {
    cursor: default;
    pointer-events: none;
    font-weight: 500;
    text-shadow: none;
    filter: initial;
}

.select2 {

    >*:focus,
    &:focus .select2>* {
        outline-width: 0px !important;
    }

    outline-width: 0px !important;
}

@media (min-width: 1440px) {
    .crypto-header-section {
        h3 {
            font-size: 32px;
        }

        .w-5 {
            width: 2rem !important;
        }

        .h-5 {
            height: 2rem !important;
        }
    }
}

@media (min-width: 1600px) {
    .crypto-header-section {
        h3 {
            font-size: 32px;
        }

        .w-5 {
            width: 2.5rem !important;
        }

        .h-5 {
            height: 2.5rem !important;
        }
    }
}

@media (max-width: 768px) {
    .page-single .card-group {
        display: block;
    }

    .d-md-down-none {
        display: none;
    }
}

@media (min-width: 1200px) and (max-width: 1440px) {
    .item-card .btn-svgs {
        width: 100%;
    }
}

.header.bg-primary .form-control.header-search.bg-transparent.border-transparent {
    color: #fff;
}

@media (max-width: 1200px) {
    .sales-img {
        width: 50% !important;
        margin: 0 auto;
        text-align: center !important;
        display: block;
    }
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag,
.iti--allow-dropdown .iti__flag-container:focus .iti__selected-flag {
    outline: none;
    box-shadow: none;
}

.iti--allow-dropdown .iti__flag-container .iti__selected-flag {
    outline: none;
    box-shadow: none;
}

input:focus,
input:hover {
    outline: none;
    box-shadow: none;
}

.error-page .page {
    position: relative;
}

.dropdown-menu {
    border: 1px solid $border  !important;
}

.main-contact-info-header .main-img-user a {
    border: 1px solid $border;
}