.tag.tag-attachments {
  padding: 4px 15px;
  font-size: 13px;
  background-color: $white;
  border: 1px solid #e8e8f7;
}
  
.tag.tag-attachments-lg {
  padding: 5px 17px;
  font-size: 17px;
  background-color: $white;
  border: 1px solid #e8e8f7;
}
  
.tag.tag-attachments-sm {
  padding: 1px 10px;
  font-size: 13px;
  background-color: $white;
  border: 1px solid #e8e8f7;
}

.tags .tag-files span {
  position: absolute;
  top: 21px;
  margin-right: 104px;
  left: 51px;
  font-size: 12px;
  color: #a5b1d9;
}
  
.attached-file-grid6 .icons li a {
  font-size: 13px;
  line-height: 30px;
  text-align: center;
  height: 30px;
  width: 30px;
  margin: 0 auto;
  border-radius: 5px;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  background: $white;
}
  
.attached-file-grid6 .icons li a {
  color: #{$primary};
  border: 1px solid $white;
}
.attached-file-grid6 .icons {
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
  bottom: -3px;
  z-index: 1;
  transition: all 0.5s ease 0s;
  display: block;
  margin: 0 auto;
  background-image: linear-gradient(to top, $black-5, transparent 100%);
}
  
.image-pic {
  position: absolute;
  right: 0;
  left: 0;
  top: 0px;
  color: $white;
  font-size: 17px;
  background-image: linear-gradient(to bottom, $black-5, transparent 100%);
  padding: 20px 0px 20px 20px;
  border-radius: 5px;
}
  
.file-image-1 {
  width: 100px;
  height: 100px;
  display: inline-table;
  margin: 20px;
  position: relative;
  border: 1px solid #ededf5;
  border-radius: 5px;

  .product-image img {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
  
.file-image-md .product-image img {
  width: 150px;
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
}

.file-image-lg .product-image img {
  width: 200px;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
}
  
.file-image-1 {
  .icons {
    padding: 0;
    margin: 0;
    list-style: none;
    opacity: 0;
    right: 7px;
    top: 6px;
    text-align: center;
    position: absolute;
    bottom: 0;
    z-index: 1;
    transition: all 0.5s ease 0s;
    text-align: right;
  }

  &:hover .icons {
    opacity: 1;
    bottom: 33px;
  }

  .icons li a {
    font-size: 12px;
    line-height: 28px;
    text-align: center;
    height: 25px;
    width: 25px;
    margin: 4px 1px;
    padding: 0;
    border-radius: 50px;
    display: block;
    position: relative;
    transition: all 0.3s ease-in-out;
    color: $white;
  }

  .file-name-1 {
    position: absolute;
    right: 0;
    left: 0;
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
  
.file-image-md {
  width: 150px;
  height: 150px;

  .icons li a {
    font-size: 13px;
    line-height: 30px;
    height: 28px;
    width: 28px;
  }
}

.file-image-lg {
  width: 200px;
  height: 200px;

  .icons li a {
    font-size: 15px;
    line-height: 35px;
    height: 35px;
    width: 35px;
  }
}

.pro-img-box {
  position: relative;
}