/*--------panel----------*/

.expanel-default {
	border: #ddd !important;
}
.expanel-primary {
	border: #{$primary} !important;
}
.expanel-secondary {
	border: $secondary !important;
}
.expanel-success {
	border: $success !important;
}
.expanel-danger {
	border: #dc0441 !important;
}
.expanel-primary>.expanel-heading {
	color: $white !important;
	background-color: #{$primary} !important;
	border-color: #{$primary} !important;
}
.expanel-secondary>.expanel-heading {
	color: $white !important;
	background-color: $secondary !important;
	border-color: $secondary !important;
}
.expanel-success>.expanel-heading {
	color: $white !important;
	background-color: $success !important;
	border-color: $success !important;
}
.expanel-danger>.expanel-heading {
	color: $white !important;
	background-color: #dc0441 !important;
	border-color: #dc0441 !important;
}
.expanel-warning>.expanel-heading {
	color: $white !important;
	background-color: $warning !important;
	border-color: $warning !important;
}
.expanel-title {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	font-size: 16px !important;
	color: inherit !important;
}
.expanel {
	margin-bottom: 20px !important;
	background-color: $white !important;
	border: 1px solid $border!important;
	border-radius: 4px !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}
.expanel-default>.expanel-heading {
	background-color: #f4f5fb !important;
	border-color: $border!important;
}
.expanel-heading {
	padding: 10px 15px !important;
	border-bottom: 1px solid $border;
	border-top-left-radius: 3px !important;
	border-top-right-radius: 3px !important;
}
.expanel-footer {
	padding: 10px 15px !important;
	background-color: #f4f5fb !important;
	border-top: 1px solid $border !important;
	border-bottom-right-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
}
.expanel-body {
	padding: 15px !important;
}