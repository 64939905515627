@media (min-width: 992px) {
    .horizontal {
      .horizontal-main .sub-slide2 .sub-slide-menu1 {
        left: 180px;
        top: 8px;
        width: 190px;
        position: absolute;
        background-color: $white;
        z-index: 9999;
        border-radius: 5px;
        padding: 5px;
        min-width: fit-content;
        box-shadow: 0px 16px 18px rgba(135, 135, 182, 0.1) !important;
        border: 1px solid $border;
      }
      .app-sidebar {
        border-bottom: 1px solid $border !important;
        border-top: 0;
      }
      .horizontal-main {
        border-left: 0 !important;
        border-right: 0 !important;
      }
  
      .hor-header .container,
      .horizontal-main .container,
      .main-content.horizontal-content .container {
        max-width: 80% !important;
        padding: 0;
      }
  
      .app-sidebar .side-item.side-item-category {
        display: none;
      }
  
      .side-menu {
        display: flex;
        overflow: hidden;
      }

      .app-sidebar-help {
        display: none;
      }
  
      .main-sidemenu {
        margin-top: 0;
      }
  
      .main-sidebar-header {
        display: none;
      }

      .hor-header .header-brand {
        display: block;
        margin: 0 1rem 0 0;
        line-height: 2.7rem;
      }

      .side-menu__item .angle {
        transform: rotate(90deg);
      }
  
      .horizontal-main {
        width: 100%;
        position: relative;
        bottom: initial;
        z-index:1;
        padding-bottom: 0;
  
        &.ps {
          overflow: visible !important;
        }
  
        .slide {
          .slide-menu,
          .sub-slide-menu {
            width: 190px;
            position: absolute;
            background-color: $white;
            z-index: 9999;
            border-radius: 5px;
            padding: 5px;
            min-width: fit-content;
            box-shadow: 0px 16px 18px rgba(135, 135, 182, 0.1) !important;
            border: 1px solid $border;
          }
        }
      }
  
      .slide-item {
        padding: 8px 25px;
      }

      .sub-side-menu__item {
        padding: 8px 35px 8px 25px !important;
      }

      .sub-slide-item {
        padding: 8px 25px !important;
      }
  
      .slide.is-expanded .sub-slide-item2:before {
        left: 20px !important;
      }
  
      .sub-slide-menu .sub-slide-item2 {
        padding: 8px 20px 8px 35px !important;
      }
  
      .sub-slide .sub-angle {
        right: -20px;
        left: auto;
        margin-right: 0 !important;
        position: relative;
      }
  
      .sub-slide .sub-slide-menu {
        position: absolute;
        background-color: $white;
        z-index: 9999;
        box-shadow: 5px 5px 5px #b9b9b9;
        border-radius: 5px;
        padding: 0px;
        min-width: fit-content;
        border: 1px solid #f0f0f8;
        left: 180px;
        top: 13px;
      }
  
      .side-menu > li > a {
        display: flex;
        margin: 0px -4px !important;
        text-decoration: none;
        position: relative;
        color: #7b8191;
        padding: 20px 13px 20px 13px !important;
        font-weight: 400;
      }
      .breadcrumb-header {
        margin-top: 20px;
      }
  
      .slide {
        margin: 0 3px;
      }
  
      .logo-horizontal {
        display: block;
      }
  
      .app-sidebar__toggle {
        display: none;
      }
  
      .mobile-logo.dark-logo-1 {
        display: none;
      }
  
      .main-content {
        margin-left: 0px !important;
      }
  
      .main-sidemenu .slide-right {
        right: 0;
      }
  
      .main-sidemenu .slide-left,
      .main-sidemenu .slide-right {
        position: absolute;
        top: 0px;
        padding: 17px;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        cursor: pointer;
      }
  
      .main-sidemenu .slide-left {
        left: 0;
      }
  
      .horizontal-main {
        position: relative;
        margin: 0 auto;
      }
  
      .main-sidemenu {
        overflow: hidden;
      }
  
      .sticky-pin {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 9;
      }
      .hor-header {
        position: relative !important; 
        z-index: 9 !important;
        padding-left: 0;
        box-shadow: none;
        border-bottom: 1px solid $border;
      }
      footer.footer {
          padding: 1.25rem;
      }
    }
    .horizontal-hover.horizontal {
      .slide {
        &:hover {
          ul.slide-menu {
            display: block !important;
          }
        }
      }
      .sub-slide {
        &:hover {
          .sub-slide-menu {
            display: block !important;
          }
        }
      }
    }
    .horizontal-main {
      .app-sidebar__user {
        display: none;
      }
      #bg-side-text {
        display: none;
      }
      &.app-sidebar .slide .side-menu__item.active::before {
        display: none;
      }
    }
  }
  
  .logo-horizontal {
    display: none;
  }
  
  .horizontal {
    @media (max-width: 991.98px) {
      // .app-sidebar.horizontal-main {
      //   left: -300px;
      // }
      .main-sidemenu {
        padding: 0;
      }
      .horizontalMenucontainer .app-header.hor-header {
        position: fixed;
      }
      .slide-left,
      .slide-right {
        display: none !important;
      }
      .main-content {
        min-width: 100%;
        min-height: calc(100vh - 50px);
        margin-top: 50px;
        margin-bottom: 0 !important;
        -webkit-transition: margin-left 0.2s ease;
        -o-transition: margin-left 0.2s ease;
        transition: margin-left 0.2s ease;
        overflow: hidden;
        .side-app {
          padding: 25px 1.5rem 0 1.5rem;
        }
      }

    }
    .main-sidemenu.container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    
    .ps--active-x > .ps__rail-x,
    .ps--active-y > .ps__rail-y {
      display: none;
    }
  }
  @media (min-width: 992px) {
    .horizontal-hover.horizontal .sub-slide2:hover .sub-slide-menu1 {
      display: block !important;
    }
    .horizontal-hover.horizontal .app-sidebar .slide-menu .sub-slide-menu1 a:before {
      left: 20px !important;
      top: 11px;
    }
    .horizontal-hover.horizontal .sub-slide-menu .sub-side-menu__item:before {
      left: 20px !important;
      top: 10px !important;
    }
  }
  
  .hor-header {
    .responsive-logo {
      display: block;
      img {
        height: 2rem;
        text-align: center;
        margin: 0 auto;
        justify-content: center;
      }
    }
  }

  @media (min-width: 992px) {
    .horizontalMenucontainer .header-brand-img {
        &.mobile-logo, &.darkmobile-logo, &.dark-logo {
            display: none;
        }
    }
}
  